<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Roles Partidos
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>



    <div class="row">
      <div class="col-12 col-sm-9 col-md-9">
        <div class="bgc-primary-d1 text-white px-3 py-25">
          <div class="row">
            <div class="col-md-6 col-12">
              <span class="text-90">Lista de</span>
              Rol de Partidos
            </div>
            <div class="col-md-6 col-12">
              <div class="columns columns-right btn-group float-right">
                <button *ngIf="aplicacion.puede_crear" title="Nuevo" (click)="crearNuevoRolPartido()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                  <i class="fa fa-plus text-purple-d1"></i>
                </button>
                <div class="export btn-group">
                  <button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Opciones" title="Acciones" aria-expanded="true">
                    <i class="fa fa-wrench text-blue-d1"></i>
                    <span class="caret"></span>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
                    <div class="col-12 col-sm-12 mt-12 mt-sm-0 cards-container">
                      <div class="card bgc-primary brc-primary radius-0">
                        <div class="card-header">
                        <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Acciones</h5>
                        </div>
  
                        <div class="card-body p-0 bg-white">
                        <table class="table table-striped table-hover mb-0">
                          <tbody>
                            <tr>
                              <td class="text-dark-m2">
                              Configuración Aplicación
                              </td>
                              <td class="text-dark-m2">
                                <a (click)="abrirConfiguracionAplicacion()" href="javascript:void(0)" class="text-blue"><i class="fa fa-cog text-primary"></i></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        </div>
                      </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="bootstrap-table bootstrap4">
          <div class="fixed-table-toolbar">
            <div class="row">
              <div class="col-12 col-md-7">
                <div class="row">
                  <div class="col-12 col-md-12">
                    <app-paginator-header [paginator]="this"></app-paginator-header>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-12">
                    <div class="row">
                      <div class="col-md-2 col-sm-12" ><label>Campeonato </label></div>
                      <div class="col-md-3 col-sm-12">
                        <select [compareWith]="compararObjectos" class="form-control" required name="campeonato" [(ngModel)]="filter.campeonato">
                            <option [ngValue]="campeonato" *ngFor="let campeonato of filter.campeonatos">{{campeonato.nombre}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-5 p-0">
                <div class="row">
                  <div class="col-12">
                    <button (click)="getSearch(text_search,null)" class="btn btn-xs btn-app btn-primary my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                      <i class="d-block h-4 fa fa fa-search text-150"></i>
                    </button>&nbsp;
                    <button (click)="abrirModalTablaPosiciones()" class="btn btn-xs btn-app btn-success my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Tabla Posiciones">
                      <i class="d-block h-4 fas fa-table text-150"></i>
                    </button>&nbsp;
                    <button (click)="abrirModalRolPartidosArbitro()" class="btn btn-xs btn-app btn-danger my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Rol Partidos Arbitro">
                      <i class="d-block h-4 fas fa-gavel text-150"></i>
                    </button>&nbsp;
                    <button (click)="abrirModalTablaGoleadores()" class="btn btn-xs btn-app btn-warning my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Goleadores">
                      <i class="d-block h-4 fas fa-futbol text-150"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="fixed-table-container" style="padding-bottom: 0px;">
            <div class="fixed-table-header" style="display: none;">
              <table></table>
            </div>
            <div id="gallery" class="fixed-table-body">
              <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
                <span class="loading-wrap">
                  <span class="animation-wrap">
                    <span class="animation-dot"></span>
                  </span>
                </span>
              </div>
              <table *ngIf="roles_partidos.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="table">
                <thead class="bgc-white text-grey text-uppercase text-80">
                  <tr>
                    <th>N°</th>
                    <th class="center"><a href="javascript:void(0)">Código <span id="codigo" class="sort fa fa-sort icon-only"></span></a></th>
                    <th class="center"><a href="javascript:void(0)">Logo <span id="logo" class="sort fa fa-sort icon-only"></span></a></th>
                    <th class="center"><a href="javascript:void(0)">Campeonato <span id="campeonato" class="sort fa fa-sort icon-only"></span></a></th>
                    <th class="center"><a href="javascript:void(0)">Rol <span id="rol" class="sort fa fa-sort icon-only"></span></a></th>
                    <th class="center"><a href="javascript:void(0)">Actualizado <span id="actualizado" class="sort fa fa-sort icon-only"></span></a></th>
                    <th class="center"><a href="javascript:void(0)">Validez <span id="validez" class="sort fa fa-sort icon-only"></span></a></th>
                    <th style="text-align: center; width: 140px; " data-field="tools">
                      <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                      <div class="fht-cell"></div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let rol_partido of roles_partidos; let i=index;">
                    <td>
                      {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
                    </td>
                    <td>
                        {{rol_partido.codigo}}
                    </td>
                    <td>
                      <div class="imagen-campeonato-vista clearfix">
                        <a href="{{rest_server+rol_partido.campeonato.logo}}" title="{{rol_partido.campeonato.nombre}}" data-rel="colorbox">
                            <img width="50" height="50" alt="sin imagen" src="{{rest_server+rol_partido.campeonato.logo}}" />
                        </a>
                    </div>
                    </td>
                    <td>
                      {{rol_partido.campeonato.nombre}}
                  </td>
                    <td>
                      {{rol_partido.rol.nombre}}
                  </td>
                    <td>
                      {{rol_partido.updatedAt | date:'dd/MM/yyyy - HH:mm'}}
                    </td>
                    <td *ngIf="rol_partido.activo"><span class="badge badge-sm badge-success">ACTIVO</span></td>
                    <td *ngIf="!rol_partido.activo"><span class="badge badge-sm badge-danger">ANULADO</span></td>
                    <td style="text-align: center; width: 140px; ">
                      <div class="action-buttons">
                        <a *ngIf="aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarRolPartido(rol_partido)">
                          <i class="fa fa-pencil-alt text-105"></i>
                        </a>
                        <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="generarPdfRolPartidos(rol_partido,null,false)">
                          <i class="fas fa-file-pdf text-105"></i>
                        </a>
                        <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="generarPdfRolPartidos(rol_partido,null,true)">
                          <i class="fas fa-file-pdf text-105"></i>
                        </a>
                        <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="generarPdfPlanillasPartidos(rol_partido)">
                          <i class="fas fa-file-pdf text-105"></i>
                        </a>
                        <a *ngIf="aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(rol_partido)">
                          <i class="fa fa-trash-alt text-105"></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="fixed-table-footer">
              <table><thead><tr></tr></thead></table>
            </div>
          </div>
          <div class="fixed-table-pagination">
            <app-paginator-footer *ngIf="roles_partidos.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-3 col-md-3 p-0">
        <div class="bgc-primary-d1 text-white px-3 py-25">
          <div class="row">
            <div class="col-md-12 col-12">
              <span class="text-90">Lista de</span>
              Tarjetas
            </div>
          </div>
        </div>
        <div class="bgc-secondary-l4 border-1 brc-secondary-l2 shadow-sm p-0 radius-1">
          <table *ngIf="roles_partidos.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
              <tr>
                <th>N°</th>
                <th class="center"><a href="javascript:void(0)">Jugador <span id="codigo" class="sort fa fa-sort icon-only"></span></a></th>
                <th class="center"><a href="javascript:void(0)">Equipo <span id="logo" class="sort fa fa-sort icon-only"></span></a></th>
                <th style="text-align: center; width: 140px; " data-field="tools">
                  <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                  <div class="fht-cell"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let partido of lista_tarjetas; let i=index;">
                <td>
                  {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
                </td>
                <td [ngClass]="{'bgc-danger-m1':(partido.tarjeta=='r_1' || partido.tarjeta=='r_2'),'bgc-yellow-m1':(partido.tarjeta=='a_1' || partido.tarjeta=='a_2')}">
                    {{partido.jugador.persona.nombre_completo}}
                </td>
                <td>
                  {{partido.equipo}}
              </td>
                <td style="text-align: center; width: 140px; ">
                  <div class="action-buttons">
                    <a *ngIf="aplicacion_ventas.puede_crear && partido.tarjeta=='a_1'" title="Generar Venta Amarilla" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="generarVentaJugadorAmarilla(partido,{id:partido.jugador.id_equipo,cliente:{id:partido.cliente,identificacion:partido.identificacion,razon_social:partido.razon_social,nit:partido.nit}},null,partido.jugador)">
                      <i class="fas fa-money-bill-alt"></i>
                    </a>
                    <a *ngIf="aplicacion_ventas.puede_crear && partido.tarjeta=='a_2'" title="Generar Venta Amarilla" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="generarVentaJugadorAmarilla(partido,null,{id:partido.jugador.id_equipo,cliente:{id:partido.cliente,identificacion:partido.identificacion,razon_social:partido.razon_social,nit:partido.nit}},partido.jugador)">
                      <i class="fas fa-money-bill-alt"></i>
                    </a>
                    <a *ngIf="aplicacion_ventas.puede_crear && partido.tarjeta=='r_1'" title="Generar Venta Roja" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="generarVentaJugadorRoja(partido,{id:partido.jugador.id_equipo,cliente:{id:partido.cliente,identificacion:partido.identificacion,razon_social:partido.razon_social,nit:partido.nit}},null,partido.jugador)">
                      <i class="fas fa-money-bill-alt"></i>
                    </a>
                    <a *ngIf="aplicacion_ventas.puede_crear && partido.tarjeta=='r_2'" title="Generar Venta Roja" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="generarVentaJugadorRoja(partido,null,{id:partido.jugador.id_equipo,cliente:{id:partido.cliente,identificacion:partido.identificacion,razon_social:partido.razon_social,nit:partido.nit}},partido.jugador)">
                      <i class="fas fa-money-bill-alt"></i>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
</div>

<ng-template #modal_registro_rol_partido let-modal>
    <div class="modal-header card-header">
        <div class="col-md-8 col-xs-8">
            <span class="text-blue text-125">Registro Rol Partido</span>
        </div>
        <div class="col-md-6 col-xs-6">
            <button *ngIf="aplicacion.puede_crear" (click)="guardarRolPartido()" class="btn btn-primary"  >
                <i class="fa fa-save text-110 mr-1"></i>
            </button>&nbsp;
            <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
                <i class="fa fa-times red2 text-110 mr-1"></i>
            </button>
        </div>
    </div>
    <div class="modal-body card-body">
        <div class="row">
          <div class="col-md-3">
              <label>Código</label>
              <input [disabled]="codigo_automatico" required type="text" id="codigo" name="codigo" [(ngModel)]="rol_partido.codigo" placeholder="Código" class="form-control" />
          </div>
          <div class="col-md-4">
              <label>Campeonato</label>
              <select [compareWith]="compararObjectos" class="form-control" required name="campeonato" [(ngModel)]="rol_partido.campeonato" (change)="obtenerEquiposCampeonato(rol_partido.campeonato,partido.categoria)">
                <option [ngValue]="campeonato" *ngFor="let campeonato of campeonatos">{{campeonato.nombre}}</option>
              </select>
          </div>
          <div class="col-md-3">
              <label>Rol</label>
              <select [compareWith]="compararObjectos" class="form-control" required name="rol" [(ngModel)]="rol_partido.rol">
                <option [ngValue]="rol" *ngFor="let rol of tipo_rol_partido.clases">{{rol.nombre}}</option>
              </select>
          </div>
          <div class="col-md-2">
            <span *ngIf="aplicacion.puede_crear" class="fa fa-edit point-edit blue" (click)="abrirConceptoEmpresa(global_variable.Dictionary.TIPO_ROLES_PARTIDOS,'obtenerRolesPartidosEmpresa')"></span>
        </div>
      </div>
      <ng-container *ngIf="aplicacion.puede_crear">
        <hr>
        <div class="row bgc-primary-d1 text-white px-3 py-25">
            <div class="col-md-2 col-12">
                <div class="row">
                  <div class="col-md-6 col-6 p-0">
                    <label>Categoria</label><br>
                    <label>Fase</label> &nbsp;<span class="fa fa-edit point-edit blue" (click)="abrirConceptoEmpresa(global_variable.Dictionary.TIPO_CAMPEONATO_FASES,'obtenerFasesCampeonato')"></span><br>
                    <label>Disciplina</label>
                  </div>
                  <div class="col-md-6 col-6 pl-0">
                    <select [compareWith]="compararObjectos" class="form-control" required name="categoria" [(ngModel)]="partido.categoria" (change)="obtenerEquiposCampeonato(rol_partido.campeonato,partido.categoria,partido.disciplina)">
                      <option [ngValue]="categoria" *ngFor="let categoria of tipo_categoria.clases">{{categoria.nombre}}</option>
                    </select>
                    <select [compareWith]="compararObjectos" class="form-control p-1" required name="fase" [(ngModel)]="partido.fase">
                      <option [ngValue]="fase" *ngFor="let fase of tipo_fase.clases">{{fase.nombre}}</option>
                    </select>
                    <select [compareWith]="compararObjectos" class="form-control p-1" required name="disciplina" [(ngModel)]="partido.disciplina" (change)="obtenerEquiposCampeonato(rol_partido.campeonato,partido.categoria,partido.disciplina)">
                      <option [ngValue]="disciplina" *ngFor="let disciplina of tipo_disciplina.clases">{{disciplina.nombre}}</option>
                    </select>
                  </div>                  
                </div>
            </div>
            <div class="col-md-2 p-0">
              <label>Equipo 1</label>
              <select [compareWith]="compararObjectos" class="form-control" required name="equipo" [(ngModel)]="partido.equipo_1_c" (change)="obtenerEquiposDiferente(partido.equipo_1_c)">
                <option [ngValue]="equipo" *ngFor="let equipo of equipos">{{equipo.nombre}}</option>
            </select>
            </div>
            <div class="col-md-2">
                <label>Fecha</label>
                <div class="input-group">
                  <input name="fecha" class="form-control" placeholder="dd/mm/yyyy"
                          name="dp" [(ngModel)]="partido.fecha_hora_texto" ngbDatepicker #d="ngbDatepicker">
                  <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                  </div>
                </div>
                <div class="input-group">
                  <ngb-timepicker [(ngModel)]="partido.hora" [spinners]="false"></ngb-timepicker>
                </div>
            </div>
            <div class="col-md-2 p-0">
                <label>Equipo 2</label>
                <select [disabled]="!partido.equipo_1_c" [compareWith]="compararObjectos" class="form-control" required name="equipo" [(ngModel)]="partido.equipo_2_c">
                  <option [ngValue]="equipo" *ngFor="let equipo of equipos_diferente">{{equipo.nombre}}</option>
              </select>
            </div>
            <div class="col-md-2">
              <label>Escenario</label>
              <select [compareWith]="compararObjectos" class="form-control" required name="escenario" [(ngModel)]="partido.escenario">
                <option [ngValue]="null" [selected]="true">Seleccionar escenario</option>
                  <option [ngValue]="escenario" *ngFor="let escenario of escenarios">{{escenario.nombre}}</option>
              </select>
            </div>
            <div class="col-md-1 pl-0">
              <label>Arbitro</label>
              <select [compareWith]="compararObjectos" class="form-control" required name="arbitro" [(ngModel)]="partido.arbitro">
                <option [ngValue]="null" [selected]="true">Seleccionar arbitro</option>
                  <option [ngValue]="arbitro" *ngFor="let arbitro of arbitros">{{arbitro.persona.nombre_completo}}</option>
              </select>
            </div>
            <div class="col-md-1">
              <button (click)="agregarPartido()" class="btn btn-xs btn-app btn-success my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Agregar">
                <i class="d-block h-4 fa fa fa-plus text-150"></i>
              </button>
            </div>

        </div>
      </ng-container>
        <hr>
        <div class="row">
            <div class="col-12">
                <table class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="table">
                    <thead class="bgc-white text-grey text-uppercase text-80">
                      <tr>
                        <th class="text-center pr-0">
                          <label class="py-0">
                            <input [(ngModel)]="allItemsSelected" (change)="selectAllItems(rol_partido.partidos)" type="checkbox" class="align-bottom mb-n1 border-2 text-dark-m3" />
                          </label>
                        </th>
                        <th>N°</th>
                        <th>Categoria</th>
                        <th>Fase</th>
                        <th>Disciplina</th>
                        <th>Fecha-Hora</th>
                        <th>Equipo 1</th>
                        <th>Equipo 2</th>
                        <th>Escenario</th>
                        <th>Arbitro</th>
                        <th style="text-align: center; width: 140px; " data-field="tools">
                          <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="generarPdfRolPartidos(rol_partido,null,false)">
                            <i class="fas fa-file-pdf text-105"></i>
                          </a>
                          <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="generarPdfRolPartidos(rol_partido,null,true)">
                            <i class="fas fa-file-pdf text-105"></i>
                          </a>
                          <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="generarPdfPlanillasPartidos(rol_partido)">
                            <i class="fas fa-file-pdf text-105"></i>
                          </a>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let partido of rol_partido.partidos; let i=index;">
                        <tr *ngIf="!partido.eliminado">
                          <td class='text-center pr-0 pos-rel'>
                            <div class="position-tl h-100 ml-n1px border-l-4 brc-orange-m1 v-hover">
                              <!-- border shown on hover -->
                            </div>
                            <div class="position-tl h-100 ml-n1px border-l-4 brc-success-m1">
                              <!-- border shown when row is selected -->
                            </div>
          
                            <label>
                              <input [(ngModel)]="partido.selected" (change)="addItemToSelected(partido)" type="checkbox" class="align-middle" />
                            </label>
                          </td>
                          <td>
                            {{(i+1)}}
                          </td>
                          <td>
                              {{partido.categoria?partido.categoria.nombre:''}}
                              <select *ngIf="partido.modificar" [compareWith]="compararObjectos" class="form-control" required name="categoria" [(ngModel)]="partido.categoria" (change)="cambiarCategoriaModificacion(partido)">
                                <option [ngValue]="categoria" *ngFor="let categoria of tipo_categoria.clases">{{categoria.nombre}}</option>
                              </select>
                          </td>
                          <td>
                            {{partido.fase?partido.fase.nombre:''}}
                            <select *ngIf="partido.modificar" [compareWith]="compararObjectos" class="form-control" required name="fase" [(ngModel)]="partido.fase">
                              <option [ngValue]="fase" *ngFor="let fase of tipo_fase.clases">{{fase.nombre}}</option>
                            </select>
                          </td>
                          <td>
                            {{partido.disciplina?partido.disciplina.nombre:''}}
                            <select *ngIf="partido.modificar" [compareWith]="compararObjectos" class="form-control" required name="disciplina" [(ngModel)]="partido.disciplina">
                              <option [ngValue]="disciplina" *ngFor="let disciplina of tipo_disciplina.clases">{{disciplina.nombre}}</option>
                            </select>
                          </td>
                          <td>
                            {{partido.fecha_hora | date:'dd/MM/yyyy HH:mm'}}
                            <ng-container *ngIf="partido.modificar">
                              <div class="input-group">
                                <input name="fecha" class="form-control" placeholder="dd/mm/yyyy"
                                        name="dp" [(ngModel)]="partido.fecha_hora_texto" ngbDatepicker #d="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                </div>
                              </div>
                              <div class="input-group">
                                <ngb-timepicker [(ngModel)]="partido.hora" [spinners]="false"></ngb-timepicker>
                              </div>
                            </ng-container>
                          </td>
                          <td class="">
                            {{partido.equipo_1.nombre}}
                            <div class="text-nowrap" *ngIf="partido.marcador_equipo_1!=null">
                              <span [ngClass]="{'bgc-danger-l3 brc-danger-m3':partido.marcador_equipo_1<partido.marcador_equipo_2,'bgc-success-l3 brc-success-m3':partido.marcador_equipo_2<partido.marcador_equipo_1,'bgc-primary-l3 brc-primary-m3':partido.marcador_equipo_2==partido.marcador_equipo_1}" class="m-1 border-1 text-dark-tp3 text-180 px-25 py-3px radius-round">
                                {{partido.marcador_equipo_1}}
                              </span>
                            </div>
                            <div class="text-nowrap" *ngIf="partido.marcador_equipo_1==null">
                              <span class="bgc-warning m-1 border-1 text-dark-tp3 text-180 px-25 py-3px radius-round">
                                {{partido.marcador_equipo_1?partido.marcador_equipo_1:'?'}}
                              </span>
                            </div>
                            <ng-container *ngIf="!partido.id_venta_equipo_1">
                              <button (click)="aumentarMarcadorEq1(partido)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                                <i class="fa fa fa-arrow-up"></i>
                              </button>
                              <button (click)="disminuirMarcadorEq1(partido)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                                <i class="fa fa fa-arrow-down"></i>
                              </button>
                            </ng-container>
                            <select *ngIf="partido.modificar" [compareWith]="compararObjectos" class="form-control" required name="{{partido.equipo_1.id}}" [(ngModel)]="partido.equipo_1_c">
                              <option [ngValue]="equipo" *ngFor="let equipo of partido.equipos">{{equipo.nombre}}</option>
                            </select>
                              <a title="Generar Venta" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" *ngIf="aplicacion_ventas.puede_crear && !partido.id_venta_equipo_1 && partido.id" (click)="generarVentaEquipo(partido,partido.equipo_1,null)">
                                <i class="fas fa-money-bill-alt"></i>
                              </a>
                          </td>
                          <td>
                            {{partido.equipo_2.nombre}}
                            <div class="text-nowrap" *ngIf="partido.marcador_equipo_2!=null">
                              <span [ngClass]="{'bgc-danger-l3 brc-danger-m3':partido.marcador_equipo_2<partido.marcador_equipo_1,'bgc-success-l3 brc-success-m3':partido.marcador_equipo_1<partido.marcador_equipo_2,'bgc-primary-l3 brc-primary-m3':partido.marcador_equipo_1==partido.marcador_equipo_2}" class="m-1 border-1 text-dark-tp3 text-180 px-25 py-3px radius-round">
                                {{partido.marcador_equipo_2}}
                              </span>
                            </div>
                            <div class="text-nowrap" *ngIf="partido.marcador_equipo_2==null">
                              <span class="bgc-warning m-1 border-1 text-dark-tp3 text-180 px-25 py-3px radius-round">
                                {{partido.marcador_equipo_2?partido.marcador_equipo_2:'?'}}
                              </span>
                            </div>
                            <ng-container *ngIf="!partido.id_venta_equipo_2">
                              <button (click)="aumentarMarcadorEq2(partido)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                                <i class="fa fa fa-arrow-up"></i>
                              </button>
                              <button (click)="disminuirMarcadorEq2(partido)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                                <i class="fa fa fa-arrow-down"></i>
                              </button>
                            </ng-container>
                              <select *ngIf="partido.modificar" [compareWith]="compararObjectos" class="form-control" required name="{{partido.equipo_2.id}}" [(ngModel)]="partido.equipo_2_c">
                                <option [ngValue]="equipo" *ngFor="let equipo of partido.equipos">{{equipo.nombre}}</option>
                              </select>
                              <a title="Generar Venta" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" *ngIf="aplicacion_ventas.puede_crear && !partido.id_venta_equipo_2 && partido.id" (click)="generarVentaEquipo(partido,null,partido.equipo_2)">
                                <i class="fas fa-money-bill-alt"></i>
                              </a>
                          </td>
                          <td>
                              {{partido.escenario.nombre}}
                              <select *ngIf="partido.modificar" [compareWith]="compararObjectos" class="form-control" required name="escenario" [(ngModel)]="partido.escenario">
                                <option [ngValue]="null" [selected]="true">Seleccionar escenario</option>
                                  <option [ngValue]="escenario" *ngFor="let escenario of escenarios">{{escenario.nombre}}</option>
                              </select>
                          </td>
                          <td>
                            {{partido.arbitro.persona.nombre_completo}}
                            <select *ngIf="partido.modificar" [compareWith]="compararObjectos" class="form-control" required name="arbitro" [(ngModel)]="partido.arbitro">
                              <option [ngValue]="null" [selected]="true">Seleccionar arbitro</option>
                                <option [ngValue]="arbitro" *ngFor="let arbitro of arbitros">{{arbitro.persona.nombre_completo}}</option>
                            </select>
                          </td>
                          <td style="text-align: center; width: 140px; ">
                            <div class="action-buttons">
                              <a *ngIf="aplicacion.puede_crear && !partido.modificar  && !partido.id_venta_equipo_1 && !partido.id_venta_equipo_2" title="Modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="modificarPartido(partido)">
                                <i class="fas fa-pencil-alt"></i>
                              </a>
                              <a *ngIf="partido.modificar" title="Modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="establecerPartidoEquipos(partido)">
                                <i class="fas fa-check"></i>
                              </a>
                              <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="generarPdfPlanillaPartido(partido)">
                                  <i class="fas fa-file-pdf text-105"></i>
                              </a>
                              <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="abrirModalResultados(partido)">
                                <i class="fas fa-poll text-105"></i>
                              </a>
                              <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="copiarEnlace(partido)">
                                <i class="fas fa-copy text-105"></i>
                              </a>
                              <a *ngIf="aplicacion.puede_eliminar && !partido.id_venta_equipo_1 && !partido.id_venta_equipo_2" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacionPartido(partido)">
                                <i class="fa fa-trash-alt text-105"></i>
                              </a>
                            </div>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #modal_resultado_partido let-modal>
  <div class="modal-header card-header">
      <div class="col-md-8 col-xs-8">
          <span class="text-blue text-125">Resultado Partido</span>
      </div>
      <div class="col-md-6 col-xs-6">
          <button (click)="guardarResultadoPartido()" class="btn btn-primary"  >
              <i class="fa fa-save text-110 mr-1"></i>
          </button>&nbsp;
          <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
              <i class="fa fa-times red2 text-110 mr-1"></i>
          </button>
      </div>
  </div>
  <div class="modal-body card-body">
      <div class="row bgc-primary-d1 text-white px-3 py-25">
          <div class="col-5 col-md-2 text-right">
            <label>{{partido.equipo_1.nombre}}</label>
          </div>
          <div class="col-3 col-md-2" >
            <label>¿Dio W.O.? <input (change)="establecerWOEquipo1()" name="switch-field-eq1" class="ace-switch input-lg ace-switch-sino bgc-green-d1" type="checkbox" [(ngModel)]="partido.w_o_equipo_1.es_wo"/></label>
            <button *ngIf="partido.id && partido.w_o_equipo_2.es_wo && !partido.w_o_equipo_1.id_venta" type="button" (click)="generarVentaWO(partido,partido.equipo_1,null,partido.equipo_2)" class="btn btn-success btn-sm">
              <i class="fas fa-money-bill-alt"></i>
            </button>
          </div>
          <div class="col-4 col-md-1 p-0" >
            <input [disabled]="partido.w_o_equipo_1.es_wo || partido.w_o_equipo_2.es_wo" type="number" min="0" step="0" class="form-control" name="marcador_1" [(ngModel)]="partido.marcador_equipo_1" [ngClass]="{'brc-danger-m1 border-3':(partido.marcador_equipo_1===null || partido.marcador_equipo_1 < 0)}">
          </div>
          <div class="col-12 col-md-2 text-center">
              <label>VS</label>
          </div>
          <div class="col-4 col-md-1 p-0">
            <input [disabled]="partido.w_o_equipo_1.es_wo || partido.w_o_equipo_2.es_wo" type="number" min="0" step="0" class="form-control" name="marcador_2" [(ngModel)]="partido.marcador_equipo_2" [ngClass]="{'brc-danger-m1 border-3':(partido.marcador_equipo_2===null || partido.marcador_equipo_2 < 0)}">
          </div>
          <div class="col-3 col-md-2" >
            <label>¿Dio W.O.? <input (change)="establecerWOEquipo2()" name="switch-field-eq2" class="ace-switch input-lg ace-switch-sino bgc-green-d1" type="checkbox" [(ngModel)]="partido.w_o_equipo_2.es_wo"/></label>
            <button *ngIf="partido.id && partido.w_o_equipo_1.es_wo && !partido.w_o_equipo_2.id_venta" type="button" (click)="generarVentaWO(partido,null,partido.equipo_2,partido.equipo_1)" class="btn btn-success btn-sm">
              <i class="fas fa-money-bill-alt"></i>
            </button>
          </div>
          <div class="col-5 col-md-2">
            <label>{{partido.equipo_2.nombre}}</label>
        </div>
      </div>
      <hr>
      <div class="row bgc-yellow-d1 text-black pt-2 p-0">
        <div class="col-6 col-md-6">
          <div class="row">
            <div class="col-12 col-md-3">
              <label>Amarillas</label>
              <a (click)="crearNuevoJugador(partido.equipo_1)" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)">
                <i class="fa fa-pencil-alt text-105"></i>
              </a>
            </div>
            <div class="col-10 col-md-7 p-0">
              <select [compareWith]="compararObjectos" class="form-control" name="eq_1_jugador_amarilla" [(ngModel)]="partido.eq_1_jugador_amarilla">
                <option [ngValue]="jugador" *ngFor="let jugador of partido.equipo_1.jugadores | orderBy:'persona.nombre_completo':false">{{jugador.persona.nombre_completo}}</option>
              </select>
            </div>
            <div class="col-2 col-md-2 p-0">
              <button type="button" (click)="agregarJugadorAmarillaEq1(partido.eq_1_jugador_amarilla)" class="btn btn-success btn-sm">
                <i class="ace-icon fa fa-arrow-down bigger-110"></i>
              </button>
            </div>
            <div class="col-12 p-0">
              <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Jugador</th>
                    <th>
                      <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                      <div class="fht-cell"></div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let amarilla_equipo_1 of partido.lista_amarillas_equipo_1; let i=index;">
                    <th>{{amarilla_equipo_1.persona.nombre_completo}}</th>
                    <td>
                      <a title="Generar Venta Amarilla" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" *ngIf="aplicacion_ventas.puede_crear && partido.id && !amarilla_equipo_1.id_venta" (click)="generarVentaJugadorAmarilla(partido,partido.equipo_1,null,amarilla_equipo_1)">
                        <i class="fas fa-money-bill-alt"></i>
                      </a>
                      <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacionAmarillaEq1(amarilla_equipo_1)">
                        <i class="fa fa-trash-alt text-105"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-6">
          <div class="row">
            <div class="col-12 col-md-3">
              <label>Amarillas</label>
              <a (click)="crearNuevoJugador(partido.equipo_2)" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)">
                <i class="fa fa-pencil-alt text-105"></i>
              </a>
            </div>
            <div class="col-10 col-md-7 p-0">
              <select [compareWith]="compararObjectos" class="form-control" name="eq_2_jugador_amarilla" [(ngModel)]="partido.eq_2_jugador_amarilla">
                <option [ngValue]="jugador" *ngFor="let jugador of partido.equipo_2.jugadores | orderBy:'persona.nombre_completo':false">{{jugador.persona.nombre_completo}}</option>
              </select>
            </div>
            <div class="col-2 col-md-2 p-0">
              <button type="button" (click)="agregarJugadorAmarillaEq2(partido.eq_2_jugador_amarilla)" class="btn btn-success btn-sm">
                <i class="ace-icon fa fa-arrow-down bigger-110"></i>
              </button>
            </div>
            <div class="col-12 p-0">
              <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Jugador</th>
                    <th>
                      <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                      <div class="fht-cell"></div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let amarilla_equipo_2 of partido.lista_amarillas_equipo_2; let i=index;">                    
                    <th>{{amarilla_equipo_2.persona.nombre_completo}}</th>
                    <td>
                      <a title="Generar Venta Amarilla" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" *ngIf="aplicacion_ventas.puede_crear && partido.id && !amarilla_equipo_2.id_venta" (click)="generarVentaJugadorAmarilla(partido,null,partido.equipo_2,amarilla_equipo_2)">
                        <i class="fas fa-money-bill-alt"></i>
                      </a>
                      <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacionAmarillaEq2(amarilla_equipo_2)">
                        <i class="fa fa-trash-alt text-105"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="row bgc-danger-d1 text-white pt-2 p-0">
        <div class="col-6 col-md-6">
          <div class="row">
            <div class="col-12 col-md-3">
              <label>Rojas</label>
              <a (click)="crearNuevoJugador(partido.equipo_1)" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-white btn-a-outline-white btn-text-white" href="javascript:void(0)">
                <i class="fa fa-pencil-alt text-105"></i>
              </a>
            </div>
            <div class="col-10 col-md-7 p-0">
              <select [compareWith]="compararObjectos" class="form-control" name="eq_1_jugador_roja" [(ngModel)]="partido.eq_1_jugador_roja">
                <option [ngValue]="jugador" *ngFor="let jugador of partido.equipo_1.jugadores | orderBy:'persona.nombre_completo':false">{{jugador.persona.nombre_completo}}</option>
              </select>
            </div>
            <div class="col-2 col-md-2 p-0">
              <button type="button" (click)="agregarJugadorRojaEq1(partido.eq_1_jugador_roja)" class="btn btn-success btn-sm">
                <i class="ace-icon fa fa-arrow-down bigger-110"></i>
              </button>
            </div>
            <div class="col-12 p-0">
              <table class="table table-striped table-hover">
                <thead class="text-white">
                  <tr>
                    <th><label>Jugador</label></th>
                    <th>
                      <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                      <div class="fht-cell"></div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let roja_equipo_1 of partido.lista_rojas_equipo_1; let i=index;">
                    <th>{{roja_equipo_1.persona.nombre_completo}}</th>
                    <td>
                      <a title="Generar Venta Roja" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" *ngIf="aplicacion_ventas.puede_crear && partido.id && !roja_equipo_1.id_venta" (click)="generarVentaJugadorRoja(partido,partido.equipo_1,null,roja_equipo_1)">
                        <i class="fas fa-money-bill-alt"></i>
                      </a>
                      <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacionRojaEq1(roja_equipo_1)">
                        <i class="fa fa-trash-alt text-105"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-6">
          <div class="row">
            <div class="col-12 col-md-3">
              <label>Rojas</label>
              <a (click)="crearNuevoJugador(partido.equipo_2)" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-white btn-a-outline-white btn-text-white" href="javascript:void(0)">
                <i class="fa fa-pencil-alt text-105"></i>
              </a>
            </div>
            <div class="col-10 col-md-7 p-0">
              <select [compareWith]="compararObjectos" class="form-control" name="eq_2_jugador_roja" [(ngModel)]="partido.eq_2_jugador_roja">
                <option [ngValue]="jugador" *ngFor="let jugador of partido.equipo_2.jugadores | orderBy:'persona.nombre_completo':false">{{jugador.persona.nombre_completo}}</option>
              </select>
            </div>
            <div class="col-2 col-md-2 p-0">
              <button type="button" (click)="agregarJugadorRojaEq2(partido.eq_2_jugador_roja)" class="btn btn-success btn-sm">
                <i class="ace-icon fa fa-arrow-down bigger-110"></i>
              </button>
            </div>
            <div class="col-12 p-0">
              <table class="table table-striped table-hover">
                <thead class="text-white">
                  <tr>
                    <th>Jugador</th>
                    <th>
                      <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                      <div class="fht-cell"></div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let roja_equipo_2 of partido.lista_rojas_equipo_2; let i=index;">
                    <th>{{roja_equipo_2.persona.nombre_completo}}</th>
                    <td>
                      <a title="Generar Venta Roja" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" *ngIf="aplicacion_ventas.puede_crear && partido.id && !roja_equipo_2.id_venta" (click)="generarVentaJugadorRoja(partido,null,partido.equipo_2,roja_equipo_2)">
                        <i class="fas fa-money-bill-alt"></i>
                      </a>
                      <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacionRojaEq2(roja_equipo_2)">
                        <i class="fa fa-trash-alt text-105"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="row bgc-purple-d1 text-white pt-2 p-0">
        <div class="col-6 col-md-6">
          <div class="row">
            <div class="col-12 col-md-3">
              <label>Marcador</label>
              <a (click)="crearNuevoJugador(partido.equipo_1)" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-white btn-a-outline-white btn-text-white" href="javascript:void(0)">
                <i class="fa fa-pencil-alt text-105"></i>
              </a>
            </div>
            <div class="col-12 col-md-5 p-0">
              <select [compareWith]="compararObjectos" class="form-control" name="eq_1_jugador_marcador" [(ngModel)]="partido.eq_1_jugador_marcador">
                <option [ngValue]="jugador" *ngFor="let jugador of partido.equipo_1.jugadores | orderBy:'persona.nombre_completo':false">{{jugador.persona.nombre_completo}}</option>
              </select>
            </div>
            <div class="col-8 col-md-3">
              <input type="number" min="1" step="0" class="form-control" name="marcador_jugador_1" [(ngModel)]="partido.marcador_jugador_1">
            </div>
            <div class="col-4 col-md-1">
              <button type="button" (click)="agregarJugadorMarcadorEq1(partido.eq_1_jugador_marcador,partido.marcador_jugador_1)" class="btn btn-success btn-sm">
                <i class="ace-icon fa fa-arrow-down bigger-110"></i>
              </button>
            </div>
            <div class="col-12 p-0">
              <table class="table table-striped table-hover">
                <thead class="text-white">
                  <tr>
                    <th><label>Jugador</label></th>
                    <th><label>Marc.</label></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let marcador_equipo_1 of partido.lista_marcador_equipo_1; let i=index;">
                    <th>{{marcador_equipo_1.jugador.persona.nombre_completo}}</th>
                    <th>
                      {{marcador_equipo_1.marcador}}
                      <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacionMarcadorEq1(marcador_equipo_1)">
                        <i class="fa fa-trash-alt text-105"></i>
                      </a>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-6">
          <div class="row">
            <div class="col-12 col-md-3">
              <label>Marcador</label>
              <a (click)="crearNuevoJugador(partido.equipo_2)" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-white btn-a-outline-white btn-text-white" href="javascript:void(0)">
                <i class="fa fa-pencil-alt text-105"></i>
              </a>
            </div>
            <div class="col-12 col-md-5 p-0">
              <select [compareWith]="compararObjectos" class="form-control" name="eq_2_jugador_marcador" [(ngModel)]="partido.eq_2_jugador_marcador">
                <option [ngValue]="jugador" *ngFor="let jugador of partido.equipo_2.jugadores | orderBy:'persona.nombre_completo':false"
                >{{jugador.persona.nombre_completo}}</option>
              </select>
            </div>
            <div class="col-8 col-md-3 ">
              <input type="number" min="1" step="0" class="form-control" name="marcador_jugador_2" [(ngModel)]="partido.marcador_jugador_2">
            </div>
            <div class="col-4 col-md-1 ">
              <button type="button" (click)="agregarJugadorMarcadorEq2(partido.eq_2_jugador_marcador,partido.marcador_jugador_2)" class="btn btn-success btn-sm">
                <i class="ace-icon fa fa-arrow-down bigger-110"></i>
              </button>
            </div>
            <div class="col-12 p-0">
              <table class="table table-striped table-hover">
                <thead class="text-white">
                  <tr>
                    <th><label>Jugador</label></th>
                    <th><label>Marc.</label></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let marcador_equipo_2 of partido.lista_marcador_equipo_2; let i=index;">
                    <th>{{marcador_equipo_2.jugador.persona.nombre_completo}}</th>
                    <th>
                      {{marcador_equipo_2.marcador}}
                      <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacionMarcadorEq2(marcador_equipo_2)">
                        <i class="fa fa-trash-alt text-105"></i>
                      </a>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
  </div>
</ng-template>

<ng-template #modal_tabla_posiciones let-modal>
  <div class="modal-header card-header">
      <div class="col-md-8 col-xs-8">
          <span class="text-blue text-125">Tabla de Posiciones</span>
      </div>
      <div class="col-md-6 col-xs-6">
          <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
              <i class="fa fa-times red2 text-110 mr-1"></i>
          </button>
      </div>
  </div>
  <div class="modal-body card-body">
      <div class="row bgc-primary-d1 text-white px-3 py-25">
          <div class="col-md-2">
              <label>Campeonato</label>
              <select [compareWith]="compararObjectos" class="form-control" required name="campeonato" [(ngModel)]="tabla_posiciones.campeonato">
                <option [ngValue]="campeonato" *ngFor="let campeonato of campeonatos">{{campeonato.nombre}}</option>
              </select>
          </div>
          <div class="col-md-2">
            <label>Disciplina</label>
            <select [compareWith]="compararObjectos" class="form-control" required name="disciplina" [(ngModel)]="tabla_posiciones.disciplina">
              <option [ngValue]="disciplina" *ngFor="let disciplina of tipo_disciplina.clases">{{disciplina.nombre}}</option>
            </select>
        </div>
          <div class="col-md-2 p-0">
              <label>Categoria</label>
              <select [compareWith]="compararObjectos" class="form-control" required name="categoria" [(ngModel)]="tabla_posiciones.categoria">
                <option [ngValue]="categoria" *ngFor="let categoria of tipo_categoria.clases">{{categoria.nombre}}</option>
              </select>
          </div>
          <div class="col-md-3">
            <label>Fase</label>
            <select [compareWith]="compararObjectos" class="form-control" required name="fase" [(ngModel)]="tabla_posiciones.fase">
              <option [ngValue]="fase" *ngFor="let fase of tipo_fase.clases">{{fase.nombre}}</option>
            </select>
          </div>
          <div class="col-md-3 p-0">
            <button (click)="buscarTablaPosiciones()" class="btn btn-xs btn-app btn-success my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Agregar">
              <i class="d-block h-4 fa fa-search text-150"></i>
            </button>&nbsp;
            <button (click)="generarPdfTablaPosiciones()" class="btn btn-xs btn-app btn-danger my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Agregar">
              <i class="d-block h-4 fa fa-file-pdf text-150"></i>
            </button>
          </div>

      </div>
      <hr>
      <div class="row">
          <div class="col-12">
              <table class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="table">
                  <thead class="bgc-white text-grey text-uppercase text-80">
                    <tr>
                      <th>N°</th>
                      <th>Equipo</th>
                      <th>PJ</th>
                      <th>G</th>
                      <th>E</th>
                      <th>P</th>
                      <th>MF</th>
                      <th>MC</th>
                      <th>MD</th>
                      <th>PTS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let equipo of equipos; let i=index;">
                      <tr>
                        <td>
                          {{(i+1)}}
                          <input type="color" name="{{(i+1)}}" [(ngModel)]="equipo.clasificacion" class="form-control" />
                        </td>
                        <td>
                            {{equipo.nombre}}
                        </td>
                        <td>
                          {{equipo.partidos_jugados}}
                        </td>
                        <td>
                          {{equipo.victorias}}
                        </td>
                        <td >
                          {{equipo.empates}}
                        </td>
                        <td>
                          {{equipo.derrotas}}
                        </td>
                        <td>
                          {{equipo.marcador_a_favor}}
                        </td>
                        <td>
                          {{equipo.marcador_contra}}
                        </td>
                        <td>
                          {{equipo.marcador_diferencia}}
                        </td>
                        <td>
                          {{equipo.puntos}}
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
          </div>
      </div>
  </div>
</ng-template>

<ng-template #modal_rol_partidos_arbitros let-modal>
  <div class="modal-header card-header">
      <div class="col-md-8 col-xs-8">
          <span class="text-blue text-125">Rol Partidos Arbitro</span>
      </div>
      <div class="col-md-6 col-xs-6">
          <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
              <i class="fa fa-times red2 text-110 mr-1"></i>
          </button>
      </div>
  </div>
  <div class="modal-body card-body">
      <div class="row bgc-primary-d1 text-white px-3 py-25">
          <div class="col-md-3">
              <label>Campeonato</label>
              <select [compareWith]="compararObjectos" class="form-control" required name="campeonato" [(ngModel)]="rol_partido_arbitro.campeonato">
                <option [ngValue]="campeonato" *ngFor="let campeonato of filter.campeonatos">{{campeonato.nombre}}</option>
              </select>
          </div>
          <div class="col-md-2">
              <label>Arbitro</label>
              <select [compareWith]="compararObjectos" class="form-control" required name="arbitro" [(ngModel)]="rol_partido_arbitro.arbitro">
                <option [ngValue]="null" [selected]="true">Seleccionar arbitro</option>
                  <option [ngValue]="arbitro" *ngFor="let arbitro of arbitros">{{arbitro.persona.nombre_completo}}</option>
              </select>
          </div>
          <div class="col-md-2 col-sm-12">
            <label for="feini">Desde </label>&nbsp;
            <div class="input-group">
                <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                        name="dp" [(ngModel)]="rol_partido_arbitro.fecha_inicio" ngbDatepicker #fi="ngbDatepicker">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                </div>
            </div>  
          </div>
          <div class="col-md-2 col-sm-12">
            <label for="fefin">Hasta </label>&nbsp;
            <div class="input-group">
                <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                        name="dp" [(ngModel)]="rol_partido_arbitro.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                </div>
            </div>  
          </div>
          <div class="col-md-3 p-0">
            <button (click)="buscarPartidosArbitro()" class="btn btn-xs btn-app btn-success my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Agregar">
              <i class="d-block h-4 fa fa-search text-150"></i>
            </button>&nbsp;
            <button (click)="generarPdfRolPartidosArbitro()" class="btn btn-xs btn-app btn-danger my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Agregar">
              <i class="d-block h-4 fa fa-file-pdf text-150"></i>
            </button>
          </div>

      </div>
      <hr>
      <div class="row">
          <div class="col-12">
              <table class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="table">
                  <thead class="bgc-white text-grey text-uppercase text-80">
                    <tr>
                      <th class="text-center pr-0">
                        <label class="py-0">
                          <input [(ngModel)]="allItemsSelected" (change)="selectAllItems(rol_partido_arbitro.partidos)" type="checkbox" class="align-bottom mb-n1 border-2 text-dark-m3" />
                        </label>
                      </th>
                      <th>N°</th>
                      <th>Campeonato</th>
                      <th>Fecha - Hora</th>
                      <th>Equipo 1</th>
                      <th>Equipo 2</th>
                      <th>Escenario</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let partido of rol_partido_arbitro.partidos; let i=index;">
                      <tr>
                        <td>
                          <label>
                            <input [(ngModel)]="partido.selected" (change)="addItemToSelected(partido)" type="checkbox" class="align-middle" />
                          </label>
                        </td>
                        <td>
                          {{(i+1)}}
                        </td>
                        <td>
                            {{partido.rol_partido.campeonato.nombre}}
                        </td>
                        <td>
                          {{partido.fecha_hora | date:'dd/MM/yyyy HH:mm'}}
                        </td>
                        <td>
                          {{partido.equipo_1.nombre}}
                        </td>
                        <td>
                          {{partido.equipo_2.nombre}}
                        </td>
                        <td>
                          {{partido.escenario.nombre}}
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
          </div>
      </div>
  </div>
</ng-template>

<ng-template #modal_tabla_goleadores let-modal>
  <div class="modal-header card-header">
      <div class="col-md-8 col-xs-8">
          <span class="text-blue text-125">Tabla de Goleadores</span>
      </div>
      <div class="col-md-6 col-xs-6">
          <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
              <i class="fa fa-times red2 text-110 mr-1"></i>
          </button>
      </div>
  </div>
  <div class="modal-body card-body">
      <div class="row bgc-primary-d1 text-white px-3 py-25">
          <div class="col-md-3">
              <label>Campeonato</label>
              <select [compareWith]="compararObjectos" class="form-control" required name="campeonato" [(ngModel)]="tabla_posiciones.campeonato">
                <option [ngValue]="campeonato" *ngFor="let campeonato of campeonatos">{{campeonato.nombre}}</option>
              </select>
          </div>
          <div class="col-md-3">
            <label>Disciplina</label>
            <select [compareWith]="compararObjectos" class="form-control" required name="disciplina" [(ngModel)]="tabla_posiciones.disciplina">
              <option [ngValue]="disciplina" *ngFor="let disciplina of tipo_disciplina.clases">{{disciplina.nombre}}</option>
            </select>
        </div>
          <div class="col-md-2 p-0">
              <label>Categoria</label>
              <select [compareWith]="compararObjectos" class="form-control" required name="categoria" [(ngModel)]="tabla_posiciones.categoria">
                <option [ngValue]="categoria" *ngFor="let categoria of tipo_categoria.clases">{{categoria.nombre}}</option>
              </select>
          </div>
          <div class="col-md-4 p-0">
            <button (click)="buscarTablaGoleadores()" class="btn btn-xs btn-app btn-success my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Agregar">
              <i class="d-block h-4 fa fa-search text-150"></i>
            </button>&nbsp;
            <button (click)="generarPdfTablaGoleadores()" class="btn btn-xs btn-app btn-danger my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Agregar">
              <i class="d-block h-4 fa fa-file-pdf text-150"></i>
            </button>
          </div>

      </div>
      <hr>
      <div class="row">
          <div class="col-12">
              <table class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="table">
                  <thead class="bgc-white text-grey text-uppercase text-80">
                    <tr>
                      <th>N°</th>
                      <th>Logo</th>
                      <th>Equipo</th>
                      <th>Foto</th>
                      <th>Jugador</th>
                      <th>Marcador</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let goleador of goleadores; let i=index;">
                      <tr>
                        <td>
                          {{(i+1)}}
                        </td>
                        <td>
                          <img width="50" height="50" alt="sin imagen" src="{{rest_server+goleador.logo_equipo}}" />
                        </td>
                        <td>
                          {{goleador.equipo}}
                        </td>
                        <td>
                          <img width="50" height="50" alt="sin imagen" src="{{rest_server+goleador.foto_jugador}}" />
                        </td>
                        <td >
                          {{goleador.jugador}}
                        </td>
                        <td >
                          {{goleador.marcador}}
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
          </div>
      </div>
  </div>
</ng-template>
