import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { GlobalVariable } from '../../../../app/global';

@Injectable({
  providedIn: 'root'
})
export class EstudiantesService {

  constructor(private http: HttpClient) { }

  public guardarImportacionEstudiantes(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"profesor-importacion-estudiantes",datos,GlobalVariable.httpOptions);
  }

  public obtenerListaEstudiantes(id_gestion,id_curso){
    return this.http.get(GlobalVariable.API_SERVER_URL+"lista-estudiantes/gestion/"+id_gestion+"/curso/"+id_curso,GlobalVariable.httpOptions);
  }

  public obtenerListaEstudiantesInstitutoPaginador(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"estudiantes/escuela/"+paginator.filter.escuela.id+"/sucursal/"+paginator.filter.sucursal.id+"/codigo_estudiante/"+(paginator.filter.codigo==null?0:paginator.filter.codigo)+"/carrera/"+paginator.filter.carrera.id+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+paginator.search+"/columna/"+paginator.configuracion_pagina.getOrden().columna+"/direccion/"+paginator.direction,GlobalVariable.httpOptions);
  }

  public obtenerListaEstudiantesColegioPaginador(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"estudiantes/escuela/"+paginator.filter.escuela.id+"/sucursal/"+paginator.filter.sucursal.id+"/codigo_estudiante/"+(paginator.filter.codigo==null?0:paginator.filter.codigo)+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+paginator.search+"/columna/"+paginator.configuracion_pagina.getOrden().columna+"/direccion/"+paginator.configuracion_pagina.getOrden().direccion,GlobalVariable.httpOptions);
  }

  public guardarEstudiante(estudiante){
    return this.http.post(GlobalVariable.API_SERVER_URL+"estudiantes",estudiante,GlobalVariable.httpOptions);
  }

  public obtenerEstudiante(id_estudiante){
    return this.http.get(GlobalVariable.API_SERVER_URL+"estudiantes/"+id_estudiante,GlobalVariable.httpOptions);
  }

  public actualizarEstudiante(estudiante){
    return this.http.put(GlobalVariable.API_SERVER_URL+"estudiantes/"+estudiante.id,estudiante,GlobalVariable.httpOptions);
  }

  public anularInscripcion(id_estudiante,id_gestion,id_curso){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"inscripcion-estudiante/"+id_estudiante+"/gestion/"+id_gestion+"/curso/"+id_curso,GlobalVariable.httpOptions);
  }

  public eliminarEstudiante(id_estudiante){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"estudiantes/"+id_estudiante,GlobalVariable.httpOptions);
  }

  public obtenerInscripcionesInstitutoEstudiante(id_estudiante){
    return this.http.get(GlobalVariable.API_SERVER_URL+"inscripciones-instituto/estudiante/"+id_estudiante,GlobalVariable.httpOptions);
  }

  public obtenerInscripcionesColegioEstudiante(id_estudiante){
    return this.http.get(GlobalVariable.API_SERVER_URL+"inscripciones-colegio/estudiante/"+id_estudiante,GlobalVariable.httpOptions);
  }

  public consultarFichaEconomicaEstudiante(id_empresa,codigo){
    return this.http.get(GlobalVariable.API_SERVER_URL+"datos-consulta-ficha-economica-estudiante/empresa/"+id_empresa+"/codigo-estudiante/"+codigo,GlobalVariable.httpOptions);
  }

  public buscarEstudiante(id_empresa,texto_busqueda){
    if (texto_busqueda === '') {
      return of([]);
    }
    return this.http.get(GlobalVariable.API_SERVER_URL+"estudiantes/empresa/"+id_empresa+"/busqueda/"+texto_busqueda,GlobalVariable.httpOptions);
  }

  public obtenerPagosPendientesEstudiante(id_estudiante){
    return this.http.get(GlobalVariable.API_SERVER_URL+"estudiante/pagos-pendientes/"+id_estudiante,GlobalVariable.httpOptions);
  }

  public obtenerPagosPendientesEstudianteColegio(id_estudiante){
    return this.http.get(GlobalVariable.API_SERVER_URL+"estudiante-colegio/pagos-pendientes/"+id_estudiante,GlobalVariable.httpOptions);
  }

  public eliminarConceptoPagoEstudiante(id_estudiante){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"instituto/concepto-pago-estudiante/"+id_estudiante,GlobalVariable.httpOptions);
  }

  public eliminarConceptoPagoEstudianteColegio(concepto_pago_estudiante){
    return this.http.patch(GlobalVariable.API_SERVER_URL+"colegio/concepto-pago-estudiante/"+concepto_pago_estudiante.id,concepto_pago_estudiante,GlobalVariable.httpOptions);
  }

  public eliminarAsignacionConceptoPagoEstudianteColegio(datos){
    return this.http.patch(GlobalVariable.API_SERVER_URL+"colegio/asignacion-concepto-pago-estudiante/"+datos.id_estudiante,datos,GlobalVariable.httpOptions);
  }

  public guardarImportacionEstudiantesColegio(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"colegio-importacion-estudiantes",datos,GlobalVariable.httpOptions);
  }

  public obtenerListaTutoresPaginador(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"tutores/escuela/"+paginator.filter.escuela.id+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+paginator.search+"/columna/"+paginator.column+"/direccion/"+paginator.direction,GlobalVariable.httpOptions);
  }

  public eliminarTutor(id_persona){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"tutores/"+id_persona,GlobalVariable.httpOptions);
  }

  public guardarImportacionTutores(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"importacion-tutores",datos,GlobalVariable.httpOptions);
  }

  public obtenerRudesEstudiantes(datos){
    return this.http.get(GlobalVariable.API_SERVER_URL+"rudes-estudiantes/gestion/"+datos.id_gestion+"/curso/"+datos.id_curso+"/estudiante/"+datos.id_estudiante,GlobalVariable.httpOptions);
  }

  public obtenerListaPagosEstudiantesVencidos(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"vencimientos-pagos-estudiantes/"+id_empresa+"/estado/1",GlobalVariable.httpOptions);
  }

  public obtenerListaPagosEstudiantesVencidosAnulados(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"vencimientos-pagos-estudiantes/"+id_empresa+"/estado/0",GlobalVariable.httpOptions);
  }

  public obtenerDocumentosEstudianteGestion(id_estudiante,id_gestion){
    return this.http.get(GlobalVariable.API_SERVER_URL+"documentos-estudiante/estudiante/"+id_estudiante+"/gestion/"+id_gestion,GlobalVariable.httpOptions);
  }

  public guardarDocumentos(documento){
    return this.http.post(GlobalVariable.API_SERVER_URL+"documentos-estudiante",documento,GlobalVariable.httpOptions);
  }

  public actualizarDocumentos(documento){
    return this.http.patch(GlobalVariable.API_SERVER_URL+"documentos-estudiante/"+documento.id,documento,GlobalVariable.httpOptions);
  }

  public guardarAsuntoAdministrativo(asunto_administrativo){
    return this.http.post(GlobalVariable.API_SERVER_URL+"asunto-administrativo-estudiante",asunto_administrativo,GlobalVariable.httpOptions);
  }

  public obtenerAsuntosAdmnistrativosPendientes(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"asuntos-administrativos/"+id_empresa,GlobalVariable.httpOptions);
  }

  public cambiarEstadoAsuntoAdmnistrativo(asunto_administrativo){
    return this.http.put(GlobalVariable.API_SERVER_URL+"cambio-estado-asunto-administrativo/"+asunto_administrativo.id,asunto_administrativo,GlobalVariable.httpOptions);
  }

  public obtenerMigracionesInscripcion(migraciones_inscripcion){
    return this.http.post(GlobalVariable.API_SERVER_URL+"migracion-inscripcion",migraciones_inscripcion,GlobalVariable.httpOptions);
  }

  public migrarInscripcion(migraciones_inscripcion){
    return this.http.post(GlobalVariable.API_SERVER_URL+"migracion-inscripcion-estudiante",migraciones_inscripcion,GlobalVariable.httpOptions);
  }

  public obtenerDeudasEstudiantes(filter){
    return this.http.get(GlobalVariable.API_SERVER_URL+"reporte-deudas-estudiantes/"+filter.id_empresa+"/sucursal/"+filter.sucursal.id+"/gestion/"+filter.gestion.id+"/estado/"+filter.estado.id,GlobalVariable.httpOptions);
  }


}
