<div class="page-content container container-plus">
    <div class="page-header border-0 mb-3">
      <h1 class="page-title text-primary-d2 text-150">
        Asistencia
        <small class="page-info text-secondary-d4">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <div class="row">
        <div class="col-10">
          <div class="row">
            <ng-container *ngIf="usuario.empresa.usar_sucursales">
              <div class="col-12 col-md-2"><label>Sucursal </label></div>
              <div class="col-12 col-md-2">
                  <select [compareWith]="compararObjectos" class="form-control" required name="sucursal" [(ngModel)]="filter.sucursal">
                      <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                  </select>
              </div>
            </ng-container>
              
              <div class="col-12 col-md-2"><label>Curso - Materia </label></div>
              <div class="col-12 col-md-2">
                  <select [compareWith]="compararObjectos" class="form-control" required name="registro_profesor" [(ngModel)]="filter.registro_profesor">
                      <option [ngValue]="registro_profesor" *ngFor="let registro_profesor of filter.registros_profesor">{{registro_profesor.curso.nombre}} - {{registro_profesor.materia.nombre}}</option>
                  </select>
              </div>
              <div class="col-12 col-md-2"><label>Trimestre </label></div>
              <div class="col-12 col-md-2">
                  <select [compareWith]="compararObjectos" class="form-control" required name="intervalo_escolar" [(ngModel)]="filter.intervalo_escolar">
                      <option [ngValue]="intervalo_escolar" *ngFor="let intervalo_escolar of filter.intervalos_escolares">{{intervalo_escolar.nombre}}</option>
                  </select>
              </div>
          </div>
        </div>
        <div class="col-2">
          <div class="row">
            <div class="col-12 col-md-3 pb-2">
                <button [disabled]="!filter.intervalo_escolar || !filter.registro_profesor" (click)="buscarAsistencias()" type="button" class="btn btn-sm btn-lighter-info btn-a-outline-info dropdown-toggle border-b-2">
                    <i class="fa fa-search text-180"></i>
                </button>
            </div>
            <div class="col-12 col-md-3 pb-2" *ngIf="fechas_asistencia.length>0">
              <button (click)="generarPdfAsistenciasRegistro()" type="button" class="btn btn-sm btn-success btn-a-outline-success dropdown-toggle border-b-2">
                  <i class="fas fa-file-pdf text-180"></i>
              </button>
            </div>
            <div class="col-12 col-md-3 pb-2" *ngIf="fechas_asistencia.length>0">
              <button (click)="generarPdfAsistencia()" type="button" class="btn btn-sm btn-danger btn-a-outline-danger dropdown-toggle border-b-2">
                  <i class="fas fa-file-pdf text-180"></i>
              </button>
            </div>
            <div class="col-12 col-md-3" *ngIf="fechas_asistencia.length>0">
              <button *ngIf="aplicacion.puede_crear" (click)="crearNuevaAsistencia()" type="button" class="btn btn-sm btn-warning btn-a-outline-warning dropdown-toggle border-b-2">
                  <i class="fa fa-plus text-180"></i>
              </button>
            </div>
        </div>
        </div>
      </div>
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header" style="display: none;">
          <table></table>
        </div>
        <div id="table_asistencia" class="table-responsive table-fixed-header" style="height: 600px;">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>

          <table *ngIf="estudiantes.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped" >
            <thead class="bgc-white text-grey text-uppercase text-80">
            <tr >
                <th style="z-index:2 !important;" class="sticky-col first-col bgc-primary-d1 text-white text-center">
                    Nº
                </th>
                <th style="z-index:2 !important;" class="sticky-col second-col bgc-primary-d1 text-white text-center">
                    Estudiante
                </th>
                <th *ngFor="let asistencia of fechas_asistencia" [ngClass]="{'asistencia-edicion':asistencia.modificado}" class="bgc-primary-d1 text-white">
                  
                    <div class="col-2" *ngIf="asistencia.id">
                      <a *ngIf="aplicacion.puede_eliminar" (click)="abrirPopupConfirmacionEliminacion(asistencia)"  class="btn btn-sm btn-lighter-danger btn-a-outline-danger dropdown-toggle border-b-2" href="javascript:void(0)">          
                        <i class="fa fa-trash-alt text-105"></i>        
                      </a>&nbsp;
                    </div>
                    <div *ngIf="asistencia.modificado" class="col-8">
                      <div class="input-group">
                        <div class="input-group-append">
                          <button class="btn btn-sm calendar btn-purple" (click)="abrirCalendario(d,asistencia)" type="button">
                            <i class="fa fa-calendar text-105"></i>        
                          </button>
                        </div>&nbsp;
                        <input type="hidden" name="fecha{{asistencia.id}}" class="form-control" placeholder="dd/mm/yyyy"
                                name="dp" [(ngModel)]="asistencia.fecha_texto" ngbDatepicker #d="ngbDatepicker" [placement]="'bottom'">
                        <span>{{asistencia.fecha_texto.day}}/{{asistencia.fecha_texto.month}}/{{asistencia.fecha_texto.year}}</span>&nbsp;
                      </div> 
                      &nbsp;
                    </div>
                    <div *ngIf="!asistencia.modificado" class="col-8"><span>{{asistencia.fecha | date:'dd/MM/yyyy'}}</span>&nbsp;</div>
                    <div class="col-2">
                      <a (click)="modificarAsistencia(asistencia)" *ngIf="aplicacion.puede_modificar && asistencia.id && !asistencia.modificado" class="btn btn-sm btn-lighter-success btn-a-outline-success dropdown-toggle border-b-2" href="javascript:void(0)">          
                        <i class="fa fa-pencil-alt text-105"></i>        
                      </a>
                      <a *ngIf="asistencia.modificado" (click)="guardarAsistencia(asistencia)" class="btn btn-sm btn-lighter-primary btn-a-outline-primary dropdown-toggle border-b-2" href="javascript:void(0)">          
                          <i class="fa fa-save text-105"></i>        
                      </a>
                      <a *ngIf="aplicacion.puede_crear && !asistencia.id && !asistencia.modificado" (click)="generarNuevoRegistroAsistencia(asistencia)" class="btn btn-sm btn-warning btn-a-outline-warning dropdown-toggle border-b-2" href="javascript:void(0)">          
                          <i class="fa fa-plus text-105"></i>        
                      </a>&nbsp;
                    </div>
                  
                </th>
            </tr>
            </thead>
            <tbody>
                <tr #estudiantes_lectura (click)="verEstudiante(estudiante)" [ngClass]="{'bgc-success-l2':estudiante.id==estudiante_actual.id}" class="bgc-h-success-l3 jqgrow ui-row-ltr" *ngFor="let estudiante of estudiantes; let i=index;">
                    <td class="sticky-col first-col text-grey bgc-h-success-l3" [ngClass]="{'bgc-success-l2':estudiante.id==estudiante_actual.id,'bgc-white':estudiante.id!=estudiante_actual.id}">{{(i+1)}}</td>
                    <td class="sticky-col second-col text-grey bgc-h-success-l3" [ngClass]="{'bgc-success-l2':estudiante.id==estudiante_actual.id,'bgc-white':estudiante.id!=estudiante_actual.id}" >{{estudiante.persona.nombre_completo}}</td>
                    <td class="text-center" *ngFor="let asistencia of estudiante.asistencias | orderBy:'asistencia.fecha':false;let j=index;">
                        <select *ngIf="asistencia.modificado" [compareWith]="compararObjectos" class="form-control {{asistencia.estado.nombre}}" required name="estado{{i}}{{j}}" [(ngModel)]="asistencia.estado">
                            <option class="{{estado.nombre}}" [ngValue]="estado" *ngFor="let estado of estados_asistencia">{{estado.nombre_corto}}</option>
                        </select>
                        <span *ngIf="asistencia.id && !asistencia.modificado" class="m-1 badge radius-round text-dark-tp15 px-3 text-90" [ngClass]="{'bgc-green-l1':asistencia.estado.nombre_corto=='P','bgc-red-l1':asistencia.estado.nombre_corto=='AU','bgc-yellow-l1':asistencia.estado.nombre_corto=='AT','bgc-blue-l1':asistencia.estado.nombre_corto=='L'}">{{asistencia.estado.nombre_corto}}</span>
                        <span *ngIf="!asistencia.id && !asistencia.modificado"></span>
                    </td>
                </tr>
            </tbody>
          </table>

            
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
</div>