import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { BaseComponent } from 'src/app/models/base-component';
import { RolesPartidosService } from '../../services/roles-partidos/roles-partidos.service';
import { Socket } from 'ngx-socket-io';

declare const $:any;

@Component({
  selector: 'app-consulta-marcador-partido',
  templateUrl: './consulta-marcador-partido.component.html',
  styleUrls: ['./consulta-marcador-partido.component.css']
})
export class ConsultaMarcadorPartidoComponent extends BaseComponent implements OnInit {

  id_partido:any;
  partido:any;

  constructor(private _Activatedroute:ActivatedRoute,
    private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    public rolesPartidosService:RolesPartidosService,
    public socket:Socket) {
      super(persistenciaService,modalService,generalService,usuarioService);
     }

  ngOnInit(): void {
    $('.body-container').css('background-image', 'none');
    $('.body-container').css('background-color', 'rgba(255, 255, 255, 255)');
    $('html').css('background-color', 'rgba(255,255,255,255)');
    this.id_partido=this._Activatedroute.snapshot.paramMap.get("id_partido");
    this.obtenerPartido();
    let me=this;
    this.socket.on('recepcionarCambioMarcador', function (partido) {
      me.partido=partido;
    });
  }

  obtenerPartido(){
    this.blockUI.start();
    this.rolesPartidosService.obtenerPartido(this.id_partido).subscribe((res:any)=>{
      this.blockUI.stop();
        this.partido=res;
        console.log(res);
    });
  }

}
