import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OrderPipe } from 'ngx-order-pipe';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { CalificacionesService } from '../../services/calificaciones/calificaciones.service';
import { PopupConfirmacionComponent } from '../../../base/components/popup-confirmacion/popup-confirmacion.component';
import * as XLSX from 'xlsx';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { Util } from 'src/app/utils/utils';
import { PdfService } from 'src/app/base/services/pdf/pdf.service';
import { ReporteCursoService } from '../../services/reporte-curso/reporte-curso.service';

declare const $ :any;
declare const aplicarTitulos:any;
declare const PDFDocument:any;
declare const blobStream:any;

@Component({
  selector: 'app-registro-calificacion',
  templateUrl: './registro-calificacion.component.html',
  styleUrls: ['./registro-calificacion.component.css']
})
export class RegistroCalificacionComponent extends BaseComponent implements OnInit {

  estudiantes:any[]=[];
  cabecera_planilla:any=[];
  valor_contador_dimension:number=0;
  valor_inicial_dimension:number=0;
  escuela:any;
  rangos_calificaciones_literal:any[]=[];
  descargar_excel:string="";
  cabecera_registro:any;
  tipo_calculo_calificacion:any;
  indice_intervalo_escolar_habilitado:any;
  es_usuario_administrador:boolean=false;
  estudiantes_intervalos:any[]=[];

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  descripcion_cabecera:NgbModalRef;
  @ViewChild('descripcion_cabecera')
  private descripcion_cabecera_ref: TemplateRef<any>;

  configuracion_planilla_modal:NgbModalRef;
  @ViewChild('configuracion_planilla_modal')
  private configuracion_planilla_modal_ref: TemplateRef<any>;

  constructor(public persistenciaService:PersistenciaService,
    public router:Router,
    public modalService: NgbModal,
    public generalService:GeneralService,
    private orderPipe: OrderPipe,
    public calificacionesService:CalificacionesService,
    public toastr: ToastrService,
    public cdr: ChangeDetectorRef,
	public usuarioService:UsuarioService,
	public pdfService:PdfService,
	public reporteCursoService:ReporteCursoService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

  ngOnInit(): void {
	this.obtenerListaRangosCalificacionesLiteral();
	this.obtenerTipoCalculoCalificacion();
	this.establecerHabilitacionModificacion();
  }

  ngAfterViewInit(){
	for(let i=0;i<this.cabecera_planilla.length;i++){
		aplicarTitulos(this.cabecera_planilla[i].id+"-c");
	}
  }

  ngAfterViewChecked() {
	this.cdr.detectChanges();
  }

  async establecerHabilitacionModificacion(){
	this.es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
	let nombre_corto_intervalo_escolar=this.filter.intervalo_escolar.nombre_corto;
	this.indice_intervalo_escolar_habilitado=nombre_corto_intervalo_escolar=="1"?"intervalo_escolar_1_habilitado":(nombre_corto_intervalo_escolar=="2"?"intervalo_escolar_2_habilitado":(nombre_corto_intervalo_escolar=="3"?"intervalo_escolar_3_habilitado":"intervalo_escolar_4_habilitado"));
	this.filter.planilla_habilitada=true;
	this.filter.guardado_inhabilitado=false;
	let configuraciones_calificacion:any=await this.calificacionesService.obtenerConfiguracionCalificacion(this.filter.gestion.id,this.usuario.id_empresa,0).toPromise();
	this.filter.registro_profesor.configuracion_total=this.filter.registro_profesor.configuracion_total?this.filter.registro_profesor.configuracion_total:(this.filter.registro_profesor.configuracion?this.filter.registro_profesor.configuracion:configuraciones_calificacion);
	this.filter.registro_profesor.configuracion=this.filter.registro_profesor.configuracion_total.filter(cc => cc.id_intervalo_escolar==this.filter.intervalo_escolar.id);
	for(let i=0;i<this.filter.registro_profesor.configuracion.length;i++){
		//this.filter.registro_profesor.configuracion[i]=configuraciones_calificacion.filter(cc => cc.id_dimension==this.filter.registro_profesor.configuracion[i].id_dimension && cc.id_intervalo_escolar==this.filter.intervalo_escolar.id);
		this.filter.registro_profesor.configuracion[i].valor_maximo=(this.filter.registro_profesor.tipo_calculo.nombre_corto==GlobalVariable.Dictionary.CALCULO_CALIFICACION_NOTA_CNP)?parseInt(this.filter.registro_profesor.configuracion[i].dimension.nombre_corto):100;
		let configuracion_padre=configuraciones_calificacion.filter(cp => cp.id==this.filter.registro_profesor.configuracion[i].id)[0];
		this.filter.registro_profesor.configuracion[i].configuracion.casilla_habilitada_padre=configuracion_padre.configuracion.casilla_habilitada;
		this.filter.planilla_habilitada=this.filter.planilla_habilitada && this.filter.registro_profesor.configuracion[i].configuracion.casilla_habilitada && this.filter.registro_profesor.configuracion[i].configuracion.casilla_habilitada_padre;
		this.filter.guardado_inhabilitado=this.filter.guardado_inhabilitado || (this.filter.registro_profesor.configuracion[i].configuracion.casilla_habilitada && this.filter.registro_profesor.configuracion[i].configuracion.casilla_habilitada_padre);
	}
	this.obtenerCalificacionesPromediosIntervalosEscolares();
  }

  obtenerTipoCalculoCalificacion(){
	  this.generalService.obtenerClases("SCHTCN").subscribe((entidad:any)=>{
		this.tipo_calculo_calificacion=entidad;
	  });
  }

  obtenerCantidadDimensionCalificaciones(dimension){
	  let calificaciones=this.estudiantes[0].inscripciones_colegio[0].calificaciones=this.estudiantes[0].inscripciones_colegio[0].calificaciones?this.estudiantes[0].inscripciones_colegio[0].calificaciones:[];
		let dimension_object=this.filter.registro_profesor.configuracion.filter(e => e.dimension.nombre ==dimension)[0];
		let calificaciones_encontradas=calificaciones.filter(e => e.id_dimension_calificacion && e.id_dimension_calificacion == dimension_object.dimension.id);
    calificaciones_encontradas=calificaciones_encontradas.slice(0,calificaciones_encontradas.length-2);
		return calificaciones_encontradas.length+2;
  }
  
  obtenerEdicionCalificacionesDimension(calificaciones,dimension,dimension_order){
		if(dimension_order==1){
			this.valor_contador_dimension=0;
		}
		var dimension_object=this.filter.registro_profesor.configuracion.filter(e => e.dimension.nombre ==dimension)[0];
    let calificaciones_encontradas=calificaciones.filter(e => (e.id_dimension_calificacion && e.id_dimension_calificacion == dimension_object.dimension.id));
		calificaciones_encontradas=this.orderPipe.transform(calificaciones_encontradas, 'orden',false);
		this.valor_inicial_dimension=this.valor_contador_dimension;
		this.valor_contador_dimension=this.valor_contador_dimension+calificaciones_encontradas.length;
		calificaciones_encontradas=calificaciones_encontradas.slice(0,calificaciones_encontradas.length-2);
		return calificaciones_encontradas;
  }
  
  verificarCalificacionReprobada(score,dimensionName){
		if(this.filter.registro_profesor.tipo_calculo.nombre_corto==GlobalVariable.Dictionary.CALCULO_CALIFICACION_NOTA_CNP)
		{
			var dimensionValue;
			for(var i=0;i<this.filter.registro_profesor.configuracion.length;i++){
				if(this.filter.registro_profesor.configuracion[i].dimension.nombre==dimensionName){
					dimensionValue=parseInt(this.filter.registro_profesor.configuracion[i].dimension.nombre_corto);
				}
			}
			return score<((dimensionValue/2)+1);
		}else{
			return score<51;
		}
  }
  
  calcularPromedioDimension(calificaciones,dimension){
    var dimension_object=this.filter.registro_profesor.configuracion.filter(e => e.dimension.nombre ==dimension)[0];
    let calificaciones_encontradas=calificaciones.filter(e => e.id_dimension_calificacion && e.id_dimension_calificacion == dimension_object.dimension.id);
    calificaciones_encontradas=this.orderPipe.transform(calificaciones_encontradas, 'orden',false);
		var sum=0,scoresCount=calificaciones_encontradas.length-2;
		
		for(var j=0;j<calificaciones_encontradas.length-2;j++){
			if(calificaciones_encontradas[j].valor==undefined){
				scoresCount--;
			}
			else{
				sum=sum+calificaciones_encontradas[j].valor;
			}
		}
		
		calificaciones_encontradas[calificaciones_encontradas.length-2].valor=Math.round(sum/scoresCount);
		var res=calificaciones_encontradas[calificaciones_encontradas.length-2].valor;

		if(res){
			return res;
		}		
		else{
			calificaciones_encontradas[calificaciones_encontradas.length-2].valor=0;
			return 0;
		}
  }
  
  calculateDimensionPercentageAverage(calificaciones,percentage,dimension){
		var dimension_object=this.filter.registro_profesor.configuracion.filter(e => e.dimension.nombre ==dimension)[0];
    let calificaciones_encontradas=calificaciones.filter(e => e.id_dimension_calificacion && e.id_dimension_calificacion == dimension_object.dimension.id);
		
		calificaciones_encontradas=this.orderPipe.transform(calificaciones_encontradas, 'orden',false);

		percentage=parseFloat(percentage)/100;
		if(this.filter.registro_profesor.tipo_calculo.nombre_corto==GlobalVariable.Dictionary.CALCULO_CALIFICACION_NOTA_CNP){
			percentage=1.0;
		}
		var sum=0,scoresCount=calificaciones_encontradas.length-2;
		
		for(var j=0;j<calificaciones_encontradas.length-2;j++){
			if(calificaciones_encontradas[j].valor==undefined){
				scoresCount--;
			}
			else{
				sum=sum+calificaciones_encontradas[j].valor;
			}
		}
		calificaciones_encontradas[calificaciones_encontradas.length-1].valor=Math.round(Math.round(Math.round(sum/scoresCount)*percentage*100)/100);
		var res=calificaciones_encontradas[calificaciones_encontradas.length-1].valor;	

		if(res){
			return res;
		}		
		else{
			calificaciones_encontradas[calificaciones_encontradas.length-1].valor=0;
			return 0;
		}
  }
  
  sumarCalificaciones(calificaciones,finalScore){
		var sum=0,has_score=true;
		finalScore.valor=0;
		for(var i=0;i<this.filter.registro_profesor.configuracion.length;i++){
			var dimension_scores=calificaciones.filter(e => (e.id_dimension_calificacion && e.id_dimension_calificacion == this.filter.registro_profesor.configuracion[i].dimension.id));
      dimension_scores=this.orderPipe.transform(dimension_scores, 'orden',false);
			has_score= has_score && dimension_scores[dimension_scores.length-1].valor!=null;
			finalScore.valor=finalScore.valor+(dimension_scores[dimension_scores.length-1].valor!=null?dimension_scores[dimension_scores.length-1].valor:0);
		}
		
		if(has_score)
		{
			return finalScore.valor;
		}
		else{
			return 0;
		}
  }
  
  obtenerIndiceFinalCalificacion(scores){
		return scores.length-1;
  }
  
  obtenerLiteralCalificacion(final_score){
		var literal="";
		var rango_encontrado=this.rangos_calificaciones_literal.filter(e => (final_score>=e.valor_inicial && final_score<=e.valor_final));
		if(rango_encontrado.length>0){
			literal=rango_encontrado[0].literal;
		}
		return literal;
  }
  
  obtenerListaRangosCalificacionesLiteral(){
    this.calificacionesService.obtenerListaRangosCalificacionesLiteral(this.filter.gestion.id,this.escuela.id).subscribe((rangos_calificaciones_literal:any)=>{
      this.rangos_calificaciones_literal=rangos_calificaciones_literal;
    });
  }
  
  validarValorCalificacion(score,max_value){
		if(!score.valor || score.valor>max_value){
			this.toastr.error("El valor de la calificación debe estar entre 1 y "+max_value);
			score.valor=null;
		}
  }
  
  calcularPorcentajePromedioDimension(calificaciones,percentage,dimension){
		var dimension_object=this.filter.registro_profesor.configuracion.filter(e => e.dimension.nombre ==dimension)[0];
    let calificaciones_encontradas=calificaciones.filter(e => e.id_dimension_calificacion && e.id_dimension_calificacion == dimension_object.dimension.id);
    calificaciones_encontradas=this.orderPipe.transform(calificaciones_encontradas, 'orden',false);

		percentage=parseFloat(percentage)/100;
		if(this.filter.registro_profesor.tipo_calculo.nombre_corto==GlobalVariable.Dictionary.CALCULO_CALIFICACION_NOTA_CNP){
			percentage=1.0;
		}
		var sum=0,scoresCount=calificaciones_encontradas.length-2;
		
		for(var j=0;j<calificaciones_encontradas.length-2;j++){
			if(calificaciones_encontradas[j].valor==undefined){
				scoresCount--;
			}
			else{
				sum=sum+calificaciones_encontradas[j].valor;
			}
		}
		calificaciones_encontradas[calificaciones_encontradas.length-1].valor=Math.round(Math.round(Math.round(sum/scoresCount)*percentage*100)/100);
		var res=calificaciones_encontradas[calificaciones_encontradas.length-1].valor;	

		if(res){
			return res;
		}		
		else{
			calificaciones_encontradas[calificaciones_encontradas.length-1].valor=0;
			return 0;
		}
  }
  
  enfocarCalificacion(keyEvent,fila,columna){
		if (keyEvent.which == '38') {
      // up arrow
      keyEvent.preventDefault();
			setTimeout(function() {
        $("#sc"+(fila-1)+"-"+(columna)).focus();
			},0);
		}
		else if (keyEvent.which == '40') {
      // down arrow
      keyEvent.preventDefault();
			setTimeout(function() {
        $("#sc"+(fila+1)+"-"+(columna)).focus();
			},0);
		}
		else if (keyEvent.which == '37') {
		   // left arrow
		   setTimeout(function() {
        var tipo_elemento = $("#sc"+(fila)+"-"+(columna-1)).prop('nodeName');
        let salto=tipo_elemento=="INPUT"?columna-1:columna-3;
			  $("#sc"+(fila)+"-"+(salto)).focus();
		  },0);
		}
		else if (keyEvent.which == '39') {
		   // right arrow
      setTimeout(function() {
        var tipo_elemento = $("#sc"+(fila)+"-"+(columna+1)).prop('nodeName');
        let salto=tipo_elemento=="INPUT"?columna+1:columna+3;
				$("#sc"+(fila)+"-"+(salto)).focus(); 
			},0);
		}
  }
  
  guardarCalificaciones(){
		this.blockUI.start();
		/*if($scope.score_is_general){
			for(var i=0;i<students.length;i++){
				for(var j=0;j<students[i].inscriptions[0].scores.length;j++){
					if(students[i].inscriptions[0].scores[j].value){
						var promise=GradeScoresGeneralUpdateData(students[i].inscriptions[0].id,students[i].inscriptions[0].scores[j].bimester_id,students[i].inscriptions[0].scores[j].score_type_id,students[i].inscriptions[0].scores[j].order,students[i].inscriptions[0].scores[j].value);
						promise.then(function(res){

						})
					}
				}
			}
			$scope.mostrarMensaje("Calificaciones asignadas a todas las materias satisfactoriamente!");
			$scope.cerrarPopup($scope.idPopupScoreForm);
			blockUI.stop();
    }else{*/
      this.calificacionesService.guardarPlanillaCalificacion({estudiantes:this.estudiantes,id_usuario:this.usuario.id}).subscribe((res:any)=>{
        this.toastr.success(res.mensaje);
        this.cerrarRegistroCalificaciones();
        this.blockUI.stop();
      });
		//}
  }
  
  cerrarRegistroCalificaciones(){
    this.alTerminar.emit({confirm:false});
  }

  limpiarCalificaciones(){
	this.blockUI.start();
	for(var i=0;i<this.estudiantes.length;i++){
		for(var j=0;j<this.estudiantes[i].inscripciones_colegio[0].calificaciones.length;j++){
			this.estudiantes[i].inscripciones_colegio[0].calificaciones[j].valor=null;
		}
	}
	this.blockUI.stop();
  }

  abrirPopupConfirmacionReinicializacion(){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de reinicializar la planilla de notas? Todas la información de esta planilla será borrada!";
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.reiniciarPlanillaCalificaciones();
			}
			this.popupConfirmacion.close();
        });
	}
  
  reiniciarPlanillaCalificaciones(){
    this.blockUI.start();
    this.calificacionesService.reiniciarPlanillaCalificacion({
      id_registro_profesor:this.filter.registro_profesor.id,
      estudiantes:this.estudiantes,
      id_materia:this.filter.registro_profesor.materia.id,
      id_intervalo_escolar:this.filter.intervalo_escolar.id
    }).subscribe((res:any)=>{
      this.toastr.info(res.mensaje);
			if(!res.tiene_error){
				this.cerrarRegistroCalificaciones();
			}
			this.blockUI.stop();
    });
  }

  	abrirPopupConfirmacionReinicializacionEstudiante(estudiante){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de reinicializar la planilla de notas del estudiante? Todas la información de este estudiante será borrada!";
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.reiniciarPlanillaCalificacionesEstudiante(estudiante);
			}
			this.popupConfirmacion.close();
		});
	}

  reiniciarPlanillaCalificacionesEstudiante(estudiante){
    this.blockUI.start();
    this.calificacionesService.reiniciarPlanillaCalificacion({
      id_registro_profesor:this.filter.registro_profesor.id,
      estudiantes:[estudiante],
      id_materia:this.filter.registro_profesor.materia.id,
      id_intervalo_escolar:this.filter.intervalo_escolar.id
    }).subscribe((res:any)=>{
      this.toastr.info(res.mensaje);
			if(!res.tiene_error){
				this.cerrarRegistroCalificaciones();
			}
			this.blockUI.stop();
    });
  }
  
  subirExcelCalificaciones(event){
    this.blockUI.start();
    let me=this;
	  var files = event.target.files;
	  var i,f;
	  for (i = 0, f = files[i]; i != files.length; ++i) {
		var reader = new FileReader();
		var name = f.name;
		reader.onload = function(e) {
		  var data = e.target.result;

		  var workbook = XLSX.read(data, {type: 'binary'});
			var first_sheet_name = workbook.SheetNames[0];
			var worksheet = workbook.Sheets[first_sheet_name];

			//lectura de las longitudes de las dimensiones
			var dimensionLetters=["CR","CS","CT","CU","CV","CW","CX","CY","CZ"];//solo para 9 dimensiones
			var dimensionRow=96;
			var dimensionCol=0;
			var dimensionsLength=[];
			do{
				dimensionsLength.push(parseInt(worksheet[dimensionLetters[dimensionCol]+dimensionRow].v));
				dimensionRow++;
				dimensionCol++;
			}while(worksheet[dimensionLetters[dimensionCol]+dimensionRow]!=undefined);
			console.log(dimensionsLength);
			//lectura de estudiante por estudiante tanto excel como el arreglo
			var row=10,indice_estudiantes=10;
			var letters=["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z","AA","AB","AC","AD","AE","AF","AG","AH","AI","AJ","AK","AL","AM","AN","AO","AP","AQ","AR","AS","AT"];
			let suma_casillas=(dimensionsLength.length*2)+1;
			for(let t=0;t<dimensionsLength.length;t++){
				suma_casillas=suma_casillas+dimensionsLength[t];
			}
			do {
				//recorremos lista de estudiantes en arreglo de notas
				let estudante_encontrado=me.estudiantes.filter(e => worksheet["B"+row].v.trim()===e.persona.nombre_completo.trim());
				if(estudante_encontrado.length>0){
					if(me.estudiantes.indexOf(estudante_encontrado[0])===(indice_estudiantes-10)){
						for(let k=2;k<suma_casillas+2;k++){
							var value=undefined;
							if(worksheet[letters[k]+row]!=undefined && worksheet[letters[k]+row].v!=""){
								try{
									value=Math.round(parseFloat(worksheet[letters[k]+row].v));
								}catch(err){
									console.log(err);
								}
							}
							me.estudiantes[indice_estudiantes-10].inscripciones_colegio[0].calificaciones[k-2].valor=value;
						}
					}else{
						row--;
					}
					indice_estudiantes++;
				}
				row++;//avanzamos uno en la lista excel
				console.log(row);
			} while (worksheet['A'+row]!=undefined);
			
			me.blockUI.stop();
		};
		reader.readAsBinaryString(f);
	  }
  }
  
  async descargarExcelCalificaciones(){
	var letters=["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z","AA","AB","AC","AD","AE","AF","AG","AH","AI","AJ","AK","AL","AM","AN","AO","AP","AQ","AR","AS","AT"];
    let workbook = new Workbook();
    let worksheet:any = workbook.addWorksheet("test");
    worksheet.addRow(['PLANILLA DE CALIFICACIONES']);
    worksheet.addRow([]);
	worksheet.addRow(['','','UNIDAD EDUCATIVA: ','','','','','',this.escuela.nombre.toUpperCase(),'','','','','','','','','ÁREA:','','','','','',this.filter.registro_profesor.materia.nombre]);
	worksheet.addRow(['','','GESTIÓN: ','','','','','',this.filter.gestion.nombre,'','','','','','','','','TRIMESTRE:','','','','','',this.filter.intervalo_escolar.nombre]);
	worksheet.addRow(['','','CURSO: ','','','','','',this.filter.registro_profesor.curso.nombre,'','','','','','','','','TIPO CÁLCULO:','','','','','',this.filter.registro_profesor.tipo_calculo.nombre]);
	worksheet.addRow([]);
    
	worksheet.mergeCells(letters[0]+"7:"+(letters[0]+"9"));
	worksheet.getCell(letters[0]+"7").value = "Nº";
	worksheet.getCell(letters[0]+"7").border = {top: {style:'thin'},left: {style:'thin'},bottom: {style:'thin'},right: {style:'thin'}};
	worksheet.getCell(letters[0]+"7").alignment = { vertical: 'middle', horizontal: 'center' };
	worksheet.mergeCells(letters[1]+"7:"+(letters[1]+"9"));
	worksheet.getCell(letters[1]+"8").value = "ESTUDIANTE";
	worksheet.getCell(letters[1]+"8").border = {top: {style:'thin'},left: {style:'thin'},bottom: {style:'thin'},right: {style:'thin'}};
	worksheet.getCell(letters[1]+"8").alignment = { vertical: 'middle', horizontal: 'center' };
	
	let acumulado=2,sumatoria_promedio=[],porcentajes=[],numero_casillas=[];
	for(let i=0;i<this.filter.registro_profesor.configuracion.length;i++){
		//let longitud_casillas=parseInt(this.filter.registro_profesor.configuracion[i].numero_casillas.toString());
		let longitud_casillas=this.obtenerCantidadDimensionCalificaciones(this.filter.registro_profesor.configuracion[i].dimension.nombre)-2;
		worksheet.mergeCells(letters[acumulado]+"8:"+(letters[acumulado+longitud_casillas+1]+"8"));
		worksheet.getCell(letters[acumulado]+"8").value = this.filter.registro_profesor.configuracion[i].dimension.nombre+" "+this.filter.registro_profesor.configuracion[i].dimension.nombre_corto+" %";
		worksheet.getCell(letters[acumulado]+"8").border = {top: {style:'thin'},left: {style:'thin'},bottom: {style:'thin'},right: {style:'thin'}};
		worksheet.getCell(letters[acumulado]+"8").alignment = { vertical: 'middle', horizontal: 'center' };
		let orden=this.filter.registro_profesor.configuracion[i].orden;
		let colorbg=(orden==1?"D2E6F6":orden==2?"E0E5E8":orden==3?"DAEEDA":orden==4?"F7D7D1":orden==5?"FBEACD":"DDDBED");
		worksheet.getCell(letters[acumulado]+"8").fill = { type: 'pattern',pattern:'darkTrellis',fgColor:{argb:colorbg},bgColor:{argb:colorbg} };
		sumatoria_promedio.push(acumulado+longitud_casillas+1);
		porcentajes.push(parseInt(this.filter.registro_profesor.configuracion[i].dimension.nombre_corto)/100);
		numero_casillas.push(longitud_casillas);
		acumulado=acumulado+longitud_casillas+2;
	}
	worksheet.getCell(letters[acumulado]+"8").value = "PROM. TRIM.";
	worksheet.mergeCells(letters[acumulado]+"8:"+(letters[acumulado]+"9"));
	worksheet.getCell(letters[acumulado]+"8").border = {top: {style:'thin'},left: {style:'thin'},bottom: {style:'thin'},right: {style:'thin'}};
	worksheet.getCell(letters[acumulado]+"8").alignment = { vertical: 'middle', horizontal: 'center',textRotation: 90 };
	acumulado=2;
	for(let i=0;i<this.cabecera_planilla.length;i++){
		worksheet.getCell(letters[acumulado]+"9").value = this.cabecera_planilla[i].descripcion;
		worksheet.getCell(letters[acumulado]+"9").border = {top: {style:'thin'},left: {style:'thin'},bottom: {style:'thin'},right: {style:'thin'}};
		worksheet.getCell(letters[acumulado]+"9").alignment = { vertical: 'middle', horizontal: 'center',textRotation: 90 };
		acumulado++;
	}

	worksheet.mergeCells(letters[0]+"1:"+(letters[acumulado]+"1"));
	worksheet.mergeCells(letters[0]+"2:"+(letters[1]+"6"));

	worksheet.mergeCells(letters[2]+"3:"+(letters[7]+"3"));
	worksheet.mergeCells(letters[2]+"4:"+(letters[7]+"4"));
	worksheet.mergeCells(letters[2]+"5:"+(letters[7]+"5"));

	worksheet.mergeCells(letters[8]+"3:"+(letters[16]+"3"));
	worksheet.mergeCells(letters[8]+"4:"+(letters[16]+"4"));
	worksheet.mergeCells(letters[8]+"5:"+(letters[16]+"5"));

	worksheet.mergeCells(letters[17]+"3:"+(letters[22]+"3"));
	worksheet.mergeCells(letters[17]+"4:"+(letters[22]+"4"));
	worksheet.mergeCells(letters[17]+"5:"+(letters[22]+"5"));

	worksheet.mergeCells(letters[23]+"3:"+(letters[30]+"3"));
	worksheet.mergeCells(letters[23]+"4:"+(letters[30]+"4"));
	worksheet.mergeCells(letters[23]+"5:"+(letters[30]+"5"));

	worksheet.getCell(letters[0]+"1").border = {top: {style:'thin'},left: {style:'thin'},bottom: {style:'thin'},right: {style:'thin'}};
	worksheet.getCell(letters[0]+"1").alignment = { vertical: 'middle', horizontal: 'center'};

	worksheet.mergeCells(letters[2]+"7:"+letters[acumulado]+"7");
	worksheet.getCell(letters[2]+"7").value = "PLANILLA DE CALIFICACIONES";
	worksheet.getCell(letters[2]+"7").border = {top: {style:'thin'},left: {style:'thin'},bottom: {style:'thin'},right: {style:'thin'}};
	worksheet.getCell(letters[2]+"7").alignment = { vertical: 'middle', horizontal: 'center'};

	//llenado de notas en las celdas
    let fila=10;
    for(let i=0;i<this.estudiantes.length;i++){
      let datos_estudiante=[i+1,this.estudiantes[i].persona.nombre_completo];
      for(let j=0;j<this.estudiantes[i].inscripciones_colegio[0].calificaciones.length;j++){
        datos_estudiante.push(this.estudiantes[i].inscripciones_colegio[0].calificaciones[j].valor);
      }
	  worksheet.addRow(datos_estudiante);

	  worksheet.getCell("A"+fila).border = {top: {style:'thin'},left: {style:'thin'},bottom: {style:'thin'},right: {style:'thin'}};
      worksheet.getCell("B"+fila).border = {top: {style:'thin'},left: {style:'thin'},bottom: {style:'thin'},right: {style:'thin'}};
      worksheet.getCell("A"+fila).protection = {locked: true,hidden: false};
      worksheet.getCell("B"+fila).protection = {locked: true,hidden: false};
      for(let j=2;j<this.estudiantes[i].inscripciones_colegio[0].calificaciones.length+2;j++){
        worksheet.getCell(letters[j]+""+fila).alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell(letters[j]+""+fila).border = {top: {style:'thin'},left: {style:'thin'},bottom: {style:'thin'},right: {style:'thin'}};
      }
	  fila++;
	}
	
	//recorrido de estudiantes
	let k=10;fila=10;
	for(let s=0;s<this.estudiantes.length;s++){
		//para colorear las celdas de las notas
		acumulado=2;
		for(let i=0;i<this.filter.registro_profesor.configuracion.length;i++){
			let orden=this.filter.registro_profesor.configuracion[i].orden;
			let colorbg=(orden==1?"D2E6F6":orden==2?"E0E5E8":orden==3?"DAEEDA":orden==4?"F7D7D1":orden==5?"FBEACD":"DDDBED");
			let longitud_casillas=this.obtenerCantidadDimensionCalificaciones(this.filter.registro_profesor.configuracion[i].dimension.nombre)-2;
			//let longitud_casillas=parseInt(this.filter.registro_profesor.configuracion[i].numero_casillas.toString());
			for(let j=acumulado;j<acumulado+longitud_casillas+2;j++){
				worksheet.getCell(letters[j]+""+fila).fill = { type: 'pattern',pattern:'darkTrellis',fgColor:{argb:colorbg},bgColor:{argb:colorbg} };
				let valor_maximo=this.filter.registro_profesor.tipo_calculo.nombre_corto==GlobalVariable.Dictionary.CALCULO_CALIFICACION_NOTA_CNP?parseInt(this.filter.registro_profesor.configuracion[i].dimension.nombre_corto):100;
				worksheet.getCell(letters[j]+""+fila).dataValidation  = { 
					type: 'whole',operator: 'between',
					allowBlank: false,
					showInputMessage: true,
					showErrorMessage: true,
					errorStyle: 'alert',
					errorTitle: 'Error de Validación',
					 error: '¡Debe ingresar una calificacion entre 1 y '+ valor_maximo+'!',
					formulae: [1, valor_maximo],
					promptTitle: 'Nota',
					prompt: '¡Debe ingresar una calificacion entre 1 y '+ valor_maximo+'!'
				};
			}
			acumulado=acumulado+longitud_casillas+2;
		}

		//para colocar las formulas
		let formula_sumatoria_promedio="";
		for(let u=0;u<sumatoria_promedio.length;u++){
			let porcentaje,formula_suma_promedio,formula_total_dimension;
			if(this.filter.registro_profesor.tipo_calculo.nombre_corto==GlobalVariable.Dictionary.CALCULO_CALIFICACION_NOTA_CNP){
				porcentaje=1;
			}else{
				porcentaje=porcentajes[u];
			}
			formula_suma_promedio="ROUND(SUMIF("+letters[sumatoria_promedio[u]-1-numero_casillas[u]]+""+fila+":"+letters[sumatoria_promedio[u]-2]+""+fila+",\"<>\")/COUNTIF("+letters[sumatoria_promedio[u]-1-numero_casillas[u]]+""+fila+":"+letters[sumatoria_promedio[u]-2]+""+fila+",\"<>\"),0)";
			formula_total_dimension="ROUND("+letters[sumatoria_promedio[u]-1]+""+fila+"*"+porcentaje+",0)";
			let valor_anterior_sum=(typeof(worksheet.getCell(letters[sumatoria_promedio[u]-1]+""+fila).value)==="number"?worksheet.getCell(letters[sumatoria_promedio[u]-1]+""+fila).value:0);
			let valor_anterior_prom=(typeof(worksheet.getCell(letters[sumatoria_promedio[u]]+""+fila).value)==="number"?worksheet.getCell(letters[sumatoria_promedio[u]]+""+fila).value:0);
			worksheet.getCell(letters[sumatoria_promedio[u]-1]+""+fila).value = { formula:formula_suma_promedio, result:valor_anterior_sum};
			worksheet.getCell(letters[sumatoria_promedio[u]]+""+fila).value = { formula:formula_total_dimension, result:valor_anterior_prom};
			if(u+1==sumatoria_promedio.length){
				formula_sumatoria_promedio=formula_sumatoria_promedio+letters[sumatoria_promedio[u]]+""+fila;
			}else{
				formula_sumatoria_promedio=formula_sumatoria_promedio+letters[sumatoria_promedio[u]]+""+fila+"+";
			}
		}
		worksheet.getCell(letters[this.estudiantes[s].inscripciones_colegio[0].calificaciones.length+1]+""+fila).value = { formula: formula_sumatoria_promedio, result:worksheet.getCell(letters[this.estudiantes[s].inscripciones_colegio[0].calificaciones.length+1]+""+fila).value };
		fila++;
	}

    worksheet.getColumn(1).width = 3;
    worksheet.getColumn(2).width = 35;
    for(let j=3;j<this.estudiantes[0].inscripciones_colegio[0].calificaciones.length+2;j++){
      worksheet.getColumn(j).width = 4;
    }

    let fila_datos=96,letras=['CR','CS','CT','CU','CV','CW','CX'],li=0;
    for(let i=0;i<this.filter.registro_profesor.configuracion.length;i++){
		let longitud_casillas=this.obtenerCantidadDimensionCalificaciones(this.filter.registro_profesor.configuracion[i].dimension.nombre)-2;
		//let longitud_casillas=parseInt(this.filter.registro_profesor.configuracion[i].numero_casillas.toString());
      worksheet.getCell(letras[li]+""+fila_datos).value = longitud_casillas;
      worksheet.getCell(letras[li]+""+(fila_datos+1)).value = this.filter.registro_profesor.configuracion[i].orden;
      li++;
      fila_datos++;
	}
	
	worksheet.views = [
		{state: 'frozen', xSplit: 2, ySplit: 9, topLeftCell: 'C10', activeCell: 'C10'}
	];
    
    
    //await worksheet.protect('1234567',{selectLockedCells:true});
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, this.filter.gestion.nombre+"-"+this.escuela.nombre+"-TRIMESTRE-"+this.filter.intervalo_escolar.nombre+"-"+this.filter.registro_profesor.curso.nombre+"-"+this.filter.registro_profesor.materia.nombre+".xlsx");
    })
  }

	modificarDescripcionCabecera(cabecera){
	  	this.cabecera_registro=cabecera;
		this.descripcion_cabecera=this.modalService.open(this.descripcion_cabecera_ref, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.descripcion_cabecera.result.then((result) => {
		this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
		this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	  }
	  
	  guardarCabeceraRegistro(){
		  this.blockUI.start();
		this.calificacionesService.guardarCabeceraCalificacion(this.cabecera_registro).subscribe((res:any)=>{
			this.descripcion_cabecera.close();
			aplicarTitulos(this.cabecera_registro.id+"-c");
			this.blockUI.stop();
			this.toastr.success(res.mensaje);
		})
	  }

	abrirConfiguracionPlanilla(){
		this.configuracion_planilla_modal=this.modalService.open(this.configuracion_planilla_modal_ref, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.configuracion_planilla_modal.result.then((result) => {
		this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
		this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
	  	});
	}

	guardarConfiguracionPlanilla(){
		this.blockUI.start();
		this.calificacionesService.guardarConfiguracionPlanilla({
			id:this.filter.registro_profesor.id,
			configuracion:this.filter.registro_profesor.configuracion_total,
			tipo_calculo:this.filter.registro_profesor.tipo_calculo
		}).subscribe((res:any)=>{
			this.configuracion_planilla_modal.close();
			this.cerrarRegistroCalificaciones();
			this.blockUI.stop();
			this.toastr.success(res.mensaje);
		});
	}

	async descargarPdfCalificaciones(){
		this.pdfService.descargarPdfCalificaciones(this.escuela,this.cabecera_planilla,this.filter,this.estudiantes);
	}

	async obtenerCalificacionesPromediosIntervalosEscolares(){
		this.blockUI.start();
	    this.estudiantes_intervalos=[];
		var primer_intervalo_escolar=this.filter.tipo_intervalo.clases.filter( i => i.nombre_corto =="1")[0];
		primer_intervalo_escolar.estudiantes=await this.reporteCursoService.obtenerBoletinCalificaciones(this.usuario.id_empresa,this.filter.gestion.id,this.filter.registro_profesor.id_curso,primer_intervalo_escolar.id,[this.filter.registro_profesor.id_materia],0).toPromise();
		this.estudiantes_intervalos.push(primer_intervalo_escolar);
		var segundo_intervalo_escolar=this.filter.tipo_intervalo.clases.filter( i => i.nombre_corto =="2")[0];
		segundo_intervalo_escolar.estudiantes=await this.reporteCursoService.obtenerBoletinCalificaciones(this.usuario.id_empresa,this.filter.gestion.id,this.filter.registro_profesor.id_curso,segundo_intervalo_escolar.id,[this.filter.registro_profesor.id_materia],0).toPromise();
		this.estudiantes_intervalos.push(segundo_intervalo_escolar);
		var tercer_intervalo_escolar=this.filter.tipo_intervalo.clases.filter( i => i.nombre_corto =="3")[0];
		tercer_intervalo_escolar.estudiantes=await this.reporteCursoService.obtenerBoletinCalificaciones(this.usuario.id_empresa,this.filter.gestion.id,this.filter.registro_profesor.id_curso,tercer_intervalo_escolar.id,[this.filter.registro_profesor.id_materia],0).toPromise();
		this.estudiantes_intervalos.push(tercer_intervalo_escolar);
		this.blockUI.stop();
	}

	calcularPromedioAnual(id_estudiante){
		let suma=0;
		for(let i=0;i<this.estudiantes_intervalos.length;i++){
			let estudiante=this.estudiantes_intervalos[i].estudiantes.filter(e => e.id==id_estudiante)[0];
			let calificacion_promedio_intervalo_escolar = estudiante.inscripciones_colegio[0].calificaciones.reduce(((prev, current) => (prev.orden > current.orden) ? prev : current),0);
			suma=suma+(calificacion_promedio_intervalo_escolar.valor?calificacion_promedio_intervalo_escolar.valor:0);
		}
		return Math.round(suma/this.estudiantes_intervalos.length);
	}

}
