<div class="modal-header">
    <div class="col-md-6 col-xs-6">
        <h4 class="widget-title">Configuración Aplicación</h4>
    </div>
    <div class="col-md-6 col-xs-6">
  <button class="btn btn-primary" type="button" (click)="guardarConfiguracionVista()">
            <i class="fa fa-save align-top bigger-110"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <fieldset class="text-110 bgc-success-l3 text-success-d4 px-3 py-15 radius-1">
        <legend >Tabla de Movimientos</legend>
        <div class="row">
            <div class="col-2">
                <label>Ordenar por</label>
            </div>
            <div class="col-4">
                <select  class="form-control" name="conf" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.columna">
                    <option [ngValue]="fieldKey" *ngFor="let fieldKey of configuracion_pagina.getConfKeys()" >{{configuracion_pagina.object.datos.visualizacion_columnas[fieldKey].value}}</option>
                </select>
            </div>
            <div class="col-2">
                <label>Dirección</label>
            </div>
            <div class="col-4">
                <select class="form-control" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.direccion">
                    <option value="asc">Ascendente</option>
                    <option value="desc">Descendente</option>
                </select>
            </div>
        </div>
    </fieldset>
    <fieldset class="text-110 bgc-primary-l3 text-primary-d4 px-3 py-15 radius-1">
        <legend >Registro de Movimiento</legend>
        <div class="row">
            <div class="col-4">
                <label>¿Busqueda Codigo de Barra Activado?</label>
            </div>
            <div class="col-2">
                <label>
                    <input name="busqueda_codigo_barra" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.busqueda_codigo_barra" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                </label>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-8">
                <label>Sucursal por defecto</label>
            </div>
            <div class="col-4">
                <select [compareWith]="compararObjectos" name="sucursal_defecto" class="form-control" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.sucursal_defecto" (change)="establecerSucursalDefecto(configuracion_pagina.object.datos.opciones_permisos.sucursal_defecto)">
                    <option [ngValue]="sucursal" *ngFor="let sucursal of sucursales">{{sucursal.nombre}}</option>
                </select>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-8">
                <label>Almacen por defecto</label>
            </div>
            <div class="col-4">
                <select [compareWith]="compararObjectos" name="almacen_defecto" class="form-control" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.almacen_defecto" (change)="establecerAlmacenDefecto(configuracion_pagina.object.datos.opciones_permisos.almacen_defecto)">
                    <option *ngIf="configuracion_pagina.object.datos.opciones_permisos.almacen_defecto==null" [ngValue]="null" selected>Ninguno</option>
                    <option [ngValue]="almacen" *ngFor="let almacen of almacenes">{{almacen.nombre}}</option>
                </select>
            </div>
        </div>
    </fieldset>
    <fieldset class="text-110 bgc-primary-l3 text-primary-d4 px-3 py-15 radius-1">
        <legend >Impresión</legend>
        <div class="row">
            <div class="col-3">
                <label>Tamaño papel</label>
            </div>
            <div class="col-3">
                <select  class="form-control" name="papel_salida" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.impresion.papel">
                <option [ngValue]="tamano_papel.nombre_corto" *ngFor="let tamano_papel of tamanos_papel" >{{tamano_papel.nombre}}</option>
                </select>
            </div>
        </div>
    </fieldset>
    <fieldset class="text-110 bgc-orange-l3 text-orange-d4 px-3 py-15 radius-1">
        <legend >Permisos</legend>
        <div class="row" *ngIf="es_usuario_administrador">
            <div class="col-3">
                <label>Movimientos Asignados</label>
            </div>
            <div class="col-3">
                <ngx-bootstrap-multiselect [options]="opciones_transacciones" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.transacciones_asignadas" (ngModelChange)="seleccionarTransaccion($event)"></ngx-bootstrap-multiselect>
            </div>
        </div>
    </fieldset>
</div>