import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { CampeonatosService } from '../../services/campeonatos/campeonatos.service';
import { RolesPartidosService } from '../../services/roles-partidos/roles-partidos.service';
import { Util } from 'src/app/utils/utils';
import { PdfService } from 'src/app/base/services/pdf/pdf.service';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { OrderPipe } from 'ngx-order-pipe';
import { VentaComponent } from 'src/app/base/components/venta/venta.component';
import { ProductosService } from 'src/app/snapquick/services/productos/productos.service';
import { RegistroJugadorComponent } from '../registro-jugador/registro-jugador.component';
import * as fs from 'file-saver';
import { Socket } from 'ngx-socket-io';
import { ConfiguracionAplicacionRolPartidosComponent } from 'src/app/base/components/configuracion-aplicacion-rol-partidos/configuracion-aplicacion-rol-partidos.component';

declare const resaltarPestañaMenu:any;
declare const PDFDocument:any;
declare const blobStream:any;
declare const qr:any
declare const Buffer;

@Component({
  selector: 'app-rol-partidos',
  templateUrl: './rol-partidos.component.html',
  styleUrls: ['./rol-partidos.component.css']
})
export class RolPartidosComponent extends BaseComponent implements OnInit {

  roles_partidos:any[]=[];
  partidos_arbitro:any[]=[];
  partido:any;
  campeonatos:any;
  equipos:any[]=[];
  lista_tarjetas:any[]=[];
  equipos_diferente:any[]=[];
  rol_partido:any;
  tipo_rol_partido:any;
  escenarios:any[]=[];
  arbitros:any[]=[];
  goleadores:any[]=[];
  tipo_categoria:any;
  tipo_fase:any={clases:[]};
  tipo_disciplina:any={clases:[]};
  marcadorEquipo1 = 0;
  marcadorEquipo2 = 0;
  tabla_posiciones:any={};
  rol_partido_arbitro:any={};

  modal_registro_rol_partido:NgbModalRef;
  @ViewChild('modal_registro_rol_partido')
  private modal_registro_rol_partido_ref: TemplateRef<any>;

  modal_tabla_posiciones:NgbModalRef;
  @ViewChild('modal_tabla_posiciones')
  private modal_tabla_posiciones_ref: TemplateRef<any>;

  modal_tabla_goleadores:NgbModalRef;
  @ViewChild('modal_tabla_goleadores')
  private modal_tabla_goleadores_ref: TemplateRef<any>;

  modal_rol_partidos_arbitros:NgbModalRef;
  @ViewChild('modal_rol_partidos_arbitros')
  private modal_rol_partidos_arbitros_ref: TemplateRef<any>;

  modal_resultado_partido:NgbModalRef;
  @ViewChild('modal_resultado_partido')
  private modal_resultado_partido_ref: TemplateRef<any>;

  modalVenta:NgbModalRef;
  modalJugador:NgbModalRef;

  aplicacion_ventas:any;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    public campeonatosService:CampeonatosService,
    public rolesPartidosService:RolesPartidosService,
    public pdfService:PdfService,
    public productosService:ProductosService,
    public orderPipe:OrderPipe,
    public socket:Socket){
      super(persistenciaService,modalService,generalService,usuarioService);
    }

  async ngOnInit(): Promise<void> {
    this.init();
		this.buscarAplicacion(this.router.url.substring(1));
    this.aplicacion_ventas= this.usuario.aplicacionesUsuario.filter(app_usuario => app_usuario.aplicacion.codigo==GlobalVariable.Dictionary.CODIGO_APP_VENTAS)[0];
		this.configuracion_pagina={};
		this.campeonatos=await this.campeonatosService.obtenerCampeonatosEmpresa(this.usuario.id_empresa).toPromise();
    this.filter={
			id_empresa:this.usuario.id_empresa,
      campeonato:GlobalVariable.Dictionary.SELECCION_TODOS,
      campeonatos:[GlobalVariable.Dictionary.SELECCION_TODOS].concat(this.campeonatos)
		}
    await this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_ROL_PARTIDOS);
    this.column = "updatedAt";
    this.direction = "desc";
		this.getSearch(this.text_search,null);
    this.obtenerTarjetas();
  }

  ngAfterViewInit() {
		resaltarPestañaMenu(this.router.url.substring(1)+"1");
  }

  getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
		this.rolesPartidosService.obtenerListaRolesPartidos(this).subscribe((dato:any) => {
			this.setPages(dato.paginas);
			this.roles_partidos=dato.roles_partidos;
			this.blockUI.stop();
		});
	}

  obtenerTarjetas(){
    this.blockUI.start('Recuperando... espere por favor!');
		this.rolesPartidosService.obtenerListaTarjetas().subscribe((lista_tarjetas:any) => {
			this.lista_tarjetas=lista_tarjetas;
			this.blockUI.stop();
		});
  }

  async crearNuevoRolPartido(){
    this.partido={
      w_o_equipo_1:{es_wo:false},
      w_o_equipo_2:{es_wo:false}
    };
    this.equipos=[];
    await this.obtenerRolesPartidosEmpresa();
    this.obtenerCategoriasEquiposEmpresa();
    this.obtenerFasesCampeonato();
    this.obtenerDisciplinasCampeonato();
    this.obtenerEscenarios();
    this.obtenerArbitros();
    let codigo_nuevo=await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_ROL_PARTIDO);
    this.rol_partido={
        id_empresa: this.usuario.id_empresa, id_usuario:this.usuario.id,
        codigo:codigo_nuevo,
        activo:true,
        partidos:[]
    }
    this.abrirRegistroRolPartido();
  }

  async modificarRolPartido(rol_partido){
    this.partido={
      escenario:null,
      w_o_equipo_1:{es_wo:false},
      w_o_equipo_2:{es_wo:false}
    };
    this.blockUI.start();
    await this.obtenerRolesPartidosEmpresa();
    this.obtenerCategoriasEquiposEmpresa();
    this.obtenerFasesCampeonato();
    this.obtenerDisciplinasCampeonato();
    this.rolesPartidosService.obtenerRolPartido(rol_partido.id).subscribe((res:any)=>{
      this.blockUI.stop();
      this.obtenerEscenarios();
      this.obtenerArbitros();
      this.rol_partido=res;
      this.rol_partido.partidos=this.rol_partido.partidos.filter(p => p.activo);
      this.abrirRegistroRolPartido();
      this.allItemsSelected=true;
      this.selectAllItems(this.rol_partido.partidos);
    });
  }

  abrirRegistroRolPartido(){
    this.modal_registro_rol_partido=this.modalService.open(this.modal_registro_rol_partido_ref, {windowClass:'rol-partido',ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.modal_registro_rol_partido.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  async obtenerRolesPartidosEmpresa(){
		this.tipo_rol_partido=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_ROLES_PARTIDOS).toPromise();
	}

  obtenerEquiposCampeonato(campeonato,categoria,disciplina){
    if(campeonato && categoria){
      this.blockUI.start('Recuperando... espere por favor!');
      this.campeonatosService.obtenerListaEquipos(campeonato.id,categoria?categoria.id:0,disciplina?disciplina.id:0).subscribe((equipos:any) => {
        this.equipos=equipos;
        this.blockUI.stop();
      });
    }
  }

  obtenerEscenarios(){
    this.blockUI.start('Recuperando... espere por favor!');
		this.rolesPartidosService.obtenerEscenarios(this.usuario.id_empresa).subscribe((escenarios:any) => {
			this.escenarios=escenarios;
			this.blockUI.stop();
		});
  }

  obtenerArbitros(){
    this.blockUI.start('Recuperando... espere por favor!');
		this.rolesPartidosService.obtenerArbitros(this.usuario.id_empresa).subscribe((arbitros:any) => {
			this.arbitros=arbitros;
			this.blockUI.stop();
		});
  }

  async agregarPartido(){
    if(await this.validarPartido(this.partido)){
      this.partido.fecha_hora=Util.convertirObjetoAfecha(this.partido.fecha_hora_texto);
      this.partido.fecha_hora.setHours(this.partido.hora.hour);
      this.partido.fecha_hora.setMinutes(this.partido.hora.minute);
      this.rol_partido.partidos.push(this.partido);
      this.partido={
        w_o_equipo_1:{es_wo:false},
      w_o_equipo_2:{es_wo:false}
      };
      this.equipos=[];
    }
  }

  async validarPartido(partido){
    let res=true;
    if(!partido.disciplina){
			this.toastr.error("Debe especificar la disciplina!");
			res=false;
		}
		if(!partido.equipo_1_c){
			this.toastr.error("Debe especificar el primer equipo!");
			res=false;
		}
    if(!partido.equipo_2_c){
			this.toastr.error("Debe especificar el segundo equipo!");
			res=false;
		}
		if(!partido.fecha_hora_texto || !partido.fecha_hora_texto.year || !partido.fecha_hora_texto.month || !partido.fecha_hora_texto.day){
      res=res && false;
      this.toastr.error("Debe especificar la fecha del partido!");
    }
    if(!partido.hora || (partido.hora.hour==null || partido.hora.hour==undefined) || (partido.hora.minute==null || partido.hora.minute==undefined)){
      res=res && false;
      this.toastr.error("Debe especificar la hora completa del partido!");
    }
    if(!partido.escenario){
			this.toastr.error("Debe especificar el escenario!");
			res=false;
		}
    if(!partido.arbitro){
			this.toastr.error("Debe especificar el arbitro!");
			res=false;
		}
    partido.equipo_1=partido.equipo_1_c;
    partido.equipo_2=partido.equipo_2_c;
    if(await this.validarDuplicidadPartido(partido)){
      res=false;
    }
		return res;
  }

  validarRolPartido(){
    let res=true;
		if(!this.rol_partido.campeonato){
			this.toastr.error("Debe especificar el campeonato!");
			res=false;
		}
    if(!this.rol_partido.rol){
			this.toastr.error("Debe especificar el rol!");
			res=false;
		}
    if(this.rol_partido.partidos.length==0){
			this.toastr.error("Debe agregar al menos un partido!");
			res=false;
		}
		return res;
  }

  guardarRolPartido(){
    if(this.validarRolPartido()){
      this.blockUI.start();
      if(this.rol_partido.id){
        this.rolesPartidosService.actualizarRolPartido(this.rol_partido).subscribe((res:any)=>{
          this.blockUI.stop();
          this.modal_registro_rol_partido.close();
          this.toastr.success(res.mensaje);
          this.getItems();
        });
      }else{
        this.rolesPartidosService.guardarRolPartido(this.rol_partido).subscribe((res:any)=>{
          this.actualizarUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_ROL_PARTIDO);
          this.blockUI.stop();
          this.modal_registro_rol_partido.close();
          this.toastr.success(res.mensaje);
          this.getItems();
        });
      }
    }
  }

  async generarPdfPlanillasPartidos(rol_partido_fuente){
    this.blockUI.start();
    let rol_partido;
    if(!rol_partido_fuente.partidos || rol_partido_fuente.partidos.length==0){
      rol_partido=await this.rolesPartidosService.obtenerRolPartido(rol_partido_fuente.id).toPromise();
    }else{
      rol_partido=Object.assign({} ,rol_partido_fuente);
    }
    if(rol_partido.partidos.filter(p => p.activo && p.selected).length>0){
      rol_partido.partidos=rol_partido.partidos.filter(p => p.activo && p.selected);
    }else{
      rol_partido.partidos=rol_partido.partidos.filter(p => p.activo);
    }

    let tamano_papel,numero_jugadores,distancia_equipos;
    if(this.configuracion_pagina.getOrden().impresion.papel_planilla_partido==GlobalVariable.Dictionary.PAPEL_CARTA){
      tamano_papel=GlobalVariable.Dictionary.PAPEL_CARTA_MEDIDA;
      numero_jugadores=13;
      distancia_equipos=283;
    }else if(this.configuracion_pagina.getOrden().impresion.papel_planilla_partido==GlobalVariable.Dictionary.PAPEL_OFICIO){
      tamano_papel=GlobalVariable.Dictionary.PAPEL_OFICIO_MEDIDA;
      numero_jugadores=18;
      distancia_equipos=360;
    }else{
      tamano_papel=GlobalVariable.Dictionary.PAPEL_CARTA_MEDIDA;
      numero_jugadores=13;
      distancia_equipos=283;
    }

    var doc = new PDFDocument({size: tamano_papel,margin:10});
    var stream = doc.pipe(blobStream());
    for(let i=0;i<rol_partido.partidos.length;i++){
      await this.generarPdfPlanillaPartido(rol_partido,rol_partido.partidos[i],doc,tamano_papel,numero_jugadores,distancia_equipos);
      if(i+1<rol_partido.partidos.length){
        doc.addPage({size:tamano_papel,margin:10});
      }
    }
    doc.end();
    this.blockUI.stop();
    stream.on('finish', function() {
      var fileURL = stream.toBlobURL('application/pdf');
      window.open(fileURL,'_blank','location=no');
    });
  }

  async generarPdfPlanillaPartido(rol_partido,partido,doc_f,tamano_papel,numero_jugadores,distancia_equipos){
      var doc = doc_f?doc_f:new PDFDocument({size: tamano_papel,margin:10});
      var stream = doc.pipe(blobStream());
      doc.roundedRect(50,20,540,(numero_jugadores==13?750:894),5).stroke();
      if (this.usuario.empresa.imagen.length > 100) { doc.image(this.usuario.empresa.imagen,60,25, { width: 50, height: 50,align:'left' }); }
      doc.font('Helvetica-Bold',20);
      doc.text(rol_partido.campeonato.nombre.toUpperCase(),0,30,{align:"center"});
      doc.font('Helvetica',10);
      doc.text(rol_partido.rol.nombre,0,50,{align:"center"});
      doc.text(partido.categoria.nombre+" - "+partido.disciplina.nombre,0,65,{align:"center"});
      let logo=await Util.convertirUrlToBase64Image(this.rest_server+rol_partido.campeonato.logo);
      doc.image(logo,530,25, { width: 50, height: 50,align:'right' });
      doc.rect(50,80,540,0).stroke();
      doc.font('Helvetica-Bold',10);
      doc.text("CANCHA:",60,82);
      doc.text("FECHA:",330,82);
      doc.text("HORA:",430,82);
      doc.font('Helvetica',10);
      doc.text(partido.escenario.nombre,110,82);
      let fecha_hora=new Date(partido.fecha_hora);
      doc.text((fecha_hora.getDate()+"/"+(fecha_hora.getMonth()+1)+"/"+fecha_hora.getFullYear()),370,82);
      doc.text(fecha_hora.getHours()+":"+fecha_hora.getMinutes(),465,82);

      if(partido.disciplina.nombre=="FÚTBOL DE SALÓN"){
        await this.generarPlanillaEquipo(doc,partido.equipo_1,100,145,numero_jugadores);
      }else if(partido.disciplina.nombre=="BALONCESTO"){
        doc.rect(50,92,540,0).stroke();
        await this.generarPlanillaEquipoBaloncesto(doc,partido.equipo_1,100,145,numero_jugadores);
      }

      if(partido.disciplina.nombre=="FÚTBOL DE SALÓN"){
        await this.generarPlanillaEquipo(doc,partido.equipo_2,100+distancia_equipos,100+distancia_equipos+45,numero_jugadores);
      }else if(partido.disciplina.nombre=="BALONCESTO"){
        doc.rect(50,100+distancia_equipos-8,282,0).stroke();//mitad hoja
        await this.generarPlanillaEquipoBaloncesto(doc,partido.equipo_2,100+distancia_equipos,100+distancia_equipos+45,numero_jugadores);
      }

      if(partido.disciplina.nombre=="BALONCESTO"){
        doc.font('Helvetica',7);
        let y_canastas=115,canastas=1,numero_canastas=37;
        for(let i=0;i<numero_canastas;i++){
          doc.text(canastas,347,y_canastas-3);
          doc.text(canastas,350,y_canastas+4);
          doc.rect(332,y_canastas-5,15,15).stroke();
          doc.rect(347,y_canastas-5,11,15).stroke();
          doc.rect(358,y_canastas-5,15,15).stroke();
          y_canastas=y_canastas+15;
          canastas=canastas+1;
        }
        y_canastas=115;
        for(let i=0;i<numero_canastas;i++){
          doc.text(canastas,388,y_canastas-3);
          doc.text(canastas,391,y_canastas+4);
          doc.rect(373,y_canastas-5,15,15).stroke();
          doc.rect(388,y_canastas-5,11,15).stroke();
          doc.rect(399,y_canastas-5,15,15).stroke();
          y_canastas=y_canastas+15;
          canastas=canastas+1;
        }
      }

      doc.font('Helvetica',10);
      doc.rect(50,(numero_jugadores==13?665:809),540,0).stroke();
      doc.text("OBSERVACIONES:",60,(numero_jugadores==13?668:812));
      doc.text("FIRMA DEL PLANILLERO                          FIRMA DEL JUEZ",0,(numero_jugadores==13?760:904),{align:"center"});
      
      if(!doc_f){
        doc.end();
        stream.on('finish', function() {
          var fileURL = stream.toBlobURL('application/pdf');
          window.open(fileURL,'_blank','location=no');
        });
      }
  }

  async generarPlanillaEquipo(doc,equipo,y_inicio,y_jugador,numero_jugadores){
    doc.font('Helvetica-Bold',10);
    doc.rect(50,y_inicio-8,540,0).stroke();//mitad hoja
    doc.text("EQUIPO:",60,y_inicio);
    let logo=await Util.convertirUrlToBase64Image(this.rest_server+equipo.logo);
    doc.image(logo,270,y_inicio-10, { width: 25, height: 25,align:'right' });
    doc.text("FALTAS ACUMULATIVAS",300,y_inicio-6,{width:'100'});
    doc.text("1º tiempo",385,y_inicio-6);
    doc.rect(383,y_inicio-8,177,12).stroke();
    doc.rect(435,y_inicio-8,0,24).stroke();
    doc.rect(460,y_inicio-8,0,24).stroke();
    doc.rect(485,y_inicio-8,0,24).stroke();
    doc.rect(510,y_inicio-8,0,24).stroke();
    doc.rect(535,y_inicio-8,0,24).stroke();
    doc.rect(560,y_inicio-8,0,24).stroke();
    doc.text("2º tiempo",385,y_inicio+6);
    doc.rect(383,y_inicio+4,177,12).stroke();
    doc.font('Helvetica',10);
    doc.text(equipo.nombre,110,y_inicio);

    doc.rect(50,y_inicio+16,540,0).stroke();
    doc.font('Helvetica-Bold',10);
    doc.text("Nº",55,y_inicio+30);
    doc.text("JUGADOR",70,y_inicio+30);
    doc.rect(298,y_inicio-8,0,48+(numero_jugadores*15)).stroke();
    doc.text("Nº",300,y_inicio+30);
    doc.rect(320,y_inicio+16,0,24+(numero_jugadores*15)).stroke();

    doc.font('Helvetica-Bold',8);
    doc.rotate(-90, { origin: [240,120] });
    doc.text("GOL", ((y_inicio==100)?220:(numero_jugadores==13?-62:-140)),225);
    doc.text("SIN TARJ.", ((y_inicio==100)?220:(numero_jugadores==13?-62:-140)),202,{width:25});
    doc.rotate(90 , { origin: [240,120] });
    doc.rect(341,y_inicio+16,0,24+(numero_jugadores*15)).stroke();

    doc.font('Helvetica-Bold',10);
    //doc.text("GOLES",322,y_inicio+30);
    doc.rect(362,y_inicio+16,0,24+(numero_jugadores*15)).stroke();
    doc.text("TARJETAS",361,y_inicio+18);
    doc.rect(363, y_inicio+26,25,14).fill("yellow").stroke();
    doc.rect(388, y_inicio+26,25,14).fill("red").stroke();
    doc.fillColor("black");
    doc.rect(388,y_inicio+40,0,(numero_jugadores*15)).stroke();
    doc.rect(414,y_inicio+16,0,24+(numero_jugadores*15)).stroke();
    doc.text("DERECHO CANCHA (Bs): .......",430,y_inicio+19);
    doc.text("MARCADOR",470,y_inicio+30);

    doc.font('Helvetica',10);
    doc.rect(50,y_inicio+40,540,0).stroke();
    let goles=1;
    equipo.jugadores=equipo.jugadores.filter(jug => jug.activo);
    equipo.jugadores=this.orderPipe.transform(equipo.jugadores, 'persona.nombre_completo',false);
    for(let i=0;i<equipo.jugadores.length || i<numero_jugadores;i++){
      doc.rect(50,y_jugador+10,365,0).stroke();
      doc.text(i+1,55,y_jugador);
      if(equipo.jugadores[i]){
        doc.text(equipo.jugadores[i].persona.nombre_completo.toUpperCase(),70,y_jugador);
      }

      let x_goles=414;

      if(i%2==0 && goles<21){
        doc.rect(x_goles,y_jugador-5,25,15).stroke();
        doc.rect(x_goles,y_jugador+10,25,15).stroke();
        doc.rect(x_goles+25,y_jugador-5,25,15).stroke();
        doc.rect(x_goles+25,y_jugador+10,25,15).stroke();
        doc.rect(x_goles+50,y_jugador-5,25,15).stroke();
        doc.rect(x_goles+50,y_jugador+10,25,15).stroke();
        doc.rect(x_goles+75,y_jugador-5,25,15).stroke();
        doc.rect(x_goles+75,y_jugador+10,25,15).stroke();
        doc.rect(x_goles+100,y_jugador-5,25,15).stroke();
        doc.rect(x_goles+100,y_jugador+10,25,15).stroke();
        doc.rect(x_goles+125,y_jugador-5,25,15).stroke();
        doc.rect(x_goles+125,y_jugador+10,25,15).stroke();
        doc.rect(x_goles+150,y_jugador-5,26,15).stroke();
        doc.rect(x_goles+150,y_jugador+10,26,15).stroke();
        //doc.rect(x_goles+175,y_jugador-5,25,15).stroke();


        doc.text(goles,425,y_jugador);
        doc.text(goles+1,450,y_jugador);
        doc.text(goles+2,475,y_jugador);
        doc.text(goles+3,500,y_jugador);
        doc.text(goles+4,525,y_jugador);
        doc.text(goles+5,550,y_jugador);
        doc.text(goles+6,575,y_jugador);
        goles=goles+7;

        doc.rect(x_goles+25,y_jugador-5,0,15).stroke();
        doc.rect(x_goles+75,y_jugador-5,0,15).stroke();
        doc.rect(x_goles+125,y_jugador-5,0,15).stroke();
      }

      y_jugador=y_jugador+15;
    }
    let detalles_ventas_pendientes:any=await this.rolesPartidosService.obtenerDetallesVentaPendientes(equipo.cliente.id).toPromise();
    let y_detalle_venta=y_inicio+150;
    doc.font('Helvetica-Bold',7);
    doc.text("PENDIENTES ECONOMICOS",417,y_detalle_venta-16);
    doc.font('Helvetica',7);
    let suma_saldo=0;
    for(let i=0;i<detalles_ventas_pendientes.length;i++){
      let fecha_venta=new Date(detalles_ventas_pendientes[i].venta.fecha);
      let texto_economico="Bs "+detalles_ventas_pendientes[i].venta.saldo.toFixed(2);
      let texto=(fecha_venta.getDate()+"/"+(fecha_venta.getMonth()+1)+"/"+fecha_venta.getFullYear())+" "+detalles_ventas_pendientes[i].producto.nombre+(detalles_ventas_pendientes[i].descripcion?" "+detalles_ventas_pendientes[i].descripcion:"");
      doc.fillColor("red");
      doc.font('Helvetica-Bold',7);
      doc.text(texto_economico,417,y_detalle_venta-5,{width:142});
      doc.fillColor("#000000");
      doc.font('Helvetica',7);
      doc.text(texto,450,y_detalle_venta-5,{width:142});
      doc.rect(415,y_detalle_venta-7,175,0).stroke();
      y_detalle_venta=y_detalle_venta+16;
      suma_saldo=suma_saldo+detalles_ventas_pendientes[i].venta.saldo;
    }
    doc.fillColor("red");
    doc.font('Helvetica-Bold',7);
    doc.text("Total: Bs "+suma_saldo.toFixed(2),530,y_inicio+134);
    doc.font('Helvetica',10);
    doc.fillColor("#000000");
    doc.text("DIRECTOR TÉCNICO:",60,y_jugador);
    doc.text("CAPITÁN:",330,y_jugador);
    doc.rect(410,y_jugador-5,180,0).stroke();
  }

  async generarPlanillaEquipoBaloncesto(doc,equipo,y_inicio,y_jugador,numero_jugadores){
    doc.font('Helvetica-Bold',10);
    doc.text("EQUIPO:",60,y_inicio);
    doc.font('Helvetica-Bold',6);
    let logo=await Util.convertirUrlToBase64Image(this.rest_server+equipo.logo);
    doc.image(logo,270,y_inicio-10, { width: 25, height: 25,align:'right' });
    if(y_inicio==100){
      doc.image(logo,335,y_inicio-6, { width: 10, height: 10,align:'right' });
      doc.image(logo,376,y_inicio-6, { width: 10, height: 10,align:'right' });
      doc.rotate(-90 , { origin: [240,120] });
      doc.text("FALTAS",220,204);
      doc.rotate(90, { origin: [240,120] });
    }else{
      doc.image(logo,361,94, { width: 10, height: 10,align:'right' });
      doc.image(logo,402,94, { width: 10, height: 10,align:'right' });
      doc.rotate(-90 , { origin: [240,120] });
      doc.text("FALTAS",(numero_jugadores==13?-65:-140),204);
      doc.rotate(90, { origin: [240,120] });
    }
    
    //doc.rect(383,y_inicio+4,177,12).stroke();
    doc.font('Helvetica',10);
    doc.text(equipo.nombre,110,y_inicio);

    doc.rect(50,y_inicio+16,282,0).stroke();
    doc.font('Helvetica-Bold',10);
    doc.text("Nº",55,y_inicio+30);
    doc.text("JUGADOR",70,y_inicio+30);
    doc.rect(298,y_inicio-8,0,48+(numero_jugadores*15)).stroke();
    doc.text("Nº",300,y_inicio+30);
    doc.rect(320,y_inicio+16,0,24+(numero_jugadores*15)).stroke();
    
    doc.fillColor("black");
    
    doc.rect(414,y_inicio+16,0,219).stroke();
    doc.text("DERECHO CANCHA (Bs): .......",430,y_inicio+19);
    
    doc.font('Helvetica',10);
    doc.text("Falta por equipo",430,y_inicio+70,{width:40});
    let x_f=480,y_f=y_jugador+5;
    for(let i=0;i<4;i++){
      doc.text(i+1,x_f-8,y_f+4);
      for(let j=0;j<4;j++){
        doc.rect(x_f,y_f,15,15).stroke();
        doc.text(j+1,x_f+4,y_f+4);
        x_f=x_f+15;
      } 
      x_f=480;
      y_f=y_f+15;
    }

    doc.rect(50,y_inicio+40,540,0).stroke();
    let goles=1;
    equipo.jugadores=equipo.jugadores.filter(jug => jug.activo);
    equipo.jugadores=this.orderPipe.transform(equipo.jugadores, 'persona.nombre_completo',false);
    for(let i=0;i<equipo.jugadores.length || i<numero_jugadores;i++){
      doc.rect(50,y_jugador+10,300,0).stroke();
      doc.text(i+1,55,y_jugador);
      if(equipo.jugadores[i]){
        doc.text(equipo.jugadores[i].persona.nombre_completo.toUpperCase(),70,y_jugador);
      }
      y_jugador=y_jugador+15;
    }
    let detalles_ventas_pendientes:any=await this.rolesPartidosService.obtenerDetallesVentaPendientes(equipo.cliente.id).toPromise();
    let y_detalle_venta=y_inicio+150;
    doc.font('Helvetica-Bold',7);
    doc.text("PENDIENTES ECONOMICOS",417,y_detalle_venta-16);
    doc.font('Helvetica',7);
    let suma_saldo=0;
    for(let i=0;i<detalles_ventas_pendientes.length;i++){
      let fecha_venta=new Date(detalles_ventas_pendientes[i].venta.fecha);
      let texto_economico="Bs "+detalles_ventas_pendientes[i].venta.saldo.toFixed(2);
      let texto=(fecha_venta.getDate()+"/"+(fecha_venta.getMonth()+1)+"/"+fecha_venta.getFullYear())+" "+detalles_ventas_pendientes[i].producto.nombre+(detalles_ventas_pendientes[i].descripcion?" "+detalles_ventas_pendientes[i].descripcion:"");
      doc.fillColor("red");
      doc.font('Helvetica-Bold',7);
      doc.text(texto_economico,417,y_detalle_venta-5,{width:142});
      doc.fillColor("#000000");
      doc.font('Helvetica',7);
      doc.text(texto,450,y_detalle_venta-5,{width:142});
      doc.rect(415,y_detalle_venta-7,175,0).stroke();
      y_detalle_venta=y_detalle_venta+16;
      suma_saldo=suma_saldo+detalles_ventas_pendientes[i].venta.saldo;
    }
    doc.fillColor("red");
    doc.font('Helvetica-Bold',7);
    doc.text("Total: Bs "+suma_saldo.toFixed(2),530,y_inicio+134);
    doc.font('Helvetica',10);
    doc.fillColor("#000000");
    doc.text("DIRECTOR TÉCNICO:",60,y_jugador);
    doc.text("CAPITÁN:",60,y_jugador+25);
    doc.rect(50,y_jugador-5,540,0).stroke();
  }

  async obtenerCategoriasEquiposEmpresa(){
		this.tipo_categoria=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_EQUIPO_CATEGORIAS).toPromise();
	}

  async obtenerFasesCampeonato(){
    this.tipo_fase=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_CAMPEONATO_FASES).toPromise();
  }

  async obtenerDisciplinasCampeonato(){
    this.tipo_disciplina=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_EQUIPO_DISCIPLINAS).toPromise();
  }

  async obtenerEquiposDiferente(equipo_origen){
	  this.equipos_diferente=this.equipos.filter(e => e.id!=equipo_origen.id);
    this.partido.equipo_2=null;
	}

  abrirPopupConfirmacionEliminacionAmarillaEq1(jugador){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de quitar el jugador?";

		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
        let indexJugador = this.partido.lista_amarillas_equipo_1.findIndex(jugadorLista => jugadorLista.id_persona === jugador.id_persona);
        this.partido.lista_amarillas_equipo_1.splice(indexJugador, 1);
			}
			this.popupConfirmacion.close();
    });
	}

  abrirPopupConfirmacionEliminacionAmarillaEq2(jugador){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de quitar el jugador?";

		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
        let indexJugador = this.partido.lista_amarillas_equipo_2.findIndex(jugadorLista => jugadorLista.id_persona === jugador.id_persona);
        this.partido.lista_amarillas_equipo_2.splice(indexJugador, 1);
			}
			this.popupConfirmacion.close();
    });
	}


  abrirPopupConfirmacionEliminacionRojaEq1(jugador){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de quitar el jugador?";

		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
        let indexJugador = this.partido.lista_rojas_equipo_1.findIndex(jugadorLista => jugadorLista.id_persona === jugador.id_persona);
        this.partido.lista_rojas_equipo_1.splice(indexJugador, 1);
			}
			this.popupConfirmacion.close();
    });
	}

  abrirPopupConfirmacionEliminacionRojaEq2(jugador){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de quitar el jugador?";

		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
        let indexJugador = this.partido.lista_rojas_equipo_2.findIndex(jugadorLista => jugadorLista.id_persona === jugador.id_persona);
        this.partido.lista_rojas_equipo_2.splice(indexJugador, 1);
			}
			this.popupConfirmacion.close();
    });
	}

  abrirPopupConfirmacionEliminacionMarcadorEq1(marcador_equipo_1){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de quitar el jugador?";

		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){

        this.marcadorEquipo1 = this.obtenerMarcadorInicialEquipo1() - marcador_equipo_1.marcador;
        let indexJugador = this.partido.lista_marcador_equipo_1.findIndex(jugadorLista => jugadorLista.jugador.id === marcador_equipo_1.jugador.id);
        this.partido.lista_marcador_equipo_1.splice(indexJugador, 1);
			}
			this.popupConfirmacion.close();
    });
	}

  abrirPopupConfirmacionEliminacionMarcadorEq2(marcador_equipo_2){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de quitar el jugador?";

		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){

        this.marcadorEquipo2 = this.obtenerMarcadorInicialEquipo2() - marcador_equipo_2.marcador;
        let indexJugador = this.partido.lista_marcador_equipo_2.findIndex(jugadorLista => jugadorLista.jugador.id === marcador_equipo_2.jugador.id);
        this.partido.lista_marcador_equipo_2.splice(indexJugador, 1);
			}
			this.popupConfirmacion.close();
    });
	}

  abrirPopupConfirmacionEliminacionPartido(partido){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de quitar el partido?";
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
        partido.eliminado=true;
			}
			this.popupConfirmacion.close();
    });
	}

  abrirPopupConfirmacionEliminacion(rol_partido){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de anular el rol de partidos?";
		this.popupConfirmacion.componentInstance.data = rol_partido;

		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarRolPartido($e.data);
			}
			this.popupConfirmacion.close();
        });
	  }

    eliminarRolPartido(rol_partido){
		this.blockUI.start();
		this.rolesPartidosService.eliminarRolPartido(rol_partido).subscribe((res:any) => {
			this.toastr.success(res.mensaje);
			this.getItems();
			this.blockUI.stop();
		});
	}

  async generarPdfRolPartidos(rol_partido_fuente,doc_f,con_resultados){
    if(rol_partido_fuente.campeonato.configuracion_pdf_rol_partido.usar_rol_version==1){
      await this.generarPdfRolPartidosV1(rol_partido_fuente,doc_f,con_resultados);
    }else if(rol_partido_fuente.campeonato.configuracion_pdf_rol_partido.usar_rol_version==2){
      await this.generarPdfRolPartidosV2(rol_partido_fuente,doc_f,con_resultados);
    }else{
      await this.generarPdfRolPartidosV3(rol_partido_fuente,doc_f,con_resultados);
    }
  }

  async generarPdfRolPartidosV1(rol_partido_fuente,doc_f,con_resultados){
    this.blockUI.start();
    let rol_partido;
    if(!rol_partido_fuente.partidos || rol_partido_fuente.partidos.length==0){
      rol_partido=await this.rolesPartidosService.obtenerRolPartido(rol_partido_fuente.id).toPromise();
    }else{
      rol_partido=Object.assign({} ,rol_partido_fuente);
    }
    if(rol_partido.partidos.filter(p => p.activo && p.selected).length>0){
      rol_partido.partidos=rol_partido.partidos.filter(p => p.activo && p.selected);
    }else{
      rol_partido.partidos=rol_partido.partidos.filter(p => p.activo);
    }
    this.blockUI.stop();
    let fechas = rol_partido.partidos.filter((partido, i, arr) => arr.findIndex(t => new Date(t.fecha_hora).toDateString() === new Date(partido.fecha_hora).toDateString()) === i);
    for(let i=0;i<fechas.length;i++){
      let indice=rol_partido.partidos.indexOf(fechas[i]);
      rol_partido.partidos.splice(indice,0,{fecha_hora:fechas[i].fecha_hora,escenario:fechas[i].escenario})
    }
    let doc = doc_f?doc_f:new PDFDocument({size:[612, Math.max(150+(rol_partido.campeonato.configuracion_pdf_rol_partido.distancia_partidos*rol_partido.partidos.length),468)],margin:10});
    let stream = doc.pipe(blobStream());
    let fondo=await Util.convertirUrlToBase64Image(this.rest_server+"img/fondo-rol-partido.png");
    await this.dibujarCabeceraRolPartido(doc,fondo,rol_partido,con_resultados);
    let y_partido=100,items=0,page=1,partidos_por_pagina=20;
    for(let i=0;i<rol_partido.partidos.length;i++){
      doc.switchToPage(page-1);
      items++;
      if(rol_partido.partidos[i].equipo_1){
        //doc.roundedRect(20,y_partido,GlobalVariable.Dictionary.PAPEL_CARTA_MEDIDA[0]-40,25,5).fill(rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta).stroke();
        let fecha_hora=new Date(rol_partido.partidos[i].fecha_hora);
        doc.fillColor(rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta);
        //doc.fillColor(rol_partido.campeonato.configuracion_tarjeta_jugador.enunciados_color);
        
        if(rol_partido.campeonato.configuracion_pdf_rol_partido.mostrar_disciplina){
          doc.text(fecha_hora.getHours()+":"+fecha_hora.getMinutes(),20,y_partido+3,{width:43,align:"left"});
          doc.font('Helvetica',8);
          if(rol_partido.partidos[i].disciplina.nombre=="BALONCESTO"){
            doc.roundedRect(11,y_partido+18,60,20,5).fill("#ed7213").stroke();
          }else{
            doc.roundedRect(11,y_partido+18,60,20,5).fill(rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta).stroke();
          }
          doc.fillColor(rol_partido.campeonato.configuracion_tarjeta_jugador.enunciados_color);
          doc.text(rol_partido.partidos[i].disciplina.nombre,13,y_partido+20,{width:55,height:30,align:"center"});
        }else{
          doc.text(fecha_hora.getHours()+":"+fecha_hora.getMinutes(),20,y_partido+7,{width:43,align:"left"});
        }
        
        doc.font('Helvetica-Bold',16);
        doc.fillColor("#000000");
        doc.text(rol_partido.partidos[i].equipo_1.nombre,60,y_partido+7,{width:231,align:"right"});
        if(con_resultados && rol_partido.partidos[i].marcador_equipo_1!=null){
          doc.fillColor("#ff0000");
          doc.text(rol_partido.partidos[i].marcador_equipo_1,291,y_partido+7,{width:20,align:"right"});
        }
        if(con_resultados && rol_partido.partidos[i].lista_amarillas_equipo_1!=null && rol_partido.partidos[i].lista_amarillas_equipo_1.length>0){
          doc.fillColor("#ffff00");
          doc.rect(88,y_partido,13,13).fill("#ffff00").stroke();
          doc.fillColor("#000000");
          doc.text(rol_partido.partidos[i].lista_amarillas_equipo_1.length,88,y_partido+1,{width:13,height:13,align:"center"});
        }
        if(con_resultados && rol_partido.partidos[i].lista_rojas_equipo_1!=null && rol_partido.partidos[i].lista_rojas_equipo_1.length>0){
          doc.fillColor("#ffff00");
          doc.rect(88,y_partido+15,13,13).fill("#ff0000").stroke();
          doc.fillColor("#000000");
          doc.text(rol_partido.partidos[i].lista_rojas_equipo_1.length,88,y_partido+16,{width:13,height:13,align:"center"});
        }
        let logo_eq_1=await Util.convertirUrlToBase64Image(this.rest_server+rol_partido.partidos[i].equipo_1.logo);
        doc.image(logo_eq_1,62,y_partido-rol_partido.campeonato.configuracion_pdf_rol_partido.logo_vertical, { width: rol_partido.campeonato.configuracion_pdf_rol_partido.logo_longitud, height: rol_partido.campeonato.configuracion_pdf_rol_partido.logo_longitud,align:'right' });
        doc.fillColor(rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta);
        if(rol_partido.campeonato.configuracion_pdf_rol_partido.mostrar_categoria){
          let color_categoria;
          if(!con_resultados){
            color_categoria=rol_partido.partidos[i].categoria.nombre_corto?rol_partido.partidos[i].categoria.nombre_corto:rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta;
          }else{
            if(rol_partido.partidos[i].w_o_equipo_1.es_wo || rol_partido.partidos[i].w_o_equipo_2.es_wo){
              color_categoria="#ed5f07";
            }else{
              color_categoria=rol_partido.partidos[i].categoria.nombre_corto?rol_partido.partidos[i].categoria.nombre_corto:rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta;
            }
          }
          doc.fillColor(color_categoria);
          doc.text("VS",311,y_partido,{width:30,align:"center"});          
          doc.fillColor(color_categoria);
          doc.roundedRect(311,y_partido+12,30,25,5).fill(color_categoria).stroke();
          doc.fillColor(rol_partido.campeonato.configuracion_tarjeta_jugador.enunciados_color);
          doc.font('Helvetica-Bold',10);
          let texto_categoria_1=rol_partido.partidos[i].categoria.nombre.split("-")[0];
          let texto_categoria_2=rol_partido.partidos[i].categoria.nombre.split("-")[1]!=undefined?rol_partido.partidos[i].categoria.nombre.split("-")[1]:"";
          doc.text(texto_categoria_1,311,y_partido+15,{width:30,align:"center"});
          doc.text(texto_categoria_2,311,y_partido+25,{width:30,align:"center"});
          doc.font('Helvetica-Bold',16);
        }else{
          let color_categoria;
          if(!con_resultados){
            color_categoria=rol_partido.partidos[i].categoria.nombre_corto?rol_partido.partidos[i].categoria.nombre_corto:rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta;
          }else{
            if(rol_partido.partidos[i].w_o_equipo_1.es_wo || rol_partido.partidos[i].w_o_equipo_2.es_wo){
              color_categoria="#ed5f07";
            }else{
              color_categoria=rol_partido.partidos[i].categoria.nombre_corto?rol_partido.partidos[i].categoria.nombre_corto:rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta;
            }
          }
          doc.fillColor(color_categoria);
          doc.text("VS",311,y_partido+7,{width:30,align:"center"});
        }
        doc.fillColor("#000000");
        doc.text(rol_partido.partidos[i].equipo_2.nombre,361,y_partido+7,{width:231,align:"left"});
        if(con_resultados && rol_partido.partidos[i].marcador_equipo_2!=null){
          doc.fillColor("#ff0000");
          doc.text(rol_partido.partidos[i].marcador_equipo_2,341,y_partido+7,{width:20,align:"left"});
        }
        if(con_resultados && rol_partido.partidos[i].lista_amarillas_equipo_2!=null && rol_partido.partidos[i].lista_amarillas_equipo_2.length>0){
          doc.fillColor("#ffff00");
          doc.rect(552,y_partido,13,13).fill("#ffff00").stroke();
          doc.fillColor("#000000");
          doc.text(rol_partido.partidos[i].lista_amarillas_equipo_2.length,552,y_partido+1,{width:13,height:13,align:"center"});
        }
        if(con_resultados && rol_partido.partidos[i].lista_rojas_equipo_2!=null && rol_partido.partidos[i].lista_rojas_equipo_2.length>0){
          doc.fillColor("#ffff00");
          doc.rect(552,y_partido+15,13,13).fill("#ff0000").stroke();
          doc.fillColor("#000000");
          doc.text(rol_partido.partidos[i].lista_rojas_equipo_2.length,552,y_partido+16,{width:13,height:13,align:"center"});
        }
        doc.fillColor("#000000");
        let logo_eq_2=await Util.convertirUrlToBase64Image(this.rest_server+rol_partido.partidos[i].equipo_2.logo);
        doc.image(logo_eq_2,565,y_partido-rol_partido.campeonato.configuracion_pdf_rol_partido.logo_vertical, { width: rol_partido.campeonato.configuracion_pdf_rol_partido.logo_longitud, height: rol_partido.campeonato.configuracion_pdf_rol_partido.logo_longitud,align:'right' });
      }else{
        doc.roundedRect(153,y_partido,300,30,5).fill(rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta).stroke();
        let fecha_hora=new Date(rol_partido.partidos[i].fecha_hora);
        doc.fillColor(rol_partido.campeonato.configuracion_tarjeta_jugador.enunciados_color);
        let y_fecha=y_partido+8;
        if(rol_partido.campeonato.configuracion_pdf_rol_partido.mostrar_escenario){
          y_fecha=y_partido+5;
          doc.font('Helvetica-Bold',10);
          doc.text(rol_partido.partidos[i].escenario.nombre,0,y_partido+20,{width:612,align:"center"});
          doc.font('Helvetica-Bold',16);
        }
        doc.text(fecha_hora.toLocaleDateString("es-ES", { weekday: 'long' }).toUpperCase()+", "+fecha_hora.getDate()+" "+fecha_hora.toLocaleString('es-ES', { month: 'long' }).toUpperCase()+" "+fecha_hora.getFullYear(),0,y_fecha,{width:612,align:"center"});
      }
      y_partido=y_partido+rol_partido.campeonato.configuracion_pdf_rol_partido.distancia_partidos;

      if(items==partidos_por_pagina){
        items=0;
        y_partido=100;
        doc.addPage({size:[612, Math.max(150+(rol_partido.campeonato.configuracion_pdf_rol_partido.distancia_partidos*rol_partido.partidos.length),468)],margin:10});
        page++;
        await this.dibujarCabeceraRolPartido(doc,fondo,rol_partido,con_resultados);
      }
    }

    if(!doc_f){
      doc.end();
      stream.on('finish', function(d) {
        var fileURL = stream.toBlobURL('application/pdf');
        window.open(fileURL,'_blank','location=no');
      });
    }
  }

  async generarPdfRolPartidosV2(rol_partido_fuente,doc_f,con_resultados){
    this.blockUI.start();
    let rol_partido;
    if(!rol_partido_fuente.partidos || rol_partido_fuente.partidos.length==0){
      rol_partido=await this.rolesPartidosService.obtenerRolPartido(rol_partido_fuente.id).toPromise();
    }else{
      rol_partido=Object.assign({} ,rol_partido_fuente);
    }
    if(rol_partido.partidos.filter(p => p.activo && p.selected).length>0){
      rol_partido.partidos=rol_partido.partidos.filter(p => p.activo && p.selected);
    }else{
      rol_partido.partidos=rol_partido.partidos.filter(p => p.activo);
    }
    
    rol_partido.campeonato.configuracion_pdf_rol_partido.distancia_partidos=90;
    let fechas = rol_partido.partidos.filter((partido, i, arr) => arr.findIndex(t => new Date(t.fecha_hora).toDateString() === new Date(partido.fecha_hora).toDateString()) === i);
    for(let i=0;i<fechas.length;i++){
      let indice=rol_partido.partidos.indexOf(fechas[i]);
      rol_partido.partidos.splice(indice,0,{fecha_hora:fechas[i].fecha_hora,escenario:fechas[i].escenario})
    }
    let doc = doc_f?doc_f:new PDFDocument({size:[612, Math.max(150+(rol_partido.campeonato.configuracion_pdf_rol_partido.distancia_partidos*rol_partido.partidos.length),468)],margin:10});
    let stream = doc.pipe(blobStream());
    let fondo=await Util.convertirUrlToBase64Image(this.rest_server+"img/fondo-rol-partido.png");
    await this.dibujarCabeceraRolPartido(doc,fondo,rol_partido,con_resultados);
    let y_partido=100,items=0,page=1,partidos_por_pagina=20;
    for(let i=0;i<rol_partido.partidos.length;i++){
      doc.switchToPage(page-1);
      items++;
      if(rol_partido.partidos[i].equipo_1){
        //doc.roundedRect(20,y_partido,GlobalVariable.Dictionary.PAPEL_CARTA_MEDIDA[0]-40,25,5).fill(rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta).stroke();
        let fecha_hora=new Date(rol_partido.partidos[i].fecha_hora);
        doc.fillColor(rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta);
        //doc.fillColor(rol_partido.campeonato.configuracion_tarjeta_jugador.enunciados_color);
        doc.font('Helvetica-Bold',25);
        if(!con_resultados){
          doc.text(fecha_hora.getHours()+":"+fecha_hora.getMinutes(),271,y_partido-7,{width:70,align:"center"});
        }

        doc.roundedRect(20,y_partido+35,572,20,5).fill(rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta).stroke();
        //doc.roundedRect(20,y_partido-20,572,50,5).fill("#dae5e5").fillOpacity(0.8).stroke();
        
        doc.font('Helvetica-Bold',16);
        doc.fillColor(rol_partido.campeonato.configuracion_tarjeta_jugador.enunciados_color);
        doc.text(rol_partido.partidos[i].equipo_1.nombre,20,y_partido+40,{width:266,align:"center"});
        if(con_resultados && rol_partido.partidos[i].marcador_equipo_1!=null){
          doc.circle(248, y_partido+7, 20)
          .lineWidth(3)
          .fillOpacity(0.8)
          .fillAndStroke(rol_partido.campeonato.configuracion_tarjeta_jugador.enunciados_color,rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta);
          doc.fillColor(rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta);
          doc.text(rol_partido.partidos[i].marcador_equipo_1,228,y_partido,{width:40,align:'center'});
        }
        if(con_resultados && rol_partido.partidos[i].lista_amarillas_equipo_1!=null && rol_partido.partidos[i].lista_amarillas_equipo_1.length>0){
          doc.fillColor("#ffff00");
          doc.rect(90,y_partido-15,13,13).fill("#ffff00").stroke();
          doc.fillColor("#000000");
          doc.text(rol_partido.partidos[i].lista_amarillas_equipo_1.length,90,y_partido-14,{width:13,height:13,align:"center"});
        }
        if(con_resultados && rol_partido.partidos[i].lista_rojas_equipo_1!=null && rol_partido.partidos[i].lista_rojas_equipo_1.length>0){
          doc.fillColor("#ffff00");
          doc.rect(90,y_partido,13,13).fill("#ff0000").stroke();
          doc.fillColor("#000000");
          doc.text(rol_partido.partidos[i].lista_rojas_equipo_1.length,90,y_partido+1,{width:13,height:13,align:"center"});
        }
        let logo_eq_1=await Util.convertirUrlToBase64Image(this.rest_server+rol_partido.partidos[i].equipo_1.logo);
        doc.image(logo_eq_1,118,y_partido-35, { width: 70, height: 70,align:'center' });
        doc.fillColor(rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta);

        let color_categoria;
        if(!con_resultados){
          color_categoria=rol_partido.partidos[i].categoria.nombre_corto?rol_partido.partidos[i].categoria.nombre_corto:rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta;
        }else{
          if(rol_partido.partidos[i].w_o_equipo_1.es_wo || rol_partido.partidos[i].w_o_equipo_2.es_wo){
            color_categoria="#ed5f07";
          }else{
            color_categoria=rol_partido.partidos[i].categoria.nombre_corto?rol_partido.partidos[i].categoria.nombre_corto:rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta;
          }
        }
        doc.fillColor(color_categoria);
        doc.text("VS",288,y_partido-20,{width:36,align:"center"});  

        if(rol_partido.campeonato.configuracion_pdf_rol_partido.mostrar_categoria){
          doc.fillColor(color_categoria);
          doc.roundedRect(276,y_partido+13,60,20,5).fill(color_categoria).stroke();
          doc.fillColor(rol_partido.campeonato.configuracion_tarjeta_jugador.enunciados_color);
          doc.font('Helvetica-Bold',10);
          doc.text(rol_partido.partidos[i].categoria.nombre,278,y_partido+18,{width:56,align:"center"});
          doc.font('Helvetica-Bold',16);
        }
        if(rol_partido.campeonato.configuracion_pdf_rol_partido.mostrar_disciplina){
          doc.font('Helvetica',11);
          if(rol_partido.partidos[i].disciplina.nombre=="BALONCESTO"){
            doc.roundedRect(266,y_partido+28,80,25,5).fill("#ed7213").stroke();
          }else{
            doc.roundedRect(266,y_partido+28,80,25,5).fill(rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta).stroke();
          }
          doc.fillColor(rol_partido.campeonato.configuracion_tarjeta_jugador.enunciados_color);
          doc.text(rol_partido.partidos[i].disciplina.nombre,268,y_partido+30,{width:76,align:"center"});
        }
        doc.font('Helvetica-Bold',16);
        doc.fillColor(rol_partido.campeonato.configuracion_tarjeta_jugador.enunciados_color);
        doc.text(rol_partido.partidos[i].equipo_2.nombre,326,y_partido+40,{width:266,align:"center"});
        if(con_resultados && rol_partido.partidos[i].marcador_equipo_2!=null){
          doc.circle(364, y_partido+7, 20)
          .lineWidth(3)
          .fillOpacity(0.8)
          .fillAndStroke(rol_partido.campeonato.configuracion_tarjeta_jugador.enunciados_color,rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta);
          doc.fillColor(rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta);
          doc.text(rol_partido.partidos[i].marcador_equipo_2,344,y_partido,{width:40,align:'center'});
        }
        if(con_resultados && rol_partido.partidos[i].lista_amarillas_equipo_2!=null && rol_partido.partidos[i].lista_amarillas_equipo_2.length>0){
          doc.fillColor("#ffff00");
          doc.rect(509,y_partido-15,13,13).fill("#ffff00").stroke();
          doc.fillColor("#000000");
          doc.text(rol_partido.partidos[i].lista_amarillas_equipo_2.length,509,y_partido-14,{width:13,height:13,align:"center"});
        }
        if(con_resultados && rol_partido.partidos[i].lista_rojas_equipo_2!=null && rol_partido.partidos[i].lista_rojas_equipo_2.length>0){
          doc.fillColor("#ffff00");
          doc.rect(509,y_partido,13,13).fill("#ff0000").stroke();
          doc.fillColor("#000000");
          doc.text(rol_partido.partidos[i].lista_rojas_equipo_2.length,509,y_partido+1,{width:13,height:13,align:"center"});
        }
        doc.fillColor("#000000");
        let logo_eq_2=await Util.convertirUrlToBase64Image(this.rest_server+rol_partido.partidos[i].equipo_2.logo);
        doc.image(logo_eq_2,424,y_partido-35, { width: 70, height: 70,align:'center' });
      }else{
        doc.roundedRect(153,y_partido,300,30,5).fill(rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta).stroke();
        let fecha_hora=new Date(rol_partido.partidos[i].fecha_hora);
        doc.fillColor(rol_partido.campeonato.configuracion_tarjeta_jugador.enunciados_color);
        let y_fecha=y_partido+8;
        if(rol_partido.campeonato.configuracion_pdf_rol_partido.mostrar_escenario && !con_resultados){
          y_fecha=y_partido+5;
          doc.font('Helvetica-Bold',10);
          doc.text(rol_partido.partidos[i].escenario.nombre,0,y_partido+20,{width:612,align:"center"});
          doc.font('Helvetica-Bold',16);
        }
        doc.text(fecha_hora.toLocaleDateString("es-ES", { weekday: 'long' }).toUpperCase()+", "+fecha_hora.getDate()+" "+fecha_hora.toLocaleString('es-ES', { month: 'long' }).toUpperCase()+" "+fecha_hora.getFullYear(),0,y_fecha,{width:612,align:"center"});
      }
      y_partido=y_partido+rol_partido.campeonato.configuracion_pdf_rol_partido.distancia_partidos;

      if(items==partidos_por_pagina){
        items=0;
        y_partido=100;
        doc.addPage({size:[612, Math.max(150+(rol_partido.campeonato.configuracion_pdf_rol_partido.distancia_partidos*rol_partido.partidos.length),468)],margin:10});
        page++;
        await this.dibujarCabeceraRolPartido(doc,fondo,rol_partido,con_resultados);
      }
    }

    if(!doc_f){
      doc.end();
      let me=this;
      stream.on('finish', function(d) {
        var reader = new FileReader();
        reader.readAsDataURL(stream.toBlob('application/pdf')); 
        reader.onloadend = async function() {
          var base64data = reader.result;                
          me.rolesPartidosService.convertirPdfImg({base64:base64data,nombre:"rol-partidos.png"}).subscribe(async (res:any)=>{
		        fs.saveAs(GlobalVariable.SERVER_URL+res.path, "rol-partidos.png");
            me.blockUI.stop();
          });
        }
        /*var fileURL = stream.toBlobURL('application/pdf');
        window.open(fileURL,'_blank','location=no');*/
      });
    }
  }

  async generarPdfRolPartidosV3(rol_partido_fuente,doc_f,con_resultados){
    this.blockUI.start();
    let rol_partido;
    if(!rol_partido_fuente.partidos || rol_partido_fuente.partidos.length==0){
      rol_partido=await this.rolesPartidosService.obtenerRolPartido(rol_partido_fuente.id).toPromise();
    }else{
      rol_partido=Object.assign({} ,rol_partido_fuente);
    }
    if(rol_partido.partidos.filter(p => p.activo && p.selected).length>0){
      rol_partido.partidos=rol_partido.partidos.filter(p => p.activo && p.selected);
    }else{
      rol_partido.partidos=rol_partido.partidos.filter(p => p.activo);
    }

    rol_partido.campeonato.configuracion_pdf_rol_partido.distancia_partidos=60;
    let fechas = rol_partido.partidos.filter((partido, i, arr) => arr.findIndex(t => new Date(t.fecha_hora).toDateString() === new Date(partido.fecha_hora).toDateString()) === i);
    for(let i=0;i<fechas.length;i++){
      let indice=rol_partido.partidos.indexOf(fechas[i]);
      rol_partido.partidos.splice(indice,0,{fecha_hora:fechas[i].fecha_hora,escenario:fechas[i].escenario})
    }
    let doc = doc_f?doc_f:new PDFDocument({size:[612, Math.max(150+(rol_partido.campeonato.configuracion_pdf_rol_partido.distancia_partidos*rol_partido.partidos.length),468)],margin:10});
    let stream = doc.pipe(blobStream());
    let fondo=await Util.convertirUrlToBase64Image(this.rest_server+"img/fondo-rol-partido.png");
    await this.dibujarCabeceraRolPartido(doc,fondo,rol_partido,con_resultados);
    let y_partido=80,items=0,page=1,partidos_por_pagina=20;
    for(let i=0;i<rol_partido.partidos.length;i++){
      doc.switchToPage(page-1);
      items++;
      if(rol_partido.partidos[i].equipo_1){

        doc.roundedRect(20,y_partido+25,256,30,10).fill(rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta).stroke();
        doc.roundedRect(336,y_partido+25,256,30,10).fill(rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta).stroke();
        //doc.roundedRect(20,y_partido-20,572,50,5).fill("#dae5e5").fillOpacity(0.8).stroke();
        
        doc.font('Helvetica-Bold',16);
        doc.fillColor(rol_partido.campeonato.configuracion_tarjeta_jugador.enunciados_color);
        doc.text(rol_partido.partidos[i].equipo_1.nombre,20,y_partido+35,{width:266,align:"center"});
        let logo_eq_1=await Util.convertirUrlToBase64Image(this.rest_server+rol_partido.partidos[i].equipo_1.logo);
        doc.image(logo_eq_1,10,y_partido+5, { width: 50, height: 50,align:'left' });
        doc.fillColor(rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta);

        let color_categoria;
        if(!con_resultados){
          color_categoria=rol_partido.partidos[i].categoria.nombre_corto?rol_partido.partidos[i].categoria.nombre_corto:rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta;
        }else{
          if(rol_partido.partidos[i].w_o_equipo_1.es_wo || rol_partido.partidos[i].w_o_equipo_2.es_wo){
            color_categoria="#ed5f07";
          }else{
            color_categoria=rol_partido.partidos[i].categoria.nombre_corto?rol_partido.partidos[i].categoria.nombre_corto:rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta;
          }
        }

        if(rol_partido.campeonato.configuracion_pdf_rol_partido.mostrar_disciplina){
          doc.font('Helvetica',11);
          if(rol_partido.partidos[i].disciplina.nombre=="BALONCESTO"){
            doc.roundedRect(266,y_partido+28,80,25,5).fill("#ed7213").stroke();
          }else{
            doc.roundedRect(246,y_partido+50,120,15,5).fill(rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta).stroke();
          }
          doc.fillColor(rol_partido.campeonato.configuracion_tarjeta_jugador.enunciados_color);
          doc.text(rol_partido.partidos[i].disciplina.nombre,0,y_partido+56,{width:612,align:"center"});
        }
        if(rol_partido.campeonato.configuracion_pdf_rol_partido.mostrar_categoria){
          doc.roundedRect(276,y_partido+33,60,22,5).fill(color_categoria).stroke();
          doc.fillColor(rol_partido.campeonato.configuracion_tarjeta_jugador.enunciados_color);
          doc.font('Helvetica-Bold',10);
          doc.text(rol_partido.partidos[i].categoria.nombre,0,y_partido+47,{width:612,align:"center"});
          doc.font('Helvetica-Bold',16);
        }
        doc.fillColor(rol_partido.campeonato.configuracion_tarjeta_jugador.enunciados_color);
        doc.text("VS",288,y_partido+34,{width:36,align:"center"});  
        doc.fillColor(rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta);
        let fecha_hora=new Date(rol_partido.partidos[i].fecha_hora);
        doc.font('Helvetica-Bold',25);
        if(!con_resultados){
          doc.text(fecha_hora.getHours()+":"+fecha_hora.getMinutes(),271,y_partido+13,{width:70,align:"center"});
        }
        doc.font('Helvetica-Bold',16);
        doc.fillColor(rol_partido.campeonato.configuracion_tarjeta_jugador.enunciados_color);
        doc.text(rol_partido.partidos[i].equipo_2.nombre,326,y_partido+35,{width:266,align:"center"});
        if(con_resultados && rol_partido.partidos[i].marcador_equipo_1!=null){
          doc.circle(256, y_partido+33, 20)
          .lineWidth(3)
          .fillOpacity(0.8)
          .fillAndStroke(rol_partido.campeonato.configuracion_tarjeta_jugador.enunciados_color,rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta);
          doc.fillColor(rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta);
          doc.text(rol_partido.partidos[i].marcador_equipo_1,236,y_partido+26,{width:40,align:'center'});
        }
        if(con_resultados && rol_partido.partidos[i].lista_amarillas_equipo_1!=null && rol_partido.partidos[i].lista_amarillas_equipo_1.length>0){
          doc.fillColor("#ffff00");
          doc.rect(90,y_partido+10,13,13).fill("#ffff00").stroke();
          doc.fillColor("#000000");
          doc.text(rol_partido.partidos[i].lista_amarillas_equipo_1.length,90,y_partido+11,{width:13,height:13,align:"center"});
        }
        if(con_resultados && rol_partido.partidos[i].lista_rojas_equipo_1!=null && rol_partido.partidos[i].lista_rojas_equipo_1.length>0){
          doc.fillColor("#ffff00");
          doc.rect(104,y_partido+10,13,13).fill("#ff0000").stroke();
          doc.fillColor("#000000");
          doc.text(rol_partido.partidos[i].lista_rojas_equipo_1.length,104,y_partido+11,{width:13,height:13,align:"center"});
        }
        if(con_resultados && rol_partido.partidos[i].marcador_equipo_2!=null){
          doc.circle(356, y_partido+33, 20)
          .lineWidth(3)
          .fillOpacity(0.8)
          .fillAndStroke(rol_partido.campeonato.configuracion_tarjeta_jugador.enunciados_color,rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta);
          doc.fillColor(rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta);
          doc.text(rol_partido.partidos[i].marcador_equipo_2,336,y_partido+26,{width:40,align:'center'});
        }
        if(con_resultados && rol_partido.partidos[i].lista_amarillas_equipo_2!=null && rol_partido.partidos[i].lista_amarillas_equipo_2.length>0){
          doc.fillColor("#ffff00");
          doc.rect(495,y_partido+10,13,13).fill("#ffff00").stroke();
          doc.fillColor("#000000");
          doc.text(rol_partido.partidos[i].lista_amarillas_equipo_2.length,495,y_partido+11,{width:13,height:13,align:"center"});
        }
        if(con_resultados && rol_partido.partidos[i].lista_rojas_equipo_2!=null && rol_partido.partidos[i].lista_rojas_equipo_2.length>0){
          doc.fillColor("#ffff00");
          doc.rect(509,y_partido+10,13,13).fill("#ff0000").stroke();
          doc.fillColor("#000000");
          doc.text(rol_partido.partidos[i].lista_rojas_equipo_2.length,509,y_partido+11,{width:13,height:13,align:"center"});
        }
        doc.fillColor("#000000");
        let logo_eq_2=await Util.convertirUrlToBase64Image(this.rest_server+rol_partido.partidos[i].equipo_2.logo);
        doc.image(logo_eq_2,550,y_partido+5, { width: 50, height: 50,align:'right' });
      }else{
        doc.roundedRect(153,y_partido+15,300,30,5).fill(rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta).stroke();
        let fecha_hora=new Date(rol_partido.partidos[i].fecha_hora);
        doc.fillColor(rol_partido.campeonato.configuracion_tarjeta_jugador.enunciados_color);
        let y_fecha=y_partido+23;
        if(rol_partido.campeonato.configuracion_pdf_rol_partido.mostrar_escenario && !con_resultados){
          y_fecha=y_partido+20;
          doc.font('Helvetica-Bold',10);
          doc.text(rol_partido.partidos[i].escenario.nombre,0,y_partido+35,{width:612,align:"center"});
          doc.font('Helvetica-Bold',16);
        }
        doc.text(fecha_hora.toLocaleDateString("es-ES", { weekday: 'long' }).toUpperCase()+", "+fecha_hora.getDate()+" "+fecha_hora.toLocaleString('es-ES', { month: 'long' }).toUpperCase()+" "+fecha_hora.getFullYear(),0,y_fecha,{width:612,align:"center"});
      }
      y_partido=y_partido+rol_partido.campeonato.configuracion_pdf_rol_partido.distancia_partidos;

      if(items==partidos_por_pagina){
        items=0;
        y_partido=100;
        doc.addPage({size:[612, Math.max(150+(rol_partido.campeonato.configuracion_pdf_rol_partido.distancia_partidos*rol_partido.partidos.length),468)],margin:10});
        page++;
        await this.dibujarCabeceraRolPartido(doc,fondo,rol_partido,con_resultados);
      }
    }

    if(!doc_f){
      doc.end();
      let me=this;
      stream.on('finish', async function(d) {
        var reader = new FileReader();
        reader.readAsDataURL(stream.toBlob('application/pdf')); 
        reader.onloadend = async function() {
          var base64data = reader.result;                
          me.rolesPartidosService.convertirPdfImg({base64:base64data,nombre:"rol-partidos.png"}).subscribe(async (res:any)=>{
		        fs.saveAs(GlobalVariable.SERVER_URL+res.path, "rol-partidos.png");
            me.blockUI.stop();
          });
        }
        /*var fileURL = stream.toBlobURL('application/pdf');
        window.open(fileURL,'_blank','location=no');*/
      });
    }
  }

  async dibujarCabeceraRolPartido(doc,fondo,rol_partido,con_resultados){
    doc.image(fondo,0,0, { width: GlobalVariable.Dictionary.PAPEL_OFICIO_MEDIDA[0], height: GlobalVariable.Dictionary.PAPEL_OFICIO_MEDIDA[1],align:'right' });
    doc.rect(0,0,GlobalVariable.Dictionary.PAPEL_OFICIO_MEDIDA[0],80).fill(rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta).stroke();
    let logo=await Util.convertirUrlToBase64Image(this.rest_server+rol_partido.campeonato.logo);
    console.log(rol_partido.campeonato.configuracion_pdf_rol_partido.campeonato_propio);
    if(rol_partido.campeonato.configuracion_pdf_rol_partido.campeonato_propio){
      if (this.usuario.empresa.imagen.length > 100) { doc.image(this.usuario.empresa.imagen,20,5, { width: 70, height: 70,align:'left' }); }
    }else{
      doc.image(logo,20,5, { width: 70, height: 70,align:'left' });
    }
    doc.fillColor(rol_partido.campeonato.configuracion_tarjeta_jugador.enunciados_color);
    doc.font('Times-Bold',30);
    doc.text(rol_partido.campeonato.nombre.toUpperCase(),0,20,{align:"center"});
    doc.font('Times-Bold',15);
    doc.text((con_resultados?"RESULTADOS ":"")+rol_partido.rol.nombre,0,50,{align:"center"});
    doc.image(logo,522,5, { width: 70, height: 70,align:'right' });
    doc.font('Helvetica-Bold',16);
  }

  abrirModalResultados(partido){
    this.partido=partido;
    this.modal_resultado_partido=this.modalService.open(this.modal_resultado_partido_ref, {windowClass:'resultado-rol-partido',ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static',scrollable:true});
    this.modal_resultado_partido.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  agregarJugadorAmarillaEq1(jugador){
    this.partido.lista_amarillas_equipo_1=this.partido.lista_amarillas_equipo_1 ?? [];
    if(jugador){
      let jugadorRepetido = this.partido.lista_amarillas_equipo_1.some(jugadorLista => jugadorLista.id === jugador.id);
    if(jugadorRepetido){
      this.toastr.error("El jugador seleccionado ya se encuentra en la lista");
    }else{
      this.partido.lista_amarillas_equipo_1.push(jugador);
    }
    }else{
      this.toastr.error("Debe seleccionar un jugador");
    }
  }

  agregarJugadorAmarillaEq2(jugador){
    this.partido.lista_amarillas_equipo_2=this.partido.lista_amarillas_equipo_2?this.partido.lista_amarillas_equipo_2:[];
    if(jugador){
      let jugadorRepetido = this.partido.lista_amarillas_equipo_2.some(jugadorLista => jugadorLista.id === jugador.id);
      if(jugadorRepetido){
        this.toastr.error("El jugador seleccionado ya se encuentra en la lista");
      }else{
        this.partido.lista_amarillas_equipo_2.push(jugador);
      }
    }else{
      this.toastr.error("Debe seleccionar un jugador");
    }
  }

  agregarJugadorRojaEq1(jugador){
    this.partido.lista_rojas_equipo_1=this.partido.lista_rojas_equipo_1?this.partido.lista_rojas_equipo_1:[];
    if(jugador){
      let jugadorRepetido = this.partido.lista_rojas_equipo_1.some(jugadorLista => jugadorLista.id === jugador.id);
    if(jugadorRepetido){
      this.toastr.error("El jugador seleccionado ya se encuentra en la lista");
    }else{
      this.partido.lista_rojas_equipo_1.push(jugador);
    }
    }else{
      this.toastr.error("Debe seleccionar un jugador");
    }
  }

  agregarJugadorRojaEq2(jugador){
    this.partido.lista_rojas_equipo_2=this.partido.lista_rojas_equipo_2?this.partido.lista_rojas_equipo_2:[];
    if(jugador){
      let jugadorRepetido = this.partido.lista_rojas_equipo_2.some(jugadorLista => jugadorLista.id === jugador.id);
      if(jugadorRepetido){
        this.toastr.error("El jugador seleccionado ya se encuentra en la lista");
      }else{
        this.partido.lista_rojas_equipo_2.push(jugador);
      }
    }else{
      this.toastr.error("Debe seleccionar un jugador");
    }
  }

  obtenerMarcadorInicialEquipo1(){
    let marcadorEquipoInicial = 0;
    for (let i = 0; this.partido.lista_marcador_equipo_1 && i < this.partido.lista_marcador_equipo_1.length; i++) {
      marcadorEquipoInicial += this.partido.lista_marcador_equipo_1[i].marcador;
    }
    return marcadorEquipoInicial;
  }
  obtenerMarcadorInicialEquipo2(){
    let marcadorEquipoInicial = 0;
    for (let i = 0; this.partido.lista_marcador_equipo_2 && i < this.partido.lista_marcador_equipo_2.length; i++) {
      marcadorEquipoInicial += this.partido.lista_marcador_equipo_2[i].marcador;
    }
    return marcadorEquipoInicial;
  }

  agregarJugadorMarcadorEq1(jugador, marcador){
    this.partido.lista_marcador_equipo_1 = this.partido.lista_marcador_equipo_1 ?? [];
    if(jugador && marcador){
      if(marcador > 0){
        let jugadorRepetido = this.partido.lista_marcador_equipo_1.some(jugadorLista => jugadorLista.jugador.id === jugador.id);
      if(jugadorRepetido){
        this.toastr.error("El jugador seleccionado ya se encuentra en la lista");
      }else{
        if (this.partido.lista_marcador_equipo_1.length !== 0 || this.partido.marcador_equipo_1 !== 0) {
          this.marcadorEquipo1 = this.obtenerMarcadorInicialEquipo1() + marcador;
          if(this.marcadorEquipo1 <= this.partido.marcador_equipo_1){
            this.marcadorEquipo1 += marcador;
            this.partido.lista_marcador_equipo_1.push({jugador:jugador, marcador:marcador});
          }else{
            this.toastr.error("El marcador no corresponde");
            this.marcadorEquipo1 = this.marcadorEquipo1 - marcador;
          }
        }else{
          this.toastr.error(`El equipo ${this.partido.equipo_1.nombre} no marco goles`);
        }
      }
      }else{
        this.toastr.error("No ingresar numeros negativos en los marcadores");
      }
    }else{
      this.toastr.error("Debe seleccionar un jugador y su marcador");
    }
  }

  agregarJugadorMarcadorEq2(jugador,marcador){
    this.partido.lista_marcador_equipo_2=this.partido.lista_marcador_equipo_2 ?? [];
    if(jugador && marcador){
      let jugadorRepetido = this.partido.lista_marcador_equipo_2.some(jugadorLista => jugadorLista.jugador.id === jugador.id);
      if(jugadorRepetido){
        this.toastr.error("El jugador seleccionado ya se encuentra en la lista");
      }else{
        if(this.partido.lista_marcador_equipo_2.length !== 0 || this.partido.marcador_equipo_2 !== 0){
          this.marcadorEquipo2 = this.obtenerMarcadorInicialEquipo2() + marcador;
          if(this.marcadorEquipo2 <= this.partido.marcador_equipo_2){
            this.marcadorEquipo2 += marcador;
            this.partido.lista_marcador_equipo_2.push({jugador:jugador,marcador:marcador});
          }else{
            this.toastr.error("El marcador no corresponde");
            this.marcadorEquipo2 = this.marcadorEquipo2 - marcador;
          }
        }else{
          this.toastr.error(`El equipo ${this.partido.equipo_2.nombre} no marco goles`);
        }

      }
    }else{
      this.toastr.error("Debe seleccionar un jugador y su marcador");
    }
  }

  guardarResultadoPartido(){
    if(this.validarResultadoPatido()){
      this.blockUI.start();
      this.rolesPartidosService.actualizarResultadoPartido(this.partido).subscribe((res:any)=>{
        this.toastr.success(res.mensaje);
        this.modal_resultado_partido.close();
        this.blockUI.stop();
      });
    }
  }

async generarVentaEquipo(partido,equipo_1,equipo_2){
		this.blockUI.start();
		let configuracion_aplicacion_ventas:any=await this.usuarioService.obtenerConfiguracionPaginaPorCodigo(this.usuario.id_empresa,GlobalVariable.Dictionary.CODIGO_APP_VENTAS,this.usuario.empresa.usar_permisos_avanzados_usuarios?this.usuario.id:0).toPromise();
		this.modalVenta = this.modalService.open(VentaComponent, {windowClass:'venta-popup', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
			this.modalVenta.componentInstance.venta.usuario = this.usuario;
			this.modalVenta.componentInstance.usuario = this.usuario;
			this.modalVenta.componentInstance.configuracion_pagina = configuracion_aplicacion_ventas;
			this.modalVenta.componentInstance.venta.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
			this.modalVenta.componentInstance.es_administrador=Util.esUsuarioAdministrador(this.usuario);
			this.modalVenta.componentInstance.venta.cliente=equipo_1?equipo_1.cliente:equipo_2.cliente;
			this.modalVenta.componentInstance.venta.sucursal=Util.obtenerSucursalesUsuario(this.usuario)[0];
			this.modalVenta.componentInstance.nombre_corto_canal_directo=this.global_variable.Dictionary.CANAL_VENTA_DIRECTA;
      this.modalVenta.componentInstance.venta.descontar_almacen=this.usuario.empresa.usar_inventario?true:false;
      this.modalVenta.componentInstance.venta.fecha=partido.fecha_hora;
      //this.modalVenta.componentInstance.nombre_corto_tipo_pago=GlobalVariable.Dictionary.TIPO_PAGO_CRE;
      this.modalVenta.componentInstance.venta.id_partido=partido.id;
      if(equipo_1){
        this.modalVenta.componentInstance.venta.id_equipo_1=equipo_1.id;
      }else{
        this.modalVenta.componentInstance.venta.id_equipo_2=equipo_2.id;
      }

      let detalles_venta=[];
      let productos:any=await this.productosService.obtenerProductosEmpresa(this.usuario.id_empresa).toPromise();
      let arbitraje=productos.filter(p => p.nombre=="ARBITRAJE");
      arbitraje=arbitraje.length>0?arbitraje[0]:null;
      if(arbitraje){
        detalles_venta.push({
          producto:arbitraje,
          precio_unitario:arbitraje.precio_unitario,
          importe:arbitraje.precio_unitario,
          total:arbitraje.precio_unitario,
          es_servicio:false,
          es_combo:false,
          item:GlobalVariable.Dictionary.ITEM_PRODUCTO,
          editar_precio:true,
          cantidad:1,descuento:0,tipo_descuento:false
        });
        this.modalVenta.componentInstance.venta.pagado=arbitraje.precio_unitario;
      }
      
			this.modalVenta.componentInstance.venta.detallesVenta=detalles_venta;
			this.modalVenta.componentInstance.inhabilitar_busqueda_item=false;
			this.modalVenta.componentInstance.inhabilitar_sucursal=true;
			this.modalVenta.componentInstance.inhabilitar_almacen=true;
			this.modalVenta.componentInstance.inhabilitar_descontar_almacen=true;
			this.blockUI.stop();
			this.modalVenta.componentInstance.alTerminar.subscribe((res) => {
				if(res){
					if(res.tiene_error){
						this.toastr.error(res.mensaje);
						this.toastr.error(res.stack.substring(0,500));
					}else{
            this.rolesPartidosService.obtenerRolPartido(this.rol_partido.id).subscribe((res:any)=>{
              this.blockUI.stop();
              this.rol_partido=res;
              this.rol_partido.partidos=this.rol_partido.partidos.filter(p => p.activo);
            });
						this.toastr.success(res.mensaje);
						this.modalVenta.close();
					}
				}else{
					this.modalVenta.close();
				}
				
			});
  }

validarResultadoPatido(){
    let res=true;
    if(!this.partido.w_o_equipo_1.es_wo && !this.partido.w_o_equipo_2.es_wo){
      if(this.partido.marcador_equipo_1 === null){
        this.toastr.error(`Debe ingresar un marcador valido al equipo ${this.partido.equipo_1.nombre}!`);
        res=false;
      }
      if(this.partido.marcador_equipo_2 === null){
        this.toastr.error(`Debe ingresar un marcador valido al equipo ${this.partido.equipo_2.nombre}!`);
        res=false;
      }
      if(this.partido.marcador_equipo_1 < 0 || this.partido.marcador_equipo_2 < 0){
        this.toastr.error("No ingresar numeros negativos en los marcadores");
        res=false;
      }
      if(this.partido.marcador_equipo_1 !== this.obtenerMarcadorInicialEquipo1()){
        this.toastr.error(`Los marcadores del equipo ${this.partido.equipo_1.nombre} no coinciden`);
        res=false;
      }
      if(this.partido.marcador_equipo_2 !== this.obtenerMarcadorInicialEquipo2()){
        this.toastr.error(`Los marcadores del equipo ${this.partido.equipo_2.nombre} no coinciden`);
        res=false;
      }
    }
    return res;
  }

  async generarVentaJugadorAmarilla(partido,equipo_1,equipo_2,jugador){
		this.blockUI.start();
		let configuracion_aplicacion_ventas:any=await this.usuarioService.obtenerConfiguracionPaginaPorCodigo(this.usuario.id_empresa,GlobalVariable.Dictionary.CODIGO_APP_VENTAS,this.usuario.empresa.usar_permisos_avanzados_usuarios?this.usuario.id:0).toPromise();
		this.modalVenta = this.modalService.open(VentaComponent, {windowClass:'venta-popup', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
			this.modalVenta.componentInstance.venta.usuario = this.usuario;
			this.modalVenta.componentInstance.usuario = this.usuario;
			this.modalVenta.componentInstance.configuracion_pagina = configuracion_aplicacion_ventas;
			this.modalVenta.componentInstance.venta.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
			this.modalVenta.componentInstance.es_administrador=Util.esUsuarioAdministrador(this.usuario);
			this.modalVenta.componentInstance.venta.cliente=equipo_1?equipo_1.cliente:equipo_2.cliente;
			this.modalVenta.componentInstance.venta.sucursal=Util.obtenerSucursalesUsuario(this.usuario)[0];
			this.modalVenta.componentInstance.nombre_corto_canal_directo=this.global_variable.Dictionary.CANAL_VENTA_DIRECTA;
      this.modalVenta.componentInstance.venta.descontar_almacen=this.usuario.empresa.usar_inventario?true:false;
      this.modalVenta.componentInstance.nombre_corto_tipo_pago=GlobalVariable.Dictionary.TIPO_PAGO_CRE;
      this.modalVenta.componentInstance.venta.id_partido=partido.id;
      this.modalVenta.componentInstance.venta.fecha=partido.fecha_hora;
      let detalles_venta=[];
      let productos:any=await this.productosService.obtenerProductosEmpresa(this.usuario.id_empresa).toPromise();

      let tarjeta_amarilla=productos.filter(p => p.nombre.includes("AMARILLA"));
      tarjeta_amarilla=tarjeta_amarilla.length>0?tarjeta_amarilla[0]:null;
      if(tarjeta_amarilla){
        this.modalVenta.componentInstance.venta.id_jugador=jugador.id;
        if(equipo_1){
          this.modalVenta.componentInstance.venta.lista_amarillas_equipo_1=partido.lista_amarillas_equipo_1;
        }else{
          this.modalVenta.componentInstance.venta.lista_amarillas_equipo_2=partido.lista_amarillas_equipo_2;
        }

        detalles_venta.push({
          producto:tarjeta_amarilla,
          descripcion:jugador.persona.nombre_completo,
          precio_unitario:tarjeta_amarilla.precio_unitario,
          importe:tarjeta_amarilla.precio_unitario,
          total:tarjeta_amarilla.precio_unitario,
          es_servicio:false,
          es_combo:false,
          item:GlobalVariable.Dictionary.ITEM_PRODUCTO,
          editar_precio:true,
          cantidad:1,descuento:0,tipo_descuento:false
        });
      }
      
			this.modalVenta.componentInstance.venta.detallesVenta=detalles_venta;
			this.modalVenta.componentInstance.inhabilitar_busqueda_item=false;
			this.modalVenta.componentInstance.inhabilitar_sucursal=true;
			this.modalVenta.componentInstance.inhabilitar_almacen=true;
			this.modalVenta.componentInstance.inhabilitar_descontar_almacen=true;
			this.blockUI.stop();
			this.modalVenta.componentInstance.alTerminar.subscribe((res) => {
				if(res){
					if(res.tiene_error){
						this.toastr.error(res.mensaje);
						this.toastr.error(res.stack.substring(0,500));
					}else{
            if(this.rol_partido){
              this.rolesPartidosService.obtenerRolPartido(this.rol_partido.id).subscribe((res:any)=>{
                this.blockUI.stop();
                this.rol_partido=res;
                this.partido=this.rol_partido.partidos.filter(par => par.id==this.partido.id)[0];
              });
            }
            this.obtenerTarjetas();
						this.toastr.success(res.mensaje);
						this.modalVenta.close();
					}
				}else{
					this.modalVenta.close();
				}
				
			});
  }

  async generarVentaJugadorRoja(partido,equipo_1,equipo_2,jugador){
		this.blockUI.start();
		let configuracion_aplicacion_ventas:any=await this.usuarioService.obtenerConfiguracionPaginaPorCodigo(this.usuario.id_empresa,GlobalVariable.Dictionary.CODIGO_APP_VENTAS,this.usuario.empresa.usar_permisos_avanzados_usuarios?this.usuario.id:0).toPromise();
		this.modalVenta = this.modalService.open(VentaComponent, {windowClass:'venta-popup', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
			this.modalVenta.componentInstance.venta.usuario = this.usuario;
			this.modalVenta.componentInstance.usuario = this.usuario;
			this.modalVenta.componentInstance.configuracion_pagina = configuracion_aplicacion_ventas;
			this.modalVenta.componentInstance.venta.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
			this.modalVenta.componentInstance.es_administrador=Util.esUsuarioAdministrador(this.usuario);
			this.modalVenta.componentInstance.venta.cliente=equipo_1?equipo_1.cliente:equipo_2.cliente;
			this.modalVenta.componentInstance.venta.sucursal=Util.obtenerSucursalesUsuario(this.usuario)[0];
			this.modalVenta.componentInstance.nombre_corto_canal_directo=this.global_variable.Dictionary.CANAL_VENTA_DIRECTA;
      this.modalVenta.componentInstance.venta.descontar_almacen=this.usuario.empresa.usar_inventario?true:false;
      this.modalVenta.componentInstance.nombre_corto_tipo_pago=GlobalVariable.Dictionary.TIPO_PAGO_CRE;
      this.modalVenta.componentInstance.venta.id_partido=partido.id;
      this.modalVenta.componentInstance.venta.fecha=partido.fecha_hora;
      let detalles_venta=[];
      let productos:any=await this.productosService.obtenerProductosEmpresa(this.usuario.id_empresa).toPromise();

      let tarjeta_roja=productos.filter(p => p.nombre.includes("ROJA"));
      tarjeta_roja=tarjeta_roja.length>0?tarjeta_roja[0]:null;
      if(tarjeta_roja){
        this.modalVenta.componentInstance.venta.id_jugador=jugador.id;
        if(equipo_1){
          this.modalVenta.componentInstance.venta.lista_rojas_equipo_1=partido.lista_rojas_equipo_1;
        }else{
          this.modalVenta.componentInstance.venta.lista_rojas_equipo_2=partido.lista_rojas_equipo_2;
        }
        detalles_venta.push({
          producto:tarjeta_roja,
          descripcion:jugador.persona.nombre_completo,
          precio_unitario:tarjeta_roja.precio_unitario,
          importe:tarjeta_roja.precio_unitario,
          total:tarjeta_roja.precio_unitario,
          es_servicio:false,
          es_combo:false,
          item:GlobalVariable.Dictionary.ITEM_PRODUCTO,
          editar_precio:true,
          cantidad:1,descuento:0,tipo_descuento:false
        });
      }
      
			this.modalVenta.componentInstance.venta.detallesVenta=detalles_venta;
			this.modalVenta.componentInstance.inhabilitar_busqueda_item=false;
			this.modalVenta.componentInstance.inhabilitar_sucursal=true;
			this.modalVenta.componentInstance.inhabilitar_almacen=true;
			this.modalVenta.componentInstance.inhabilitar_descontar_almacen=true;
			this.blockUI.stop();
			this.modalVenta.componentInstance.alTerminar.subscribe((res) => {
				if(res){
					if(res.tiene_error){
						this.toastr.error(res.mensaje);
						this.toastr.error(res.stack.substring(0,500));
					}else{
            if(this.rol_partido){
              this.rolesPartidosService.obtenerRolPartido(this.rol_partido.id).subscribe((res:any)=>{
                this.blockUI.stop();
                this.rol_partido=res;
                this.partido=this.rol_partido.partidos.filter(par => par.id==this.partido.id)[0];
              });
            }
            this.obtenerTarjetas();
						this.toastr.success(res.mensaje);
						this.modalVenta.close();
					}
				}else{
					this.modalVenta.close();
				}
				
			});
  }

  establecerWOEquipo1(){
    if(this.partido.w_o_equipo_1.es_wo){
      this.partido.w_o_equipo_2.es_wo=!this.partido.w_o_equipo_1.es_wo;
      if(this.partido.disciplina.nombre=="BALONCESTO"){
        this.partido.marcador_equipo_1=20;
        this.partido.marcador_equipo_2=0;
      }else{
        this.partido.marcador_equipo_1=3;
        this.partido.marcador_equipo_2=0;
      }
    }
  }

  establecerWOEquipo2(){
    if(this.partido.w_o_equipo_2.es_wo){
      this.partido.w_o_equipo_1.es_wo=!this.partido.w_o_equipo_2.es_wo;
      if(this.partido.disciplina.nombre=="BALONCESTO"){
        this.partido.marcador_equipo_1=0;
        this.partido.marcador_equipo_2=20;
      }else{
        this.partido.marcador_equipo_1=0;
        this.partido.marcador_equipo_2=3;
      }
    }
  }

  async generarVentaWO(partido,equipo_1,equipo_2,equipo_que_dio){
		this.blockUI.start();
		let configuracion_aplicacion_ventas:any=await this.usuarioService.obtenerConfiguracionPaginaPorCodigo(this.usuario.id_empresa,GlobalVariable.Dictionary.CODIGO_APP_VENTAS,this.usuario.empresa.usar_permisos_avanzados_usuarios?this.usuario.id:0).toPromise();
		this.modalVenta = this.modalService.open(VentaComponent, {windowClass:'venta-popup', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
			this.modalVenta.componentInstance.venta.usuario = this.usuario;
			this.modalVenta.componentInstance.usuario = this.usuario;
			this.modalVenta.componentInstance.configuracion_pagina = configuracion_aplicacion_ventas;
			this.modalVenta.componentInstance.venta.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
			this.modalVenta.componentInstance.es_administrador=Util.esUsuarioAdministrador(this.usuario);
			this.modalVenta.componentInstance.venta.cliente=equipo_1?equipo_1.cliente:equipo_2.cliente;
			this.modalVenta.componentInstance.venta.sucursal=Util.obtenerSucursalesUsuario(this.usuario)[0];
			this.modalVenta.componentInstance.nombre_corto_canal_directo=this.global_variable.Dictionary.CANAL_VENTA_DIRECTA;
      this.modalVenta.componentInstance.venta.descontar_almacen=this.usuario.empresa.usar_inventario?true:false;
      this.modalVenta.componentInstance.nombre_corto_tipo_pago=GlobalVariable.Dictionary.TIPO_PAGO_CRE;
      this.modalVenta.componentInstance.venta.id_partido=partido.id;
      this.modalVenta.componentInstance.venta.fecha=partido.fecha_hora;
      let detalles_venta=[];
      let productos:any=await this.productosService.obtenerProductosEmpresa(this.usuario.id_empresa).toPromise();

      let w_o=productos.filter(p => p.nombre.includes("W.O."));
      w_o=w_o.length>0?w_o[0]:null;
      if(w_o){
        if(equipo_1){
          this.modalVenta.componentInstance.venta.w_o_equipo_1=partido.w_o_equipo_1;
        }else{
          this.modalVenta.componentInstance.venta.w_o_equipo_2=partido.w_o_equipo_2;
        }
        detalles_venta.push({
          producto:w_o,
          descripcion:"Partido contra "+equipo_que_dio.nombre,
          precio_unitario:w_o.precio_unitario,
          importe:w_o.precio_unitario,
          total:w_o.precio_unitario,
          es_servicio:false,
          es_combo:false,
          item:GlobalVariable.Dictionary.ITEM_PRODUCTO,
          editar_precio:true,
          cantidad:1,descuento:0,tipo_descuento:false
        });
      }
      
			this.modalVenta.componentInstance.venta.detallesVenta=detalles_venta;
			this.modalVenta.componentInstance.inhabilitar_busqueda_item=false;
			this.modalVenta.componentInstance.inhabilitar_sucursal=true;
			this.modalVenta.componentInstance.inhabilitar_almacen=true;
			this.modalVenta.componentInstance.inhabilitar_descontar_almacen=true;
			this.blockUI.stop();
			this.modalVenta.componentInstance.alTerminar.subscribe((res) => {
				if(res){
					if(res.tiene_error){
						this.toastr.error(res.mensaje);
						this.toastr.error(res.stack.substring(0,500));
					}else{
            this.rolesPartidosService.obtenerRolPartido(this.rol_partido.id).subscribe((res:any)=>{
              this.blockUI.stop();
              this.rol_partido=res;
              this.partido=this.rol_partido.partidos.filter(par => par.id==this.partido.id)[0];
            });
						this.toastr.success(res.mensaje);
						this.modalVenta.close();
					}
				}else{
					this.modalVenta.close();
				}
				
			});
  }

  abrirModalTablaPosiciones(){
    this.tabla_posiciones={};
    this.obtenerCategoriasEquiposEmpresa();
    this.obtenerFasesCampeonato();
    this.obtenerDisciplinasCampeonato();
    this.modal_tabla_posiciones=this.modalService.open(this.modal_tabla_posiciones_ref, {windowClass:'tabla-posiciones',ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static',scrollable:true});
    this.modal_tabla_posiciones.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  abrirModalRolPartidosArbitro(){
    let fecha_actual=new Date();
    this.rol_partido_arbitro={
      campeonato:GlobalVariable.Dictionary.SELECCION_TODOS,
      fecha_inicio:{
				year: fecha_actual.getFullYear(), 
				month: (fecha_actual.getMonth()+1),
				day:fecha_actual.getDate()
			},
			fecha_fin:{
				year: fecha_actual.getFullYear(), 
				month: (fecha_actual.getMonth()+1),
				day:fecha_actual.getDate()
			}
    };
    this.obtenerArbitros();
    this.modal_rol_partidos_arbitros=this.modalService.open(this.modal_rol_partidos_arbitros_ref, {windowClass:'rol-partido-arbitro',ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',scrollable:true});
    this.modal_rol_partidos_arbitros.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  buscarTablaPosiciones(){
    this.blockUI.start();
    this.rolesPartidosService.obtenerTablaPosiciones(this.tabla_posiciones).subscribe((res:any)=>{
      this.equipos=res;
      for(let i=0;i<this.equipos.length;i++){
        if(i<this.equipos.length/2){
          this.equipos[i].clasificacion="#207020";
        }else{
          this.equipos[i].clasificacion="#ff0000";
        }
      }
      this.blockUI.stop();
    });
  }

  buscarTablaGoleadores(){
    this.blockUI.start();
    this.rolesPartidosService.obtenerTablaGoleadores(this.tabla_posiciones).subscribe((res:any)=>{
      this.goleadores=res;
      this.blockUI.stop();
    });
  }

  abrirModalTablaGoleadores(){
    this.tabla_posiciones={};
    this.obtenerCategoriasEquiposEmpresa();
    this.obtenerDisciplinasCampeonato();
    this.modal_tabla_goleadores=this.modalService.open(this.modal_tabla_goleadores_ref, {windowClass:'tabla-posiciones',ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static',scrollable:true});
    this.modal_tabla_goleadores.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  async modificarPartido(partido){
    partido.modificar=!partido.modificar;
    let fecha_hora=new Date(partido.fecha_hora);
    partido.fecha_hora_texto=Util.convertirFechaAObjectoFecha(fecha_hora);
    partido.hora={hour:fecha_hora.getHours(),minute:fecha_hora.getMinutes()};
    this.obtenerEquiposCampeonato(this.rol_partido.campeonato,partido.categoria,partido.disciplina);
    partido.equipos=await this.campeonatosService.obtenerListaEquipos(this.rol_partido.campeonato.id,partido.categoria.id,partido.disciplina?partido.disciplina.id:0).toPromise();
    partido.equipo_1_c=partido.equipo_1;
    partido.equipo_2_c=partido.equipo_2;
  }

  async cambiarCategoriaModificacion(partido){
    partido.equipos=await this.campeonatosService.obtenerListaEquipos(this.rol_partido.campeonato.id,partido.categoria.id,partido.disciplina?partido.disciplina.id:0).toPromise();
  }

  async establecerPartidoEquipos(partido){
    if(await this.validarPartido(partido)){
      partido.fecha_hora=Util.convertirObjetoAfecha(partido.fecha_hora_texto);
      partido.fecha_hora.setHours(partido.hora.hour);
      partido.fecha_hora.setMinutes(partido.hora.minute);
      partido.modificar=!partido.modificar;
    }else{
      return;
    }
  }

  async validarDuplicidadPartido(partido){
    let respuesta:any=await this.rolesPartidosService.validarDuplicidadPartido(partido).toPromise();
    if(respuesta.tiene_error){
      this.toastr.error(respuesta.mensaje);
    }
    return respuesta.tiene_error;
  }

  async generarPdfTablaPosiciones(){
    this.blockUI.start();
    let doc = new PDFDocument({size:[612, Math.max(150+(this.equipos.length*30),468)],margin:10});
    let stream = doc.pipe(blobStream());
    let fondo=await Util.convertirUrlToBase64Image(this.rest_server+this.tabla_posiciones.campeonato.fondo_tabla_posiciones);
    doc.image(fondo,0,0, { width: GlobalVariable.Dictionary.PAPEL_OFICIO_MEDIDA[0], height: GlobalVariable.Dictionary.PAPEL_OFICIO_MEDIDA[1],align:'right' });
    doc.rect(0,0,GlobalVariable.Dictionary.PAPEL_OFICIO_MEDIDA[0],80).fill(this.tabla_posiciones.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta).stroke();
    if (this.usuario.empresa.imagen.length > 100) { doc.image(this.usuario.empresa.imagen,20,5, { width: 70, height: 70,align:'left' }); }
    doc.fillColor(this.tabla_posiciones.campeonato.configuracion_tarjeta_jugador.enunciados_color);
    doc.font('Times-Bold',30);
    doc.text("TABLA CLASIFICATORIA",0,15,{align:"center"});
    doc.font('Times-Bold',20);
    doc.text(this.tabla_posiciones.categoria.nombre.toUpperCase()+" - "+this.tabla_posiciones.disciplina.nombre.toUpperCase(),0,40,{align:"center"});
    doc.font('Times-Bold',18);
    doc.text(this.tabla_posiciones.fase.nombre.toUpperCase(),0,60,{align:"center"});
    let logo=await Util.convertirUrlToBase64Image(this.rest_server+this.tabla_posiciones.campeonato.logo);
    doc.image(logo,522,5, { width: 70, height: 70,align:'right' });
    doc.font('Helvetica-Bold',16);
    let y_partido=135,items=0,page=1,partidos_por_pagina=20;
    doc.roundedRect(35,87,550,40,20).fill(this.tabla_posiciones.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta).stroke();
    doc.fillColor(this.tabla_posiciones.campeonato.configuracion_tarjeta_jugador.enunciados_color);
    doc.text("Nº",45,100);
    doc.text("EQUIPO",100,100);
    doc.text("PJ",340,100);
    doc.text("PG",370,100);
    doc.text("PE",400,100);
    doc.text("PP",430,100);
    doc.text("MF",460,100);
    doc.text("MC",490,100);
    doc.text("MD",520,100);
    doc.text("PTS",550,100);
    for(let i=0;i<this.equipos.length;i++){
      doc.roundedRect(50,y_partido-8,20,30,10).fill(this.equipos[i].clasificacion).stroke();
      doc.rect(40,y_partido-8,20,30).fill(this.equipos[i].clasificacion).stroke();
      doc.fillColor(this.tabla_posiciones.campeonato.configuracion_tarjeta_jugador.enunciados_color);
      doc.text(i+1,45,y_partido);
      let logo_eq=await Util.convertirUrlToBase64Image(this.rest_server+this.equipos[i].logo);
      doc.image(logo_eq,70,y_partido-7, { width: 25, height: 25,align:'right' });
      doc.text(this.equipos[i].nombre,100,y_partido);
      doc.text(this.equipos[i].partidos_jugados,340,y_partido);
      doc.text(this.equipos[i].victorias,370,y_partido);
      doc.text(this.equipos[i].empates,400,y_partido);
      doc.text(this.equipos[i].derrotas,430,y_partido);
      doc.text(this.equipos[i].marcador_a_favor,460,y_partido);
      doc.text(this.equipos[i].marcador_contra,490,y_partido);
      doc.text(this.equipos[i].marcador_diferencia,520,y_partido);
      doc.text(this.equipos[i].puntos,550,y_partido);
      items++;
      y_partido=y_partido+30;
    }

    doc.end();
    this.blockUI.stop();
    stream.on('finish', function(d) {
      var fileURL = stream.toBlobURL('application/pdf');
      window.open(fileURL,'_blank','location=no');
    });
  }

  buscarPartidosArbitro(){
    this.blockUI.start();
    this.rolesPartidosService.obtenerPartidosArbitro(this.rol_partido_arbitro).subscribe((res:any)=>{
      this.blockUI.stop();
      this.rol_partido_arbitro.partidos=res;
      this.allItemsSelected=true;
      this.selectAllItems(this.rol_partido_arbitro.partidos);
    });
  }

  async generarPdfRolPartidosArbitro(){
    let rol_partido=JSON.parse(JSON.stringify(this.rol_partido_arbitro));
    if(rol_partido.partidos.filter(p => p.activo && p.selected).length>0){
      rol_partido.partidos=rol_partido.partidos.filter(p => p.activo && p.selected);
    }else{
      rol_partido.partidos=rol_partido.partidos.filter(p => p.activo);
    }

    let canchas = rol_partido.partidos.filter((partido, i, arr) => arr.findIndex(t => t.id_escenario === partido.id_escenario) === i);
    console.log(canchas);
    for(let i=0;i<canchas.length;i++){
      let indice=rol_partido.partidos.indexOf(canchas[i]);
      rol_partido.partidos.splice(indice,0,{fecha_hora:canchas[i].fecha_hora,escenario:canchas[i].escenario})
    }

    let fechas = rol_partido.partidos.filter((partido, i, arr) => arr.findIndex(t => new Date(t.fecha_hora).toDateString() === new Date(partido.fecha_hora).toDateString() && partido.id!=null) === i);
    for(let i=0;i<fechas.length;i++){
      let indice=rol_partido.partidos.indexOf(fechas[i]);
      rol_partido.partidos.splice(indice,0,{fecha_hora:fechas[i].fecha_hora,escenario:fechas[i].escenario})
    }

    let doc = new PDFDocument({size:[612, Math.max(150+(40*rol_partido.partidos.length),468)],margin:10});
    let stream = doc.pipe(blobStream());
    let fondo=await Util.convertirUrlToBase64Image(this.rest_server+"img/fondo-rol-partido.png");
    await this.dibujarCabeceraRolPartidoArbitro(doc,fondo,rol_partido);
    let y_partido=100,items=0,page=1,partidos_por_pagina=20;
    for(let i=0;i<rol_partido.partidos.length;i++){
      doc.switchToPage(page-1);
      items++;
      if(rol_partido.partidos[i].equipo_1){
        //doc.roundedRect(20,y_partido,GlobalVariable.Dictionary.PAPEL_CARTA_MEDIDA[0]-40,25,5).fill(rol_partido.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta).stroke();
        let fecha_hora=new Date(rol_partido.partidos[i].fecha_hora);
        doc.fillColor("#06427e");
        //doc.fillColor(rol_partido.campeonato.configuracion_tarjeta_jugador.enunciados_color);
        
        doc.text(fecha_hora.getHours()+":"+fecha_hora.getMinutes(),20,y_partido+3,{width:43,align:"left"});
        doc.font('Helvetica-Bold',16);
        doc.fillColor("#000000");
        doc.text(rol_partido.partidos[i].equipo_1.nombre,60,y_partido+7,{width:231,align:"right"});
        let logo_eq_1=await Util.convertirUrlToBase64Image(this.rest_server+rol_partido.partidos[i].equipo_1.logo);
        doc.image(logo_eq_1,62,y_partido-0, { width: 25, height: 25,align:'right' });
        doc.fillColor("#06427e");

        doc.font('Helvetica',8);
        if(rol_partido.partidos[i].disciplina.nombre=="BALONCESTO"){
          doc.roundedRect(280,y_partido+22,90,12,5).fill("#ed7213").stroke();
        }else{
          doc.roundedRect(280,y_partido+22,90,12,5).fill("#06427e").stroke();
        }
        doc.fillColor("#ffffff");
        doc.text(rol_partido.partidos[i].disciplina.nombre,281,y_partido+25,{width:90,height:30,align:"center"});

        doc.font('Helvetica-Bold',16);
        let color_categoria;
        color_categoria=rol_partido.partidos[i].categoria.nombre_corto?rol_partido.partidos[i].categoria.nombre_corto:"#06427e";
        doc.fillColor(color_categoria);
        doc.text("VS",311,y_partido,{width:30,align:"center"});          
        doc.font('Helvetica-Bold',10);
        doc.roundedRect(295,y_partido+10,62,14,5).fill(color_categoria).stroke();
        doc.fillColor("#ffffff");
        doc.text(rol_partido.partidos[i].categoria.nombre,296,y_partido+13,{width:60,align:"center"});

        doc.font('Helvetica-Bold',16);
        doc.fillColor("#000000");
        doc.text(rol_partido.partidos[i].equipo_2.nombre,361,y_partido+7,{width:231,align:"left"});
        doc.fillColor("#000000");
        let logo_eq_2=await Util.convertirUrlToBase64Image(this.rest_server+rol_partido.partidos[i].equipo_2.logo);
        doc.image(logo_eq_2,565,y_partido-0, { width: 25, height: 25,align:'right' });
      }else{
        doc.roundedRect(153,y_partido,300,30,5).fill("#06427e").stroke();
        let fecha_hora=new Date(rol_partido.partidos[i].fecha_hora);
        doc.fillColor("#ffffff");
        let y_fecha=y_partido+8;
        y_fecha=y_partido+5;
        doc.font('Helvetica-Bold',10);
        doc.text(rol_partido.partidos[i].escenario.nombre,0,y_partido+20,{width:612,align:"center"});
        doc.font('Helvetica-Bold',16);
        doc.text(fecha_hora.toLocaleDateString("es-ES", { weekday: 'long' }).toUpperCase()+", "+fecha_hora.getDate()+" "+fecha_hora.toLocaleString('es-ES', { month: 'long' }).toUpperCase()+" "+fecha_hora.getFullYear(),0,y_fecha,{width:612,align:"center"});
      }
      y_partido=y_partido+40;

      if(items==partidos_por_pagina){
        items=0;
        y_partido=100;
        doc.addPage({size:[612, Math.max(150+(40*rol_partido.partidos.length),468)],margin:10});
        page++;
        await this.dibujarCabeceraRolPartidoArbitro(doc,fondo,rol_partido);
      }
    }

    doc.end();
    stream.on('finish', function(d) {
      var fileURL = stream.toBlobURL('application/pdf');
      window.open(fileURL,'_blank','location=no');
    });
  }

  async dibujarCabeceraRolPartidoArbitro(doc,fondo,rol_partido){
    doc.image(fondo,0,0, { width: GlobalVariable.Dictionary.PAPEL_OFICIO_MEDIDA[0], height: GlobalVariable.Dictionary.PAPEL_OFICIO_MEDIDA[1],align:'right' });
    doc.rect(0,0,GlobalVariable.Dictionary.PAPEL_OFICIO_MEDIDA[0],80).fill("#06427e").stroke();
    if (this.usuario.empresa.imagen.length > 100) { doc.image(this.usuario.empresa.imagen,20,5, { width: 70, height: 70,align:'left' }); }
    doc.fillColor("#ffffff");
    doc.font('Times-Bold',20);
    doc.text(this.usuario.empresa.nombre.toUpperCase(),0,20,{align:"center"});
    doc.font('Times-Bold',15);
    doc.text("ROL DE PARTIDOS - ARBITRO: "+rol_partido.arbitro.persona.nombre_completo.toUpperCase(),0,50,{align:"center"});
    if (this.usuario.empresa.imagen.length > 100) { doc.image(this.usuario.empresa.imagen,522,5, { width: 70, height: 70,align:'right' }); }
    doc.font('Helvetica-Bold',16);
  }

  async crearNuevoJugador(equipo){
    this.modalJugador = this.modalService.open(RegistroJugadorComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
    this.modalJugador.componentInstance.equipo = equipo;
		this.modalJugador.componentInstance.usuario = this.usuario;
		
		this.modalJugador.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
          this.blockUI.start();
          this.rolesPartidosService.obtenerRolPartido(this.rol_partido.id).subscribe((res:any)=>{
            this.blockUI.stop();
            this.rol_partido=res;
            this.partido=this.rol_partido.partidos.filter(par => par.id==this.partido.id)[0];
          });
					this.modalJugador.close();
				}
			}else{
				this.modalJugador.close();
			}
			
		});
  }

  async generarPdfTablaGoleadores(){
    this.blockUI.start();
    let doc = new PDFDocument({size:[GlobalVariable.Dictionary.PAPEL_CARTA_MEDIDA[0],GlobalVariable.Dictionary.PAPEL_CARTA_MEDIDA[1]],margin:10});
    let stream = doc.pipe(blobStream());
    let fondo=await Util.convertirUrlToBase64Image(this.rest_server+this.tabla_posiciones.campeonato.fondo_tabla_posiciones);
    doc.image(fondo,0,0, { width: GlobalVariable.Dictionary.PAPEL_CARTA_MEDIDA[0], height: GlobalVariable.Dictionary.PAPEL_CARTA_MEDIDA[1],align:'right' });
    doc.rect(0,0,GlobalVariable.Dictionary.PAPEL_CARTA_MEDIDA[0],80).fill(this.tabla_posiciones.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta).stroke();
    if (this.usuario.empresa.imagen.length > 100) { doc.image(this.usuario.empresa.imagen,20,5, { width: 70, height: 70,align:'left' }); }
    doc.fillColor(this.tabla_posiciones.campeonato.configuracion_tarjeta_jugador.enunciados_color);
    doc.font('Times-Bold',30);
    doc.text("TABLA GOLEADORES",0,15,{align:"center"});
    doc.font('Times-Bold',20);
    doc.text(this.tabla_posiciones.categoria.nombre.toUpperCase()+" - "+this.tabla_posiciones.disciplina.nombre.toUpperCase(),0,40,{align:"center"});
    let logo=await Util.convertirUrlToBase64Image(this.rest_server+this.tabla_posiciones.campeonato.logo);
    doc.image(logo,522,5, { width: 70, height: 70,align:'right' });
    doc.font('Helvetica-Bold',16);
    let y_partido=135,items=0,page=1,partidos_por_pagina=20;
    doc.roundedRect(10,87,590,40,20).fill(this.tabla_posiciones.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta).stroke();
    doc.fillColor(this.tabla_posiciones.campeonato.configuracion_tarjeta_jugador.enunciados_color);
    doc.text("Nº",15,100);
    doc.text("EQUIPO",100,100);
    doc.text("JUGADOR",340,100);
    doc.text("GOLES",530,100);
    for(let i=0;i<this.goleadores.length;i++){
      doc.roundedRect(20,y_partido-8,20,60,10).fill(this.tabla_posiciones.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta).stroke();
      doc.rect(10,y_partido-8,20,60).fill(this.tabla_posiciones.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta).stroke();
      doc.fillColor(this.tabla_posiciones.campeonato.configuracion_tarjeta_jugador.enunciados_color);
      doc.text(i+1,15,y_partido);
      let logo_eq=await Util.convertirUrlToBase64Image(this.rest_server+this.goleadores[i].logo_equipo);
      let foto_jugador=await Util.convertirUrlToBase64Image(this.rest_server+this.goleadores[i].foto_jugador);
      doc.image(logo_eq,35,y_partido-7, { width: 50, height: 50,align:'right' });
      doc.text(this.goleadores[i].equipo,85,y_partido+10);
      doc.text(this.goleadores[i].jugador.toUpperCase(),325,y_partido+10,{width:160});
      doc.image(foto_jugador,490,y_partido-7, { width: 50, height: 50,align:'right' });
      doc.text(this.goleadores[i].marcador,550,y_partido+10);
      items++;
      y_partido=y_partido+60;
    }

    doc.end();
    this.blockUI.stop();
    stream.on('finish', function(d) {
      var fileURL = stream.toBlobURL('application/pdf');
      window.open(fileURL,'_blank','location=no');
    });
  }

  aumentarMarcadorEq1(partido){
    partido.marcador_equipo_1=partido.marcador_equipo_1?partido.marcador_equipo_1+1:1;
    this.socket.emit('cambiarMarcador',partido);
  }

  disminuirMarcadorEq1(partido){
    partido.marcador_equipo_1=partido.marcador_equipo_1?partido.marcador_equipo_1-1:1;
    this.socket.emit('cambiarMarcador',partido);
  }

  aumentarMarcadorEq2(partido){
    partido.marcador_equipo_2=partido.marcador_equipo_2?partido.marcador_equipo_2+1:1;
    this.socket.emit('cambiarMarcador',partido);
  }

  disminuirMarcadorEq2(partido){
    partido.marcador_equipo_2=partido.marcador_equipo_2?partido.marcador_equipo_2-1:1;
    this.socket.emit('cambiarMarcador',partido);
  }
  

  copiarEnlace(partido){
    navigator.clipboard.writeText(GlobalVariable.SERVER_URL+"tor/consulta-marcador-partido/partido/"+partido.id);
  }

  abrirConfiguracionAplicacion(){
		this.configuracion_aplicacion_modal=this.modalService.open(ConfiguracionAplicacionRolPartidosComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',scrollable:true});
		this.configuracion_aplicacion_modal.componentInstance.configuracion_pagina=this.configuracion_pagina;
		this.configuracion_aplicacion_modal.componentInstance.usuario=this.usuario;
		this.configuracion_aplicacion_modal.componentInstance.alTerminar.subscribe((res) => {
			this.configuracion_aplicacion_modal.close();
		});
	}
}
