import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class PacientesService {

  constructor(private http: HttpClient) { }

  public obtenerListaPacientes(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"pacientes/empresa/"+paginator.filter.id_empresa+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+paginator.search+"/columna/"+paginator.column+"/direccion/"+paginator.direction,GlobalVariable.httpOptions);
  }

  public obtenerPacientesEmpresa(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"pacientes/empresa/"+id_empresa,GlobalVariable.httpOptions);
  }

  public guardarPaciente(paciente){
    return this.http.post(GlobalVariable.API_SERVER_URL+"pacientes/",paciente,GlobalVariable.httpOptions);
  }

  public actualizarPaciente(paciente){
    return this.http.put(GlobalVariable.API_SERVER_URL+"pacientes/"+paciente.id,paciente,GlobalVariable.httpOptions);
  }

  public eliminarPaciente(id_paciente){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"pacientes/"+id_paciente, GlobalVariable.httpOptions);
  }

  public guardarPacientesImportacion(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"pacientes/empresa",datos, GlobalVariable.httpOptions);
  }

  public buscarPaciente(id_empresa,texto){
    return this.http.get(GlobalVariable.API_SERVER_URL+"pacientes/empresa/"+id_empresa+"/texto/"+texto, GlobalVariable.httpOptions);
  }

  public obtenerHistoriasPaciente(paginator){
    let fecha_inicial=paginator.filter.fecha_inicio?new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0):0;
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"kardex-paciente/paciente/"+paginator.filter.id_paciente+"/fecha-inicio/"+fecha_inicial+"/fecha-fin/"+fecha_fin,GlobalVariable.httpOptions);
  }

  public guardarReceta(id_historia_paciente,historia_paciente){
    return this.http.put(GlobalVariable.API_SERVER_URL+"receta/historia-paciente/"+id_historia_paciente,historia_paciente, GlobalVariable.httpOptions);
  }

  public obtenerReceta(id_historia_paciente){
    return this.http.get(GlobalVariable.API_SERVER_URL+"receta/historia-paciente/"+id_historia_paciente, GlobalVariable.httpOptions);
  }
  
}
