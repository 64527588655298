import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { AsistenciasService } from '../../services/asistencias/asistencias.service';
import { EstudiantesService } from '../../services/estudiantes/estudiantes.service';
import { GeneralService } from '../../../base/services/general/general.service';
import { MateriasService } from '../../services/materias/materias.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { ProfesoresService } from '../../services/profesores/profesores.service';
import { CalificacionesService } from '../../services/calificaciones/calificaciones.service';
import { RegistroCalificacionComponent } from '../registro-calificacion/registro-calificacion.component';
import { OrderPipe } from 'ngx-order-pipe';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { RegistroCalificacionInicialComponent } from '../registro-calificacion-inicial/registro-calificacion-inicial.component';
import { Util } from 'src/app/utils/utils';

declare const resaltarPestañaMenu:any;

@Component({
  selector: 'app-calificaciones',
  templateUrl: './calificaciones.component.html',
  styleUrls: ['./calificaciones.component.css']
})
export class CalificacionesComponent extends BaseComponent implements OnInit {

  gestion_actual:any;
  estudiantes:any=[];
  estados_asistencia:any=[];
  fechas_asistencia:any[]=[];
  estudiante_actual:any={};
  ventana_registro_calificacion:NgbModalRef;
  escuela:any;
  cabecera_planilla:any=[];
  es_rol_profesor:boolean=true;

  constructor(public persistenciaService:PersistenciaService,
    public router:Router,
    public modalService: NgbModal,
    public generalService:GeneralService,
    public materiasService:MateriasService,
    public estudiantesService:EstudiantesService,
    public asistenciasService:AsistenciasService,
    public profesoresService:ProfesoresService,
	public toastr: ToastrService,
	public calificacionesService:CalificacionesService,
	private orderPipe: OrderPipe,
	public usuarioService:UsuarioService) {
    super(persistenciaService,modalService,generalService,usuarioService);
  }

  async ngOnInit(): Promise<void> {
    this.init();
    this.buscarAplicacion(this.router.url.substring(1));
	this.es_rol_profesor=Util.esRolUsuario("PROFESOR",this.usuario);
    this.filter={
		empleado:null,
		empleados:[],
		registros_profesor:[],
		intervalos_escolares:[],
	  	tipo_intervalo:{},
	  	gestion:{},
		gestiones:[]
    }
	await this.obtenerGestionesColegio();
	await this.obtenerGestionActual();
	this.escuela=await this.profesoresService.obtenerEscuela(this.usuario.id_empresa).toPromise();
	if(this.es_rol_profesor){
		this.filter.empleado={id_persona:this.usuario.id_persona,persona:this.usuario.persona};
		this.obtenerRegistrosProfesor();
	}else{
		await this.obtenerProfesores();
	}
    this.obtenerIntervalosColegio();
  }

  ngAfterViewInit() {
    resaltarPestañaMenu(this.router.url.substring(1)+"1");
  }

  async obtenerGestionesColegio(){
    let tipo_gestion:any=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_GESTIONES).toPromise();
	this.filter.gestiones=tipo_gestion?tipo_gestion.clases:[];
  }

  async obtenerProfesores(){
	this.blockUI.start();
    let empleados=await this.calificacionesService.obtenerProfesoresEscuela(this.filter.gestion.id,this.usuario.id_empresa).toPromise();
	this.filter.empleados=empleados;
    this.blockUI.stop();
  }

  async obtenerGestionActual(){
    this.blockUI.start();
    let gestion_actual=(new Date().getFullYear()).toString();
	this.gestion_actual=(this.filter.gestiones)?this.filter.gestiones.filter(g => g.nombre==gestion_actual).length>0?this.filter.gestiones.filter(g => g.nombre==gestion_actual)[0]:null:null;
	this.filter.gestion=this.gestion_actual;
    this.blockUI.stop();
  }
  
  obtenerRegistrosProfesor(){
    if(this.gestion_actual){
      this.blockUI.start();
      this.profesoresService.obtenerRegistrosProfesorGestion(this.filter.empleado.id_persona,this.filter.gestion.id).subscribe((registros_profesor:any[])=>{
        this.filter.registros_profesor=registros_profesor;
        this.blockUI.stop();
      }); 
    }
  }

  obtenerDatosCurso(id_curso){
    //this.obtenerMateriasCurso(id_curso);
    //this.obtenerEstudiantesCurso(id_curso);
  }

  async obtenerIntervalosColegio(){
    this.blockUI.start();
    let tipo_intervalo:any=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_INTERVALOS_ESCOLAR).toPromise();
    this.filter.tipo_intervalo=tipo_intervalo?tipo_intervalo:{};
    this.blockUI.stop();
  }

	async abrirCalificaciones(registro_profesor,intervalo_escolar){
		this.blockUI.start("Espere un momento, esto puede tardar unos minutos, depende si el registro ya ha sido creado!");
		this.filter.registro_profesor=registro_profesor;
		this.filter.registro_profesor.configuracion=this.filter.registro_profesor.configuracion?this.filter.registro_profesor.configuracion:await this.calificacionesService.obtenerConfiguracionCalificacion(registro_profesor.id_gestion,this.usuario.id_empresa,0).toPromise();
		this.filter.intervalo_escolar=intervalo_escolar;
		this.filter.valor_inicial_dimension=0;
		this.filter.valor_contador_dimension=0;
		//$scope.bimester_able=bimesterShortName=="1"?"first_bimester_enabled":(bimesterShortName=="2"?"second_bimester_enabled":(bimesterShortName=="3"?"third_bimester_enabled":"fourth_bimester_enabled"));
		//Iterar lista de dimensiones - Max values dimensiones
		let contador_total_calificaciones=1;
		for(let i=0;i<this.filter.registro_profesor.configuracion.length;i++){
			this.filter.registro_profesor.configuracion[i].valor_maximo=(this.filter.registro_profesor.tipo_calculo.nombre_corto==GlobalVariable.Dictionary.CALCULO_CALIFICACION_NOTA_CNP)?parseInt(this.filter.registro_profesor.configuracion[i].dimension.nombre_corto):100;
			contador_total_calificaciones=contador_total_calificaciones+this.filter.registro_profesor.configuracion[i].configuracion.numero_casillas+2;
		}

		//$scope.score_is_general=is_general;

		/*if(is_general){
			var promise=GradeReportData($scope.filter.management.id,$scope.filter.school.id,grade.id);
			promise.then(function(entitiy){
				$scope.students = entitiy.students;
				var bimester_id=$.grep($scope.bimesters, function(e){return e.short_name == bimesterShortName;})[0].id;

				for(var i=0;i<$scope.students.length;i++){
					$scope.students[i].inscriptions[0].scores=Array.apply( null, { length: totalCountScores } );
					for(var j=0;j<$scope.students[i].inscriptions[0].scores.length;j++){
						for(var k=0;k<$scope.filter.school.dimensions.length;k++){
							for(var l=0;l<$scope.filter.school.dimensions[k].field_number+2;l++){
								$scope.students[i].inscriptions[0].scores[j]={
									value:null,order:j+1,
									bimester_id:bimester_id,
									score_type_id:$scope.filter.school.dimensions[k].dimension.id,
									score_type:$scope.filter.school.dimensions[k].dimension
								};
								j++;		
							}
						}
					}
					$scope.students[i].inscriptions[0].scores[$scope.students[i].inscriptions[0].scores.length-1]={
									value:null,order:totalCountScores,
									bimester_id:bimester_id,score_type_id:null,score_type:null};
				}
				var fileName="REGISTRO-DE-NOTAS-"+$scope.bimesterName+"-"+gradeName;
				$scope.downloadExcelFormat=restServer+"teacher/downloadFormat/teacher-registry/"+teacher_registry_id+"/schoolManagement/"+$scope.filter.management.id+"/schoolRegistry/"+$scope.filter.school.id+"/grade/"+grade.id+"/subject/0/bimester/0/fileName/"+fileName+".xlsx";
				blockUI.stop();
			});
		}else{*/
			this.estudiantes=await this.calificacionesService.obtenerPlanillaCalificacion(this.usuario.id_empresa,this.filter.registro_profesor.id,this.filter.gestion.id,this.escuela.id,this.filter.registro_profesor.id_curso,this.filter.registro_profesor.id_materia,intervalo_escolar.id).toPromise();
			for(var i=0;i<this.estudiantes.length;i++){
				this.estudiantes[i].inscripciones_colegio[0].calificaciones=this.estudiantes[i].inscripciones_colegio[0].calificaciones[0].registro?this.estudiantes[i].inscripciones_colegio[0].calificaciones[0].registro:this.estudiantes[i].inscripciones_colegio[0].calificaciones;
				this.estudiantes[i].inscripciones_colegio[0].calificaciones=this.orderPipe.transform(this.estudiantes[i].inscripciones_colegio[0].calificaciones, 'orden',false);
			}
			//var fileName=$scope.filter.management.name+"-"+$scope.filter.school.name+"-"+$scope.bimesterName+"-"+grade.name+"-"+subject.name;
			//$scope.downloadExcelFormat=restServer+"teacher/downloadFormat/teacher-registry/"+teacher_registry_id+"/schoolManagement/"+$scope.filter.management.id+"/schoolRegistry/"+$scope.filter.school.id+"/grade/"+grade.id+"/subject/"+subject.id+"/bimester/"+bimesterShortName+"/fileName/"+fileName+".xlsx";
			
		//}

		//if(teacher_registry_id!=0){
			this.cabecera_planilla=await this.calificacionesService.obtenerCabeceraPlanillaCalificacion(this.filter.registro_profesor.id,this.filter.intervalo_escolar.id).toPromise();
		//}
		
		this.blockUI.stop();

    this.ventana_registro_calificacion = this.modalService.open(RegistroCalificacionComponent, {windowClass:'registro-calificacion',ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static', scrollable:true});
	this.ventana_registro_calificacion.componentInstance.estudiantes = this.estudiantes;
	this.ventana_registro_calificacion.componentInstance.filter = this.filter;
	this.ventana_registro_calificacion.componentInstance.escuela = this.escuela;
	this.ventana_registro_calificacion.componentInstance.usuario = this.usuario;
	this.ventana_registro_calificacion.componentInstance.cabecera_planilla=this.cabecera_planilla;
	
	this.ventana_registro_calificacion.componentInstance.alTerminar.subscribe(($e) => {
		this.ventana_registro_calificacion.close();
	});
  }

	async abrirCalificacionesInicial(registro_profesor,intervalo_escolar){
		this.blockUI.start("Espere un momento, esto puede tardar unos minutos, depende si el registro ya ha sido creado!");
		this.filter.registro_profesor=registro_profesor;
		this.filter.intervalo_escolar=intervalo_escolar;
		this.filter.valor_inicial_dimension=0;
		this.filter.valor_contador_dimension=0;
		this.estudiantes=await this.calificacionesService.obtenerPlanillaCalificacionInicial(this.usuario.id_empresa,this.filter.registro_profesor.id,this.filter.gestion.id,this.escuela.id,this.filter.registro_profesor.id_curso,this.filter.registro_profesor.id_materia,intervalo_escolar.id).toPromise();
		for(var i=0;i<this.estudiantes.length;i++){
			this.estudiantes[i].inscripciones_colegio[0].calificaciones=this.estudiantes[i].inscripciones_colegio[0].calificaciones[0].registro?this.estudiantes[i].inscripciones_colegio[0].calificaciones[0].registro:this.estudiantes[i].inscripciones_colegio[0].calificaciones;
			this.estudiantes[i].inscripciones_colegio[0].calificaciones=this.orderPipe.transform(this.estudiantes[i].inscripciones_colegio[0].calificaciones, 'orden',false);
		}
	
		this.blockUI.stop();

		this.ventana_registro_calificacion = this.modalService.open(RegistroCalificacionInicialComponent, {windowClass:'registro-calificacion',ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static', scrollable:true});
		this.ventana_registro_calificacion.componentInstance.estudiantes = this.estudiantes;
		this.ventana_registro_calificacion.componentInstance.filter = this.filter;
		this.ventana_registro_calificacion.componentInstance.escuela = this.escuela;
		this.ventana_registro_calificacion.componentInstance.usuario = this.usuario;

		this.ventana_registro_calificacion.componentInstance.alTerminar.subscribe(($e) => {
			this.ventana_registro_calificacion.close();
		});
	}

}
