<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Centralizador
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          de Calificaciones {{global_variable.Dictionary.SCH.TEXTO_PERIODOS_ANUAL}}
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Lista de</span>
      Estudiantes {{global_variable.Dictionary.SCH.TEXTO_PERIODOS_ANUAL}}
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-12 col-md-9">
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="row">
                  <div class="col-2">
                      <label>Gestión Escolar </label>
                      <select [compareWith]="compararObjectos" class="form-control" required name="gestion" [(ngModel)]="filter.gestion">
                          <option [ngValue]="gestion" *ngFor="let gestion of filter.gestiones">{{gestion.nombre}}</option>
                      </select>
                  </div>
                  <div class="col-3">
                      <label>Nivel </label>
                      <select (change)="obtenerCursosPorNivel(filter.nivel.id)" [compareWith]="compararObjectos" name="nivel" class="form-control" [(ngModel)]="filter.nivel">
                          <option [ngValue]="nivel" *ngFor="let nivel of filter.niveles">{{nivel.nombre_corto}}</option>
                      </select>
                  </div>
                  <div class="col-2">
                      <label>Curso </label>
                      <select [compareWith]="compararObjectos" class="form-control" required name="curso" [(ngModel)]="filter.curso">
                          <option [ngValue]="curso" *ngFor="let curso of filter.cursos | orderBy:'orden':false">{{curso.nombre_corto}}</option>
                      </select> 
                  </div>
                  <div class="col-2">
                      <label>{{global_variable.Dictionary.SCH.TEXTO_PERIODOS_ANUAL}}: </label>
                      <select [compareWith]="compararObjectos" class="form-control" required name="int_esc" [(ngModel)]="filter.intervalo_escolar">
                          <option [ngValue]="intervalo_escolar" *ngFor="let intervalo_escolar of filter.intervalos_escolares">{{intervalo_escolar.nombre}}</option>
                      </select> 
                  </div>
                  <div class="col-3">
                      <label>Parámetro: </label>
                      <select class="form-control" required name="param" [(ngModel)]="filter.parametro">
                          <option value="materia">POR MATERIAS</option>
                          <option value="area">POR AREAS</option>
                      </select> 
                  </div>
                </div>
              </div>
            </div>
		      </div>
          <div class="col-12 col-md-3">
            <div class="row">
              <div class="col-4">
                <div class="columns columns-right btn-group float-right">
                  <button (click)="generarCentralizador()" class="btn btn-xs btn-app btn-primary my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                    <i class="d-block h-4 fa fa fa-search text-150"></i>
                  </button>
                </div>
              </div>
              <div class="col-8">
                <div class="columns columns-right btn-group float-right">
                  <button [disabled]="estudiantes.length==0" (click)="generarExcelCentralizador()" class="btn btn-xs btn-success y-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Excel">
                    <i class="d-block h-4 fa fa fa-file-excel text-150"></i>
                  </button>&nbsp;
                  <button [disabled]="estudiantes.length==0" (click)="generarPdfCentralizador()" class="btn btn-xs btn-danger y-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Excel">
                    <i class="d-block h-4 fa fa fa-file-pdf text-150"></i>
                  </button>&nbsp;
                  <button (click)="descargarFormatoExcelCentralizador()" [disabled]="!filter.curso" class="btn btn-success" type="button">
                    <i class="fa fa-file-download"></i>
                  </button>&nbsp;
                  <button (click)="clickBotonSubir('subir-excel-centralizador')" [disabled]="!filter.curso || !filter.intervalo_escolar" class="btn btn-primary" aria-label="Export" type="button" title="Subir excel importación" aria-expanded="false">
                    <i class="fa fa-file-upload"></i>
                    <input id="subir-excel-centralizador" style="display:none" type="file" class="form-control" (change)="subirExcelCentralizador($event)" placeholder="Upload file" accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                  </button>
                </div>
              </div>
              
            </div>
          </div>
		    </div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header" style="display: none;">
          <table></table>
        </div>
        <div id="gallery" class="fixed-table-body table-responsive">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table class="table table-bordered table-hover table-striped" *ngIf="estudiantes.length>0">
            <thead>
                <tr>
                    <th>Nº</th>
                    <th style="width:200px;">Apellidos y Nombres</th>
                    <th style="font-size:10px" *ngFor="let score of estudiantes[0].inscripciones_colegio[0].calificaciones | orderBy:'materia.orden':false">{{score.materia.nombre}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let student of estudiantes; let i=index;">
                    <td>{{i+1}}</td>
                    <td style="font-size:10px;width:200px;">{{student.persona.nombre_completo.toUpperCase()}}</td>
                    <ng-container *ngIf="filter.nivel.nombre_corto!=global_variable.Dictionary.SCH.CLASE_NIVEL_INICIAL">
                      <td *ngFor="let studentScore of student.inscripciones_colegio[0].calificaciones | orderBy:'materia.orden':false" [ngClass]="{'text-danger': studentScore.valor<=50}">{{studentScore.valor}}</td>
                    </ng-container>
                    <ng-container *ngIf="filter.nivel.nombre_corto==global_variable.Dictionary.SCH.CLASE_NIVEL_INICIAL">
                      <td *ngFor="let studentScore of student.inscripciones_colegio[0].calificaciones | orderBy:'materia.orden':false">{{studentScore.descripcion}}</td>
                    </ng-container>
                </tr>
            </tbody>
        </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
</div>
