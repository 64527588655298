import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../services/general/general.service';
import { PersistenciaService } from '../../services/persistencia/persistencia.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { UsuarioService } from '../../services/usuario/usuario.service';
import { Util } from 'src/app/utils/utils';

@Component({
  selector: 'app-configuracion-aplicacion-rol-partidos',
  templateUrl: './configuracion-aplicacion-rol-partidos.component.html',
  styleUrls: ['./configuracion-aplicacion-rol-partidos.component.css']
})
export class ConfiguracionAplicacionRolPartidosComponent extends BaseComponent implements OnInit {

  @Input() configuracion_pagina:any;
  @Input() usuario:any;
  es_usuario_administrador:boolean=false;
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  tamanos_papel:any[]=[];

  constructor(public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    public usuarioService:UsuarioService) {
      super(persistenciaService,modalService,generalService,usuarioService);
     }

  ngOnInit(): void {
    this.obtenerTamanosPapel();
    this.es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
    this.verTab("impresion");
  }

  obtenerTamanosPapel(){
    this.generalService.obtenerClases("TAMPAPFACT").subscribe((entidad:any) => {
      this.tamanos_papel=entidad.clases;
    });
  }

  guardarConfiguracionVista(){
		this.configuracion_pagina.updateObject();
		this.toastr.success("Configuracion actualizada satisfactoriamente!");
		this.alTerminar.emit();
	}

  cerrarComponente(){
    this.alTerminar.emit();
  }

}
