
<div class="row text-white fixed-bottom">
    <div class="col-1 text-right p-0">
        <img width="40" height="40" alt="sin imagen" src="{{rest_server+partido.equipo_1.logo}}" />
    </div>
    <div class="col-4 text-right p-0 bgc-primary align-middle">
        {{partido.equipo_1.nombre}}
    </div>
    <div class="col-1 text-right bgc-yellow text-dark-m3 font-weight-bold">{{partido.marcador_equipo_1}}</div>
    <div class="col-1 text-left bgc-yellow text-dark-m3 font-weight-bold">{{partido.marcador_equipo_2}}</div>
    <div class="col-4 text-left p-0 bgc-primary">
        {{partido.equipo_2.nombre}}
    </div>
    <div class="col-1 text-left p-0">
        <img width="40" height="40" alt="sin imagen" src="{{rest_server+partido.equipo_2.logo}}" />
    </div>
</div>
