<div class="page-content container container-plus">
    <div class="page-header border-0">
      <h1 class="page-title text-primary-d2">
        Editorial Contexto Educativo
        <small class="page-info text-secondary-d3">
          <i class="fa fa-angle-double-right text-80"></i>
          Material de Apoyo
        </small>
      </h1>
      
      <a target="_blank" href="http://meet.google.com/new">Video Llamada &nbsp; <i class="float-right fa fa-video text-180 "></i></a>
    </div>


    <div class="row">
      <div class="col-12 col-md-6 mt-3 mt-md-0">
        <div class="card bcard h-auto border-0">
          <div class="card-header bgc-green-d3">
              <div class="col-8">
                <h3 class="card-title text-130 text-white">
                  Textos
                  <span class="text-75">(textos)</span>
                </h3>
              </div>
              <div class="col-4" *ngIf="editor">
                <button class="btn btn-primary" type="button" (click)="crearNuevoRecurso({id:'id-textos',directorio:'Textos'})">
                  <i class="ace-icon fa fa-plus red2 align-top text-90"></i>
                </button>
              </div>
          </div>

          <div class="card-body bgc-white border-1 border-t-0 brc-success-m3">
            <div class="jqtree tree-dotted" id="id-textos"></div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 mt-3 mt-md-0">
        <div class="card bcard h-auto border-0">
          <div class="card-header bgc-green-d3">
            <div class="col-8">
              <h3 class="card-title text-130 text-white">
                Solucionarios
                <span class="text-75">(ejemplos)</span>
              </h3>
            </div>
            <div class="col-4" *ngIf="editor">
              <button class="btn btn-primary" type="button" (click)="crearNuevoRecurso({id:'id-solucionarios',directorio:'Solucionarios'})">
                <i class="ace-icon fa fa-plus red2 align-top text-90"></i>
              </button>
            </div>
          </div>

          <div class="card-body bgc-white border-1 border-t-0 brc-success-m3">
            <div class="jqtree tree-dotted" id="id-solucionarios"></div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 mt-3 mt-md-0">
        <div class="card bcard h-auto border-0">
          <div class="card-header bgc-green-d3">
            <div class="col-8">
              <h3 class="card-title text-130 text-white">
                Planificadores
                <span class="text-75">(ejemplos)</span>
              </h3>
            </div>
            <div class="col-4" *ngIf="editor">
              <button class="btn btn-primary" type="button" (click)="crearNuevoRecurso({id:'id-planificadores',directorio:'Planificadores'})">
                <i class="ace-icon fa fa-plus red2 align-top text-90"></i>
              </button>
            </div>
          </div>

          <div class="card-body bgc-white border-1 border-t-0 brc-success-m3">
            <div class="jqtree tree-dotted" id="id-planificadores"></div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 mt-3 mt-md-0">
        <div class="card bcard h-auto border-0">
          <div class="card-header bgc-primary-d3">
            <div class="col-8">
              <h3 class="card-title text-130 text-white">
                Laminoteca
                <span class="text-75">(laminas)</span>
              </h3>
            </div>
            <div class="col-4" *ngIf="editor">
              <button class="btn btn-primary" type="button" (click)="crearNuevoRecurso({id:'id-laminas',directorio:'Laminas'})">
                <i class="ace-icon fa fa-plus red2 align-top text-90"></i>
              </button>
            </div>
          </div>

          <div class="card-body bgc-white border-1 border-t-0 brc-success-m3">
            <div class="jqtree tree-dotted" id="id-laminas"></div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 mt-3 mt-md-0">
        <div class="card bcard h-auto border-0">
          <div class="card-header bgc-primary-d3">
            <div class="col-8">
              <h3 class="card-title text-130 text-white">
                Videos
                <span class="text-75">(videos)</span>
              </h3>
            </div>
            <div class="col-4" *ngIf="editor">
              <button class="btn btn-primary" type="button" (click)="crearNuevoRecurso({id:'id-videos',directorio:'Videos'})">
                <i class="ace-icon fa fa-plus red2 align-top text-90"></i>
              </button>
            </div>
          </div>

          <div class="card-body bgc-white border-1 border-t-0 brc-success-m3">
            <div class="jqtree tree-dotted" id="id-videos"></div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 mt-3 mt-md-0">
        <div class="card bcard h-auto border-0">
          <div class="card-header bgc-primary-d3">
            <div class="col-8">
              <h3 class="card-title text-130 text-white">
                Cartillas
                <span class="text-75">(cartillas)</span>
              </h3>
            </div>
            <div class="col-4" *ngIf="editor">
              <button class="btn btn-primary" type="button" (click)="crearNuevoRecurso({id:'id-cartillas',directorio:'Cartillas'})">
                <i class="ace-icon fa fa-plus red2 align-top text-90"></i>
              </button>
            </div>
          </div>

          <div class="card-body bgc-white border-1 border-t-0 brc-success-m3">
            <div class="jqtree tree-dotted" id="id-cartillas"></div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 mt-3 mt-md-0">
        <div class="card bcard h-auto border-0">
          <div class="card-header bgc-primary-d3">
            <div class="col-8">
              <h3 class="card-title text-130 text-white">
                Adicionales
                <span class="text-75">(adicionales)</span>
              </h3>
            </div>
            <div class="col-4" *ngIf="editor">
              <button class="btn btn-primary" type="button" (click)="crearNuevoRecurso({id:'id-adicionales',directorio:'Adicionales'})">
                <i class="ace-icon fa fa-plus red2 align-top text-90"></i>
              </button>
            </div>
          </div>

          <div class="card-body bgc-white border-1 border-t-0 brc-success-m3">
            <div class="jqtree tree-dotted" id="id-adicionales"></div>
          </div>
        </div>
      </div>
    </div>
</div>


<ng-template #visor_pdf let-modal>
	<div class="modal-header">
		<div class="col-md-10 col-xs-2">
			<h4 class="widget-title">{{nombre_pdf}}</h4>
		</div>
		<div class="col-md-2 col-xs-2">
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
		<!--pdf-viewer [src]="url_visor_pdf" 
              [render-text]="true"
              style="display: block;"
              [zoom]="1"
              [original-size]="true"
              [fit-to-page]="true"
              (after-load-complete)="afterLoadComplete($event)"
        ></pdf-viewer>-->
      
	</div>
</ng-template>


<div *ngIf="url_visor_pdf" class="modal modal-nb ace-aside aside-bottom aside-r aside-fade aside-offset aside-shown-above-nav" id="aside-pdf-visor" tabindex="7" role="dialog" aria-hidden="false">

  <div id="visor_pdf" class="modal-dialog mr-2" style="width: 80%; height:100vh;" role="document">
    <div class="modal-content border-0 bgc-primary mb-4 radius-1 shadow">
      <div class="modal-header bgc-primary">
        <div class="col-md-3 col-3">
          <h4 class="widget-title">{{nombre_pdf}}</h4>
        </div>
        <div class="col-md-9 col-9">
          <button class="btn btn-purple" type="button" (click)="anteriorPagina()">
            <i class="ace-icon fas fa-step-backward red2 align-top bigger-110"></i>
          </button>
          <input style="display:inline;-moz-appearance: textfield;-webkit-appearance: none;width:40px !important;padding: 0px;" type="number" step="0" name="pagina" [(ngModel)]="filter.currentPage" (keyup)="establecerPagina()" min="1" max="{{filter.pages}}" class="form-control"/>
          <button class="btn btn-purple" type="button" (click)="siguientePagina()">
            <i class="ace-icon fas fa-step-forward red2 align-top bigger-110"></i>
          </button>&nbsp;
          <label class="text-white">Línea</label>&nbsp;
          <select style="display:inline;width:50px" class="form-control" name="ancho_linea" [(ngModel)]="filter.ancho_linea">
            <option [ngValue]="ancho_linea" *ngFor="let ancho_linea of [1,3,5,7,9,11,13,15,17]">{{ancho_linea}}</option>
          </select>&nbsp;
          <label class="text-white">Lapicero</label>&nbsp;
          <input type="color" name="favcolor" [value]="filter.color" [(ngModel)]="filter.color" (change)="cambiarColor()">&nbsp;
          <button *ngIf="!escribiendo" class="btn btn-secondary" type="button" (click)="activarEscritura()">
            <i class="ace-icon fas fa-pen-alt red2 align-top bigger-110"></i>
          </button>&nbsp;
          <button *ngIf="escribiendo" class="btn btn-warning" type="button" (click)="desactivarEscritura()">
            <i class="ace-icon fas fa-edit red2 align-top bigger-110"></i>
          </button>&nbsp;
          <button class="btn btn-danger" type="button" (click)="borrarEscritura()">
            <i class="ace-icon fas fa-eraser red2 align-top bigger-110"></i>
          </button>&nbsp;
          <button class="btn btn-success" type="button" (click)="aumentarZoom()">
            <i class="ace-icon fa fa-search-plus red2 align-top bigger-110"></i>
          </button>&nbsp;
          <button class="btn btn-success" type="button" (click)="disminuirZoom()">
            <i class="ace-icon fa fa-search-minus red2 align-top bigger-110"></i>
          </button>&nbsp;
          <button class="btn btn-purple" type="button" *ngIf="!filter.maximizado" (click)="maximizarPdf()">
            <i class="ace-icon fas fa-window-maximize red2 align-top bigger-110"></i>
          </button>&nbsp;
          <button class="btn btn-purple" type="button" *ngIf="filter.maximizado" (click)="restaurarPdf()">
            <i class="ace-icon fas fa-minus-square red2 align-top bigger-110"></i>
          </button>&nbsp;
          <button class="btn btn-danger" type="button" (click)="cerrarVisorPdf()">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
          </button>
        </div>
      </div>
      <div class="modal-body bg-transparent p-0">
        <div *ngIf="!filter.pdf_cargado" class="alert fade show bgc-white text-dark-tp3 border-1 brc-secondary-l2 shadow-sm radius-0 py-3 d-flex align-items-start">
          <div class="position-tl w-102 m-n1px border-t-4 brc-success"></div>
          <div class="bgc-success-l4 px-3 py-3 radius-round mr-4 border-2 brc-success-m2">
            <i class="fa fa-spinner text-140 text-success mx-2px"></i>
          </div>

          <div class="text-dark-tp3">
            <h3 class="text-success-d1 text-130">Cargando el archivo!</h3>
            Si no carga, vuelva a intentar cerrando y volviendo a abrir el archivo pdf.
          </div>          
        </div>
        <pdf-viewer [src]="url_visor_pdf" 
              [render-text]="true"
              style="display: block;"
              [zoom]="pdf_visor_zoom"
              [original-size]="true"
              [fit-to-page]="true"
              [(page)]="filter.currentPage"
              [show-all]="false"
              (after-load-complete)="afterLoadComplete($event)"
        ></pdf-viewer>
      </div>
    </div>

    <!-- the button that toggle modal -->
    <!--<div class="aside-header fade position-br mb-5 d-flex align-items-center">
      <div style="cursor:pointer;" class="animation-appear d-none d-md-block shadow py-2 pl-3 pr-4 radius-round bg-white border-2 brc-info-tp1" data-toggle="modal" data-target="#aside-support">
        <div style="max-width: 220px;" class="text-95 pt-1 text-secondary-d3">
          <img src="assets/image/user.jpg" class="mr-2 float-left radius-round border-1 shadow-sm brc-primary-tp2" alt="User avatar" />
          Hey there, if you want to talk, I'm available
          <span class="fa-stack w-auto mt-n2px ">
    <i class="fas fa-circle text-dark fa-stack-1x text-100"></i>
    <i class="fas fa-smile-wink text-warning-m2 text-125 fa-stack-1x"></i>
 </span>
        </div>
      </div>

      <button type="button" class="shadow ml-2 btn btn-info radius-round mw-auto p-3" data-toggle="modal" data-target="#aside-pdf-visor">
        <i class="fas fa-headset text-150 px-2px"></i>
      </button>
    </div>-->
  </div>

</div>