import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GlobalVariable } from 'src/app/global';
import { Router } from '@angular/router';
import { BaseComponent } from '../../../models/base-component';
import { PersistenciaService } from '../../services/persistencia/persistencia.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GeneralService } from '../../services/general/general.service';
import { EmpresaService } from '../../services/empresas/empresa.service';
import { Socket } from 'ngx-socket-io';
import { ToastrService } from 'ngx-toastr';
import { PopupConfirmacionComponent } from '../../components/popup-confirmacion/popup-confirmacion.component';
import { ProductosService } from '../../../snapquick/services/productos/productos.service';
import { ClientesService } from '../../../snapquick/services/clientes/clientes.service';
import { ReportesService } from '../../../snapquick/services/reportes/reportes.service';
import { UsuarioService } from '../../services/usuario/usuario.service';
import * as fs from 'file-saver';
import { MapMarker } from '@angular/google-maps';
import { RegistroUsuarioComponent } from '../registro-usuario/registro-usuario.component';
import { EmpresaFacturacionComponent } from '../empresa-facturacion/empresa-facturacion.component';
import { OrderPipe } from 'ngx-order-pipe';
import { EstudiantesService } from 'src/app/school/services/estudiantes/estudiantes.service';
import { Util } from 'src/app/utils/utils';
import { PageConfiguration } from 'src/app/models/page-configuration';
import { ConceptosIngresosService } from 'src/app/institute/services/conceptos-ingresos/conceptos-ingresos.service';

declare const resaltarPestañaMenu:any;
declare const aplicarWizard:any;
declare const aplicarPluginImagen:any;

@Component({
  selector: 'app-empresas',
  templateUrl: './empresas.component.html',
  styleUrls: ['./empresas.component.css']
})
export class EmpresasComponent extends BaseComponent implements OnInit {
  server_url=GlobalVariable.SERVER_URL;
  conexion_google_map:boolean=true;
  empresas:any[]=[];
  empresa:any;
  planes_rubro:any[]=[];
  planes:any[]=[];
  roles:any[]=[];
  rol:any={};
  tipoMunicipios:any;
  tipoDepartamentos:any;
  rubros:any[];
  datos_eliminacion:any;
  descarga_app:any;
  markers:any[];
  center:any= { lat: -17.39380008501286700000, lng: -66.15696068334569000000 };
  tipo_dependencias:any={clases:[]};
  tipo_turnos:any={clases:[]};
  tipo_calificacion_notas:any={clases:[]};

  wizard_edicion_empresa:string="wizard_edicion_empresa";
  wizard_creacion_empresa:string="wizard_creacion_empresa";

  modalEdicionEmpresa:NgbModalRef;
  @ViewChild('modalEdicionEmpresa')
  private modalEdicionEmpresaRef: TemplateRef<any>;

  modalEliminacionDatos:NgbModalRef;
  @ViewChild('modalEliminacionDatos')
  private modalEliminacionDatosRef: TemplateRef<any>;

  modal_datos_facturacion:NgbModalRef;
  @ViewChild('modal_datos_facturacion')
  private modal_datos_facturacion_ref: TemplateRef<any>;

  modalCreacionEmpresa:NgbModalRef;
  @ViewChild('modalCreacionEmpresa')
  private modalCreacionEmpresaRef: TemplateRef<any>;

  configuracionGestiones:NgbModalRef;
  @ViewChild('configuracionGestiones')
  private configuracionGestionesRef: TemplateRef<any>;

  configuracionCalificaciones:NgbModalRef;
  @ViewChild('configuracionCalificaciones')
  private configuracionCalificacionesRef: TemplateRef<any>;

  descargaDatos:NgbModalRef;
  @ViewChild('descargaDatos')
  private descargaDatosRef: TemplateRef<any>;
  
  vistaRoles:NgbModalRef;
  @ViewChild('vistaRoles')
  private vistaRolesRef: TemplateRef<any>;

  registroRol:NgbModalRef;
  @ViewChild('registroRol')
  private registroRolRef: TemplateRef<any>;

  descargaempresa:NgbModalRef;
  @ViewChild('descargaempresa')
  private descargaempresaRef: TemplateRef<any>;

  edicionusuario:NgbModalRef;

  tipo_gestiones:any;
  configuraciones_calificaciones:any;
  gestion:any;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
	public empresasService:EmpresaService,
	public productosService:ProductosService,
	public clientesService:ClientesService,
	public reportesService:ReportesService,
	public usuariosService:UsuarioService,
    public modalService: NgbModal,
    public socket:Socket,
	private orderPipe: OrderPipe,
    private toastr: ToastrService,
	private estudiantesService:EstudiantesService,
	private conceptosIngresosService:ConceptosIngresosService) {
    super(persistenciaService,modalService,generalService,usuariosService);
   }

  ngOnInit(): void {
    this.init();
		this.buscarAplicacion(this.router.url.substring(1));
		this.filter={
			id_empresa:this.usuario.id_empresa?this.usuario.id_empresa:0,
			rubro: {nombre:"TODOS",id:0},//this.grupos_filtro[0],
			plan:{nombre:"TODOS",id:0},//this.subgrupos_filtro[0],
			rubros:[{nombre:"TODOS",id:0}],
			planes:[{nombre:"TODOS",id:0}]
    }
    
    this.column = "createdAt";
    this.getSearch(this.text_search,null);
    this.obtenerDepartamentos();
    this.obtenerRubrosEmpresa();
  }

  ngAfterViewInit() {
		resaltarPestañaMenu(this.router.url.substring(1)+"1");
  }
  
  getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
		this.empresasService.obtenerListaEmpresas(this).subscribe((dato:any) => {
			this.setPages(dato.paginas);
			this.empresas=dato.empresas;
			this.blockUI.stop();
		});
  }

  obtenerDepartamentos(){
		this.generalService.obtenerClases("DEP").subscribe((entidad:any)=>{
      this.tipoDepartamentos=entidad?entidad:{clases:[]};
    });
  }
  
  obtenerRubrosEmpresa () {
    this.generalService.obtenerClases("RUBEMP").subscribe((entidad:any)=>{
      this.rubros=entidad?entidad.clases:[];
    });
	}

  abrirPopupConfirmacionInactivacion(empresa){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de inhabilitar la empresa?";
		this.popupConfirmacion.componentInstance.data = empresa;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.inactivarEmpresa($e.data);
			}
			this.popupConfirmacion.close();
        });
	}
  
  inactivarEmpresa(empresa){
		this.blockUI.start();
		empresa.activo=false;
    this.socket.emit('cerrarSesion',{empresa:empresa,usuario:{}});
    this.empresasService.activarDesactivarEmpresa(empresa).subscribe((resa:any)=>{
      this.toastr.success(resa.mensaje);
      this.getItems();
      this.blockUI.stop();
    });
  }
  
  abrirPopupConfirmacionActivacion(empresa){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de habilitar la empresa?";
		this.popupConfirmacion.componentInstance.data = empresa;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.activarEmpresa($e.data);
			}
			this.popupConfirmacion.close();
        });
	}

	activarEmpresa(empresa){
		this.blockUI.start();
    empresa.activo=true;
    this.empresasService.activarDesactivarEmpresa(empresa).subscribe((resa:any)=>{
      this.toastr.success(resa.mensaje);
      this.getItems();
      this.blockUI.stop();
    });
  }

  validarDatosModificacion(){
    let res=true;
		if(!this.empresa.codigo){
			this.toastr.error("Debe ingresar un codigo de empresa!");
			res=false;
		}
		if(!this.empresa.nombre){
			this.toastr.error("Debe ingresar el nombre de la empresa!");
			res=false;
    }
    if(!this.empresa.razon_social){
			this.toastr.error("Debe ingresar la razon social de la empresa!");
			res=false;
    }
    if(!this.empresa.nit){
			this.toastr.error("Debe ingresar el nit de la empresa!");
			res=false;
    }
    if(!this.empresa.direccion){
			this.toastr.error("Debe ingresar la dirección de la empresa!");
			res=false;
    }
    if(!this.empresa.telefono1){
			this.toastr.error("Debe ingresar el telefono de la empresa!");
			res=false;
    }
		return res;
  }
  
  guardarModificacion(){
		this.blockUI.start();
		if(this.validarDatosModificacion()){
			let modulos=[];
			for(let i=0;i<this.empresa.modulos.length;i++){
				modulos.push(this.empresa.modulos[i]);
				for(let j=0;j<this.empresa.modulos[i].hijos.length;j++){
					modulos.push(this.empresa.modulos[i].hijos[j]);
				}
			}
			this.empresa.modulos=modulos;
			if(this.empresa.id){
        this.empresasService.modificarEmpresa(this.empresa).subscribe((res:any)=>{
			this.blockUI.stop();
			if(res.tiene_error){
				this.toastr.error(res.mensaje);
			}else{
				this.toastr.success(res.mensaje);
				this.modalEdicionEmpresa.close();
				this.getItems();
			}
        });
			}
		}else{
      this.blockUI.stop();
    }
  }
  
  async modificarEmpresa(empresa_a_modificar){
    this.blockUI.start();
    this.empresa=await this.empresasService.obtenerEmpresa(empresa_a_modificar.id).toPromise();
		await this.buscarPlanes(this.empresa.rubro);
		//this.buscarMunicipios(this.empresa.departamento);
		let modulos=await this.obtenerModulosConstruidosNuevaEmpresa();
		for(let i=0;i<modulos.length;i++){
			let modulo_encontrado=this.empresa.modulos.filter(m => m.modulo.id == modulos[i].modulo.id && m.habilitado).length>0;
			if(modulo_encontrado){
				modulos[i].habilitado=true;
			}
			for(let j=0;modulos[i].hijos && j<modulos[i].hijos.length;j++){
				let modulo_hijo_encontrado=this.empresa.modulos.filter(m => m.modulo.id == modulos[i].hijos[j].modulo.id && m.habilitado).length>0;
				if(modulo_hijo_encontrado){
					modulos[i].hijos[j].habilitado=true;
				}
			}
		}
    this.empresa.modulos=modulos;
    this.modalEdicionEmpresa=this.modalService.open(this.modalEdicionEmpresaRef, {windowClass:"empresa-edicion",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
    this.modalEdicionEmpresa.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
	aplicarWizard(this.wizard_edicion_empresa,this,'guardarModificacion');
	aplicarPluginImagen("id_logo");
	aplicarPluginImagen("id_portada");
		this.blockUI.stop();
  }
  
	async buscarPlanes(rubro){
    	let rubro_datos:any=await this.empresasService.obtenerPlanesRubro(rubro).toPromise();
		let planes=[];
		for(let i=0;i<rubro_datos.planes.length;i++){
			planes.push(rubro_datos.planes[i].plan);
		}
		this.planes_rubro=rubro_datos.planes;
		this.planes=planes;
		//this.empresa.plan_snapquick=null;
		//this.empresa.modulos=[];
		if(rubro.nombre.includes("COLEGIO")){
			this.obtenerDependencias();
			this.obtenerTurnos();
			this.obtenerTiposCalculoNotas();
		}
	}

	obtenerDependencias(){
		this.generalService.obtenerClases(GlobalVariable.Dictionary.DEPENDENCIAS_ESCUELA).subscribe((entidad:any)=>{
		  this.tipo_dependencias=entidad;
		});
	}
	
	obtenerTurnos(){
		this.generalService.obtenerClases(GlobalVariable.Dictionary.TURNOS_ESCUELA).subscribe((entidad:any)=>{
		  this.tipo_turnos=entidad;
		});
	}

	obtenerTiposCalculoNotas(){
		this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPOS_CALCULO_NOTAS_ESCUELA).subscribe((entidad:any)=>{
			this.tipo_calificacion_notas=entidad;
		});
	}
  
  buscarMunicipios(departamento){
    this.generalService.obtenerClases(departamento.nombre_corto+"M").subscribe((entidad:any)=>{
      this.tipoMunicipios=entidad;
    });
  }
  
  async obtenerModulosConstruidosNuevaEmpresa(){
		let modulos_datos:any=await this.obtenerModulosConstruidos();
		let modulos=[];
		for(let i=0;i<modulos_datos.length;i++){
			let modulo_empresa={
				id_modulo:modulos_datos[i].id,
				modulo:modulos_datos[i],
				habilitado:false,
				hijos:[]
			}
			for(let j=0;modulos_datos[i].hijos && j<modulos_datos[i].hijos.length;j++){
				modulo_empresa.hijos.push({
					id_modulo:modulos_datos[i].hijos[j].id,
					modulo:modulos_datos[i].hijos[j],
					habilitado:false
				});
			}
			modulos.push(modulo_empresa);
		}
		return modulos;
  }
  
  async obtenerModulosConstruidos(){
		let modulos:any=await this.empresasService.obtenerModulos().toPromise();
		let modulos_padres=[];
		for(let i=0;i<modulos.length;i++){
			if(modulos[i].id_padre==null){
				modulos_padres.push(modulos[i]);
			}
		}
		for(let i=0;i<modulos.length;i++){
			for(let j=0;j<modulos_padres.length;j++){
				if(modulos[i].id_padre==modulos_padres[j].id){
					if(!modulos_padres[j].hijos){
						modulos_padres[j].hijos=[];
					}
					modulos_padres[j].hijos.push(modulos[i]);
				}
			}
		}
		return modulos_padres;
  }
  
  buscarModulos(plan_){
		if(!this.empresa.id){
			let plan=this.planes_rubro.filter(m => m.plan.id == plan_.id)[0];
			let modulos_padres=[];
			for(let i=0;i<plan.plan.modulos.length;i++){
				if(plan.plan.modulos[i].modulo.id_padre==null){
					plan.plan.modulos[i].modulo.hijos=[];
					modulos_padres.push(plan.plan.modulos[i].modulo);
				}
			}
			for(let i=0;i<plan.plan.modulos.length;i++){
				for(let j=0;j<modulos_padres.length;j++){
					if(plan.plan.modulos[i].modulo.id_padre==modulos_padres[j].id){
						if(!modulos_padres[j].hijos){
							modulos_padres[j].hijos=[];
						}
						modulos_padres[j].hijos.push(plan.plan.modulos[i].modulo);
					}
				}
			}
			let modulos=[];
			for(let i=0;i<modulos_padres.length;i++){
				let modulo_empresa={
					id_modulo:modulos_padres[i].id,
					modulo:modulos_padres[i],
					habilitado:false,
					hijos:[]
				}
				for(let j=0;modulos_padres[i].hijos && j<modulos_padres[i].hijos.length;j++){
					modulo_empresa.hijos.push({
						id_modulo:modulos_padres[i].hijos[j].id,
						modulo:modulos_padres[i].hijos[j],
						habilitado:false
					});
				}
				modulos.push(modulo_empresa);
			}
			this.empresa.modulos=modulos;
		}else{
			//aqui colocar cuando ya tiene modulos la empresa y no se desea sobreescribir con 
			//el cambio de plan.
		}
	}

	subirImagen(e){
		let me=this;
		let filesSelected =  e.target.files;
		if (filesSelected.length > 0)
		{
			var fileToLoad = filesSelected[0];
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
				me.empresa.imagen=base64EncodedImage;
			}
			fileReader.readAsDataURL(fileToLoad);	
		}
  }

  	subirImagenPortada(e){
		let me=this;
		let filesSelected =  e.target.files;
		if (filesSelected.length > 0)
		{
			var fileToLoad = filesSelected[0];
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
				me.empresa.imagen_portada=base64EncodedImage;
			}
			fileReader.readAsDataURL(fileToLoad);	
		}
	}

  	abrirModalEliminacionDatos(empresa){
		this.empresa=empresa;
		this.datos_eliminacion={
			id_empresa:empresa.id
		}
		this.modalEliminacionDatos=this.modalService.open(this.modalEliminacionDatosRef, {windowClass:"eliminacion-datos",ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.modalEliminacionDatos.result.then((result) => {
		this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
		this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	abrirPopupConfirmacionEliminacion(){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de eliminar los datos seleccionados de la empresa?";
		this.popupConfirmacion.componentInstance.data = this.datos_eliminacion;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarDatosEmpresa($e.data);
			}
			this.popupConfirmacion.close();
        });
	}
	  
	eliminarDatosEmpresa(datos){
		this.blockUI.start();
		this.empresasService.eliminarDatosEmpresa(datos).subscribe((dato:any)=>{
			this.blockUI.stop();
			if(dato.tiene_error){
				this.toastr.error(dato.mensaje);
			}else{
				this.modalEliminacionDatos.close();
				this.toastr.success(dato.mensaje);
			}
		});
	}

	descargarDatosEmpresaApp(){
		this.descarga_app={
			productos:true,
			clientes:true,
			tipos:true,
			cuentas_clientes:true,
			usuarios_precios:true,
			estudiantes:this.usuario.empresa.usar_cobros_caja_colegio,
			fichas_economicas:this.usuario.empresa.usar_cobros_caja_colegio
		}
		this.descargaDatos=this.modalService.open(this.descargaDatosRef, {windowClass:"eliminacion-datos",ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.descargaDatos.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
			}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	async descargaArchivoApp(){
		this.blockUI.start();
		let datos:any={empresa:this.usuario.empresa,fecha_descarga:new Date(),productos:[],clientes:[],tipos:[],cuentas_clientes:[],usuarios:[]};
		if(this.descarga_app.tipos){
			datos.tipos = await this.empresasService.obtenerTiposSincronizacionEmpresa(0).toPromise();
			datos.tipos=datos.tipos.concat(await this.empresasService.obtenerTiposSincronizacionEmpresa(this.usuario.id_empresa).toPromise());
		}

		if(this.descarga_app.productos){
			datos.productos=await this.productosService.obtenerProductosEmpresa(this.usuario.id_empresa).toPromise();
		}

		if(this.descarga_app.clientes){
			datos.clientes=await this.clientesService.obtenerClientesEmpresa(this.usuario.id_empresa).toPromise();
		}

		if(this.descarga_app.cuentas_clientes){
			let fecha_actual=new Date();
			let inf:any=await this.reportesService.obtenerEstadoCuentaClientes({
				filter:{
					id_empresa:this.usuario.id_empresa,
					cliente:undefined,
					sucursal:{id:0},
					usuario:{id:0},
					cuentas_liquidadas:false,
					nombre_contacto_cliente:0,
					fecha_fin:{
						year: fecha_actual.getFullYear(), 
						month: (fecha_actual.getMonth()+1),
						day:fecha_actual.getDate()
					}
				},
				currentPage: 1,
				itemsPerPage: 0,
				search:0,
				column:"codigo",
				direction:"asc"
			}).toPromise();
			datos.cuentas_clientes=inf.clientes;
			let clientes:any=await this.reportesService.obtenerCuentasPorCobrarEmpresa({
				filter:{
					id_empresa:this.usuario.id_empresa,
					cuentas_liquidadas:false
				}
			}).toPromise();
			datos.cuentas_detalle_clientes=clientes;
		}

		if(this.descarga_app.usuarios_precios){
			datos.usuarios=await this.usuariosService.obtenerUsuariosEmpresa(this.usuario.id_empresa).toPromise();
		}

		if(this.usuario.empresa.usar_cobros_caja_colegio){
			let escuela:any=await this.generalService.obtenerEscuela(this.usuario.id_empresa).toPromise();
			if(this.descarga_app.estudiantes){
				let configuracion_aplicacion:any=Util.obtenerConfiguracionAplicacion(GlobalVariable.Dictionary.CODIGO_APP_ESTUDIANTES,this);
				let inf:any=await this.estudiantesService.obtenerListaEstudiantesColegioPaginador({
					filter:{
						escuela:escuela
					},
					currentPage: 1,
					itemsPerPage: 0,
					search:0,
					column:"codigo",
					direction:"asc",
					configuracion_pagina:new PageConfiguration({
						crear: true,
						id_empresa: this.usuario.id_empresa,
						id_usuario:this.usuario.empresa?(this.usuario.empresa.usar_permisos_avanzados_usuarios?this.usuario.id:null):null,
						datos: configuracion_aplicacion
					  },this.aplicacion.aplicacion.id,
					  this.usuarioService)
				}).toPromise();
				datos.estudiantes=inf.estudiantes;
			}
	
			if(this.descarga_app.fichas_economicas){
				let fichas_economicas:any=await this.conceptosIngresosService.buscarConceptosPagoEstudianteColegio({id_estudiante:0,id_gestion:0,id_escuela:escuela.id}).toPromise();
				datos.fichas_economicas=fichas_economicas;
			}
		}

		var file = new Blob([JSON.stringify(datos)], {type: 'application/json'});
		fs.saveAs(file, "datos_"+this.usuario.empresa.nombre+".snapquick");
		this.descargaDatos.close();
		this.blockUI.stop();
	}

	crearNuevaEmpresa(){
		this.empresa={
			nit:"0",
			sugerido:false,
			sucursal:{
				latitud: -17.39380008501286700000, longitud: -66.15696068334569000000,
				delivery_propio_activado:true,
				escuela:{}
			},
			en_linea:true,
			imagen:"img/icon-empresa-default.png",
			imagen_portada:"img/imagen-portada-por-defecto.png",
			persona:{usuario:{}},
			direccion:"",
			telefono1:""
		}

		try{
			let bounds = new google.maps.LatLngBounds();
			let markers = [];
			let posicion_marcador=new google.maps.LatLng(this.empresa.sucursal.latitud,this.empresa.sucursal.longitud);
			bounds.extend(posicion_marcador);
			markers.push({
				position: {
					lat: this.empresa.sucursal.latitud,
					lng: this.empresa.sucursal.longitud,
				},
				label: {
					color: 'red',
				},
				options: { 
					labelAnchor: "5 55",
					labelClass: 'marker-label', 
					draggable: true
				},
			});
			
			this.markers = markers;
			this.center={ lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng()};
		}catch(e){
			this.conexion_google_map=false;
			this.toastr.error("No se pudo conectar con google maps!")
		}

		this.modalCreacionEmpresa=this.modalService.open(this.modalCreacionEmpresaRef, {windowClass:"wizard_creacion_empresa",ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.modalCreacionEmpresa.result.then((result) => {
		this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
		this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
		aplicarWizard(this.wizard_creacion_empresa,this,'guardar');
		aplicarPluginImagen("id_logo");
		aplicarPluginImagen("id_portada");
	}

	arrastarPinMapa(event) {
		this.empresa.sucursal.latitud = event.latLng.lat();
		this.empresa.sucursal.longitud = event.latLng.lng();
	}

	cerrarRegistroEmprea(){
		this.modalCreacionEmpresa.close();
	}

	buscarAplicacionesRubro(modulos){
		var aplicaciones_usuario=[];
		for(var i=0;i<modulos.length;i++){
			if(modulos[i].habilitado){
				for(var j=0;j<modulos[i].modulo.aplicaciones.length;j++){
					var encontrados=aplicaciones_usuario.filter(e => e.id_aplicacion == modulos[i].modulo.aplicaciones[j].aplicacion.id);
					if(encontrados.length==0){
						aplicaciones_usuario.push({
							id_aplicacion:modulos[i].modulo.aplicaciones[j].aplicacion.id,
							aplicacion:modulos[i].modulo.aplicaciones[j].aplicacion,
							puede_crear:true,
							puede_ver:true,
							puede_modificar:true,
							puede_eliminar:true
						});		
					}
				}	
			}
		}
		return aplicaciones_usuario;
	}

	validarDatosCreacion(){
		let res=this.validarDatosModificacion();
		if(!this.empresa.rubro){
			this.toastr.error("Debe ingresar el rubro de la empresa!");
			res=false;
		}
		if(!this.empresa.plan_snapquick){
			this.toastr.error("Debe ingresar el plan de la empresa!");
			res=false;
		}
		if(!this.empresa.departamento){
			this.toastr.error("Debe ingresar el departamento de la empresa!");
			res=false;
		}
		if(this.empresa.rubro && this.empresa.rubro.nombre.includes("COLEGIO")){
			if(!this.empresa.sucursal.escuela.dependencia){
				this.toastr.error("Debe seleccionar la dependencia del colegio!");
				res=false;
			}
			if(!this.empresa.sucursal.escuela.turno){
				this.toastr.error("Debe seleccionar el turno del colegio!");
				res=false;
			}
			if(!this.empresa.sucursal.escuela.tipo_calculo_calificacion){
				this.toastr.error("Debe seleccionar el tipo de calculo de notas del colegio!");
				res=false;
			}
		}
		return res;
	  }

	guardar(){
		this.blockUI.start();
		if(this.validarDatosCreacion()){
			if(this.empresa.persona.correo_electronico){
				this.empresa.persona.usuario.nombre_usuario=this.empresa.persona.correo_electronico;
				this.empresa.persona.usuario.aplicaciones_usuario=this.buscarAplicacionesRubro(this.empresa.modulos);
			}
			var empresa=this.empresa;
			var modulos=[];
			for(var i=0;empresa.modulos && i<empresa.modulos.length;i++){
				modulos.push(empresa.modulos[i]);
				for(var j=0;j<empresa.modulos[i].hijos.length;j++){
					modulos.push(empresa.modulos[i].hijos[j]);
				}
			}
			empresa.modulos=modulos;
			this.empresasService.crearCuentaEmpresa(empresa).subscribe((res:any)=>{
				this.blockUI.stop();
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
				}else{
					this.toastr.success(res.mensaje);
					this.cerrarRegistroEmprea();
					this.getItems();
				}
			});
		}else{
			this.blockUI.stop();
		}
	}

	obtenerListaRoles(empresa){
		this.blockUI.start();
		this.empresasService.obtenerRolesEmpresa(empresa.id).subscribe((roles:any[])=>{
			this.roles=roles;
			this.blockUI.stop();
		});
	}

	verRoles(empresa){
		this.empresa=empresa;
		this.obtenerListaRoles(empresa);
		this.vistaRoles=this.modalService.open(this.vistaRolesRef, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.vistaRoles.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
			}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	crearNuevoRol(){
		this.blockUI.start();
		this.rol={
			id_empresa:this.empresa.id,
			aplicacionesRol:[]
		}
		this.empresasService.obtenerEmpresa(this.empresa.id).subscribe((empresa_a_buscar:any) =>{
			this.blockUI.stop();
			for(var i=0;i<empresa_a_buscar.modulos.length;i++){
				if(empresa_a_buscar.modulos[i].habilitado){
					for(var j=0;j<empresa_a_buscar.modulos[i].modulo.aplicaciones.length;j++){
						if(this.rol.aplicacionesRol.filter(app => (app.aplicacion.id==empresa_a_buscar.modulos[i].modulo.aplicaciones[j].aplicacion.id)).length==0){
							this.rol.aplicacionesRol.push({aplicacion:empresa_a_buscar.modulos[i].modulo.aplicaciones[j].aplicacion,habilitado:true});
						}
					}	
				}
			}
			this.registroRol=this.modalService.open(this.registroRolRef, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',size:'md'});
			this.registroRol.result.then((result) => {
				this.closeResult = `Closed with: ${result}`;
				}, (reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			});
		});
	}

	validarRol(){
		let res=true;
		if(!this.rol.nombre){
			this.toastr.error("Debe ingresar un nombre de rol!");
			res=false;
		}
		if(this.rol.aplicaciones==0){
			this.toastr.error("El rol debe tener al menos una aplicacion asignada!");
			res=false;
		}
		return res;
	}

	guardarRol(){
		this.blockUI.start();
		if(this.validarRol()){
			if(this.rol.id){
				this.empresasService.actualizarRol(this.rol).subscribe((res:any)=>{
					this.blockUI.stop();
					if(res.tiene_error){
						this.toastr.error(res.mensaje);
					}else{
						this.toastr.success(res.mensaje);
						this.registroRol.close();
						this.obtenerListaRoles(this.empresa);
					}
				});
			}else{
				this.empresasService.guardarRol(this.rol).subscribe((res:any)=>{
					this.blockUI.stop();
					if(res.tiene_error){
						this.toastr.error(res.mensaje);
					}else{
						this.toastr.success(res.mensaje);
						this.registroRol.close();
						this.obtenerListaRoles(this.empresa);
					}
				});
			}
		}else{
			this.blockUI.stop();
		}
	}

	modificarRol(rol_a_modificar){
		this.blockUI.start();
		this.empresasService.obtenerRol(rol_a_modificar.id).subscribe((rol:any)=>{
			this.blockUI.stop();
			this.rol=rol;
			this.registroRol=this.modalService.open(this.registroRolRef, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',size:'md'});
			this.registroRol.result.then((result) => {
				this.closeResult = `Closed with: ${result}`;
				}, (reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			});
		});
	}

	crearNuevoUsuario(empresa){
		this.edicionusuario=this.modalService.open(RegistroUsuarioComponent, {windowClass : "wizard-edicion-usuario",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
		let usuario=JSON.parse(JSON.stringify(this.usuario));
		usuario.id_empresa=empresa.id;
		this.edicionusuario.componentInstance.usuario = usuario;
		this.edicionusuario.componentInstance.empresas = this.empresas;
		this.edicionusuario.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.toastr.success(res.mensaje);
					this.getItems();
					this.edicionusuario.close();
				}
			}else{
				this.edicionusuario.close();
			}
			
		});
	}

	abrirDescargaEmpresa(){
		this.empresa={codigo:null};
		this.descargaempresa=this.modalService.open(this.descargaempresaRef, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.descargaempresa.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
			}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	async descargarEmpresa(){
		this.blockUI.start();
		let res:any=await this.empresasService.descargarDatosEmpresa(this.empresa.codigo).toPromise();
		this.toastr.success(res.mensaje);
		this.blockUI.stop();
	}

	actualizarAplicacionesRol(){
		this.blockUI.start();
		this.rol.aplicacionesRol=[];
		this.empresasService.obtenerEmpresa(this.empresa.id).subscribe((empresa_a_buscar:any) =>{
			this.blockUI.stop();
			for(var i=0;i<empresa_a_buscar.modulos.length;i++){
				if(empresa_a_buscar.modulos[i].habilitado){
					for(var j=0;j<empresa_a_buscar.modulos[i].modulo.aplicaciones.length;j++){
						if(this.rol.aplicacionesRol.filter(app=>app.aplicacion.id==empresa_a_buscar.modulos[i].modulo.aplicaciones[j].aplicacion.id).length==0){
							this.rol.aplicacionesRol.push({aplicacion:empresa_a_buscar.modulos[i].modulo.aplicaciones[j].aplicacion,habilitado:true});
						}
					}	
				}
			}
		});
	}

	async abrirDatosFacturacion(empresa_a_modificar){
		this.empresa=await this.empresasService.obtenerEmpresa(empresa_a_modificar.id).toPromise();
		this.modal_datos_facturacion = this.modalService.open(EmpresaFacturacionComponent, {windowClass:'empresa-facturacion',ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
		this.modal_datos_facturacion.componentInstance.empresa = this.empresa;
		this.modal_datos_facturacion.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.toastr.success(res.mensaje);
					this.modal_datos_facturacion.close();
				}
			}else{
				this.modal_datos_facturacion.close();
			}
			
		});
	}

	autenticarSin(empresa){
		this.blockUI.start();
		this.empresasService.autenticarEmpresaSin(empresa).subscribe((res:any)=>{
			if(res.tiene_error){
				this.toastr.error(res.mensaje);
			}else{
				this.toastr.success(res.mensaje);
				empresa.sin_token=res.token;
			}
			this.blockUI.stop();
		});
	}

	eliminarAplicacion(rol_aplicacion){
		if(rol_aplicacion.id){
			rol_aplicacion.eliminado=true;
		}else{
			this.rol.aplicacionesRol.splice(this.rol.aplicacionesRol.indexOf(rol_aplicacion),1);
		}
	}

	async abrirConfiguracionGestiones(empresa){
		this.empresa=empresa;
		this.tipo_gestiones=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_GESTIONES).toPromise();
		this.tipo_gestiones.clases=this.orderPipe.transform(this.tipo_gestiones.clases, 'nombre_corto',true);
		this.configuracionGestiones=this.modalService.open(this.configuracionGestionesRef, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',size:'md',scrollable:true});
		this.configuracionGestiones.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
			}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	modificarRegistroConfiguracionNotas(gestion){
		this.blockUI.start();
		this.gestion=gestion;
		this.empresasService.obtenerConfiguracionCalificaciones(this.usuario.id_empresa,gestion.id).subscribe((configuraciones:any)=>{
			this.configuraciones_calificaciones=configuraciones;
			this.configuraciones_calificaciones=this.orderPipe.transform(this.configuraciones_calificaciones, 'orden',false);
			this.configuracionCalificaciones=this.modalService.open(this.configuracionCalificacionesRef, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',size:'md',scrollable:true});
			this.configuracionCalificaciones.result.then((result) => {
				this.closeResult = `Closed with: ${result}`;
				}, (reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			});
			this.blockUI.stop();
		});
	}

	guardarConfiguracionCalificaciones(){
		this.blockUI.start();
		this.empresasService.guardarConfiguracionCalificaciones({configuraciones_calificaciones:this.configuraciones_calificaciones}).subscribe((res:any)=>{
			if(res.tiene_error){
				this.toastr.error(res.mensaje);
			}else{
				this.configuracionCalificaciones.close();
				this.toastr.success(res.mensaje);
			}
			this.blockUI.stop();
		});
	}

	certificarEmpresa(empresa){
		this.blockUI.start();
		this.empresasService.certificarEmpresa(empresa.id).subscribe((res:any)=>{
			this.blockUI.stop();
			this.toastr.info(res.mensaje);
		});
	}

	establecerRazonSocial(){
		if(!this.empresa.razon_social){
		  this.empresa.razon_social=this.empresa.nombre;
		}
	}


}
