import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { Util } from 'src/app/utils/utils';
import { GlobalVariable } from 'src/app/global';

@Component({
  selector: 'app-configuracion-aplicacion-movimiento-inventario',
  templateUrl: './configuracion-aplicacion-movimiento-inventario.component.html',
  styleUrls: ['./configuracion-aplicacion-movimiento-inventario.component.css']
})
export class ConfiguracionAplicacionMovimientoInventarioComponent extends BaseComponent implements OnInit {

  @Input() configuracion_pagina:any;
  @Input() usuario:any;
  es_usuario_administrador:boolean=false;
  tipos_documentos:any[]=[];
  tipos_pago:any[]=[];
  tamanos_papel:any[]=[];
  transacciones:any=[];
  sucursales:any[]=[];
  almacenes:any[]=[];
  opciones_transacciones:any[]=[];
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  constructor(public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    public usuarioService:UsuarioService) {
      super(persistenciaService,modalService,generalService,usuarioService);
     }

  ngOnInit(): void {
    this.obtenerTamanosPapel();
    this.obtenerTransacciones();
    this.es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
    this.sucursales=Util.obtenerSucursalesUsuario(this.usuario);
    if(this.configuracion_pagina.object.datos.opciones_permisos.sucursal_defecto==null){
      this.configuracion_pagina.object.datos.opciones_permisos.sucursal_defecto=this.sucursales[0];
      this.almacenes=this.sucursales[0].almacenes;
    }else{
      this.almacenes=this.sucursales.filter(s => s.id==this.configuracion_pagina.object.datos.opciones_permisos.sucursal_defecto.id)[0].almacenes;
    }
  }

  obtenerTamanosPapel(){
    this.generalService.obtenerClases("TAMPAPFACT").subscribe((entidad:any) => {
      this.tamanos_papel=entidad.clases;
    });
  }

  obtenerTransacciones(){
    this.blockUI.start();
    this.generalService.obtenerClases("MOVEGR").subscribe((entidad:any)=>{
      this.generalService.obtenerClases("MOVING").subscribe((entidadIng:any)=>{
        this.transacciones=this.transacciones.concat(entidadIng.clases.filter(e => (e.nombre_corto == GlobalVariable.Dictionary.ING_INV_INICIAL || e.nombre_corto == GlobalVariable.Dictionary.ING_AJUSTE || e.nombre_corto == GlobalVariable.Dictionary.ING_COMPRA )));
        this.transacciones=this.transacciones.concat(
          entidad.clases.filter(e => 
            (e.nombre_corto == GlobalVariable.Dictionary.EGRE_PRODUCCION  || 
            e.nombre_corto == GlobalVariable.Dictionary.EGRE_AJUSTE  || 
            e.nombre_corto == GlobalVariable.Dictionary.EGRE_BAJA  || 
            e.nombre_corto == GlobalVariable.Dictionary.EGRE_CONSUMO  || 
            e.nombre_corto == GlobalVariable.Dictionary.EGRE_PUNTO_VENTA  || 
            e.nombre_corto == GlobalVariable.Dictionary.EGRE_VENTA_PERSONAL  || 
            (e.nombre_corto == GlobalVariable.Dictionary.EGRE_TRASPASO && this.usuario.empresa.usar_sucursales))
          ));
        this.llenarOpcionesTransacciones();
		    this.blockUI.stop();
      });
    });
  }

  llenarOpcionesTransacciones(){
    this.opciones_transacciones=[];
		for(var i=0;i<this.transacciones.length;i++){
			let opcion={
				name:this.transacciones[i].nombre,
				maker: "",
				ticked:false,
				id:this.transacciones[i].id
			}
			this.opciones_transacciones.push(opcion);
		}
  }

  seleccionarTransaccion(e){
    this.cdr.detectChanges();
  }

  guardarConfiguracionVista(){
		this.configuracion_pagina.updateObject();
		this.toastr.success("Configuracion actualizada satisfactoriamente!");
		this.alTerminar.emit();
	}

  cerrarComponente(){
    this.alTerminar.emit();
  }

  establecerSucursalDefecto(sucursal){
    this.configuracion_pagina.object.datos.opciones_permisos.almacen_defecto=null;
    this.almacenes=sucursal.almacenes;
  }

  establecerAlmacenDefecto(sucursal){

  }

}
