import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PdfService } from 'src/app/base/services/pdf/pdf.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { VentasService } from 'src/app/base/services/ventas/ventas.service';
import { BaseComponent } from 'src/app/models/base-component';
import { EstudiantesService } from '../../services/estudiantes/estudiantes.service';

declare const aplicarSubidaArchivo:any;
declare const $:any;

@Component({
  selector: 'app-documentos-estudiante',
  templateUrl: './documentos-estudiante.component.html',
  styleUrls: ['./documentos-estudiante.component.css']
})
export class DocumentosEstudianteComponent extends BaseComponent implements OnInit {

  @Input() estudiante:any;
  gestion_tab:any={};
  @Output() onConfirm:EventEmitter<any>  = new EventEmitter<any>();
  documentos:any[]=[];

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public usuarioService:UsuarioService,
    private toastr: ToastrService,
    public ventasService:VentasService,
    public pdfService:PdfService,
    private estudiantesService:EstudiantesService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

  async ngOnInit(): Promise<void> {
    this.filter={
      escuela:null,
			inscripciones_instituto:[],
      inscripciones_colegio:[],
      //inscripcion_instituto:this.inscripcion_instituto?this.inscripcion_instituto:null,
      //inscripcion_colegio:this.inscripcion_colegio?this.inscripcion_colegio:null
    }
    if(this.usuario.empresa.usar_ingresos_economicos_instituto){
      //this.obtenerInscripcionesInstitutoEstudiante();
    }else if(this.usuario.empresa.usar_ingresos_economicos_colegio){
      await this.obtenerInscripcionesColegioEstudiante();
    }
  }

  ngAfterViewInit(){
    aplicarSubidaArchivo('dropzone','preview-template',this,'subirArchivo','eliminarArchivo',{maximo_tamano_archivo:25});
  }

  async obtenerInscripcionesColegioEstudiante(){
    this.blockUI.start();
    let inscripciones:any=await this.estudiantesService.obtenerInscripcionesColegioEstudiante(this.estudiante.id).toPromise();
    this.filter.inscripciones_colegio=inscripciones;
    if(this.usuario.empresa.usar_acceso_gestion_escolar){
      if(this.usuario.datos && this.usuario.datos.gestiones){
        this.filter.inscripciones_colegio=this.filter.inscripciones_colegio.filter(ins => this.usuario.datos.gestiones.includes(ins.gestion.id))
      }
    }
    if(this.filter.inscripciones_colegio.length>0){
      this.establecerGestionTab(this.filter.inscripciones_colegio[this.filter.inscripciones_colegio.length-1].gestion);
    }
    this.blockUI.stop();
  }

  establecerGestionTab(gestion){
    this.gestion_tab=gestion;
    if(!this.gestion_tab.documento){
      this.blockUI.start();
      this.estudiantesService.obtenerDocumentosEstudianteGestion(this.estudiante.id,this.gestion_tab.id).subscribe((documento:any)=>{
        documento.archivos_a_subir=[];
        documento.id_empresa=this.usuario.id_empresa;
        this.gestion_tab.documento=documento;
        this.blockUI.stop();
      });
    }
  }

  cerrarComponente(){
    this.onConfirm.emit({confirm:false});
  }

  subirArchivo(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
				me.gestion_tab.documento.archivos_a_subir.push({archivo:base64EncodedImage,nombre:file.name,file:file,activo:true,fecha_registro:new Date(),descripcion:""});
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  eliminarArchivo(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.gestion_tab.documento.archivos_a_subir.splice(me.gestion_tab.documento.archivos_a_subir.indexOf({archivo:base64EncodedImage,nombre:file.name,file:file}),1);
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  guardarDocumentos(){
    this.blockUI.start();
    $(".dz-message").css( "display", "block" );
    $(".dz-preview").remove();
    this.estudiantesService.guardarDocumentos(this.gestion_tab.documento).subscribe((res:any)=>{
      this.gestion_tab.documento=null;
      this.establecerGestionTab(this.gestion_tab);
        this.toastr.success(res.mensaje);
        this.blockUI.stop();
    });
  }

  abrirPopupConfirmacionEliminacion(archivo){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de eliminar el documento?";
		this.popupConfirmacion.componentInstance.data = archivo;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
        this.blockUI.start();
        this.gestion_tab.documento.archivos[this.gestion_tab.documento.archivos.indexOf(archivo)].activo=false;
				this.estudiantesService.actualizarDocumentos(this.gestion_tab.documento).subscribe((res:any)=>{
          this.gestion_tab.documento=null;
          this.establecerGestionTab(this.gestion_tab);
          this.toastr.success(res.mensaje);
          this.blockUI.stop();
        });
			}
			this.popupConfirmacion.close();
    });
  }

}
