<div class="main-container container bgc-transparent">

    <div role="main" class="main-content minh-100 justify-content-center">
      <div class="p-2 p-md-4">
        <div class="row">
          <div class="shadow radius-1 overflow-hidden bg-white col-12 col-lg-10 offset-lg-1">

            <div class="row">

              <div class="d-none d-lg-flex col-lg-5 border-r-1 brc-default-l2 px-0">
                <div id="loginBgCarousel" class="carousel slide minw-100 h-100">
                  <ol class="carousel-indicators">
                    <li data-target="#loginBgCarousel" data-slide-to="0" class="active"></li>
                    <li data-target="#loginBgCarousel" data-slide-to="1"></li>
                    <li data-target="#loginBgCarousel" data-slide-to="2"></li>
                    <li data-target="#loginBgCarousel" data-slide-to="3"></li>
                  </ol>

                  <div class="carousel-inner minw-100 h-100">
                    <div class="carousel-item active minw-100 h-100">
                      <div style="background-image: url(assets/template/assets/image/login-bg-1.svg);" class="bgc-primary-l4 d-flex flex-column align-items-center justify-content-center">
                        <h1 class="mt-5">
                          <a href="#/"><img class="pull-left" src="{{rest_server+'img/'+datos_app.logo}}" width="45" height="45" /></a>
                        </h1>
                        <h2 class="text-blue-d1">
                          {{datos_app.nombre}} <span class="text-80 text-dark-tp4">App</span>
                        </h2>

                        <div class="mt-5 mx-4 text-120 text-dark-tp4 text-justify">
                          Nos caracterizamos por una atencion oportuna a nuestros clientes brindandoles soluciones acorde las necesidades en el ambito comercial, educativo, administrativo y electronico.
                          Nuestro equipo profesional esta formado por personal calificado, donde cada uno posee aptitudes que permiten agregar valor a nuestros productos.
                          <hr class="mb-1" />
                        </div>

                        <div class="mt-auto mb-4 text-dark-tp3">
                          SnapCoding S.R.L. &copy; 2013 - 2023
                        </div>
                      </div>
                    </div>

                    <div class="carousel-item minw-100 h-100">
                      <div style="background-image: url(assets/template/assets/image/login-bg-2.svg);" class="d-flex flex-column align-items-center justify-content-start">
                        <img class="pull-left" src="{{rest_server+'img/'+datos_app.logo}}" width="45" height="45" />
                        <h2 class="text-blue-l1">
                         {{datos_app.nombre}} <span class="text-80 text-white-tp3">App</span>
                        </h2>
                      </div>
                    </div>

                    <div class="carousel-item minw-100 h-100">
                      <div style="background-image: url(assets/template/assets/image/login-bg-3.jpg);" class="d-flex flex-column align-items-center justify-content-start">
                        <div class="bgc-black-tp4 radius-1 p-3 w-90 text-center my-3 h-100">
                          <img class="pull-left" src="{{rest_server+'img/'+datos_app.logo}}" width="45" height="45" />
                          <h2 class="text-blue-l1">
                            {{datos_app.nombre}} <span class="text-80 text-white-tp3">App</span>
                          </h2>
                        </div>
                      </div>
                    </div>

                    <div class="carousel-item minw-100 h-100">
                      <div style="background-image: url(assets/template/assets/image/login-bg-4.jpg);" class="d-flex flex-column align-items-center justify-content-start">
                        <img class="pull-left" src="{{rest_server+'img/'+datos_app.logo}}" width="45" height="45" />
                        <h2 class="text-blue-d1">
                          {{datos_app.nombre}} <span class="text-80 text-dark-tp3">App</span>
                        </h2>
                      </div>
                    </div>

                  </div>
                </div>
              </div>


              <div class="col-12 col-lg-7 py-lg-5 bgc-white px-0">
                <!-- you can also use tab links -->
                <ul class="mt-n4 mb-4 nav nav-tabs nav-tabs-simple justify-content-end" role="tablist">
                  <li class="nav-item" *ngIf="datos_app.crear_cuenta">
                    <a class="nav-link active" data-toggle="tab" href="#id-tab-login" role="tab" aria-controls="login" aria-selected="true">
                      Ingresar
                    </a>
                  </li>
                  <li class="nav-item" *ngIf="datos_app.crear_cuenta">
                    <a class="nav-link" data-toggle="tab" href="#id-tab-signup" role="tab" aria-controls="signup" aria-selected="false">
                      Crear Cuenta
                    </a>
                  </li>
                </ul>


                <div class="tab-content tab-sliding border-0 p-0" data-swipe="right">

                  <div class="tab-pane active show mh-100 px-3 px-lg-0 pb-3" id="id-tab-login">
                    <div class="d-none d-lg-block col-md-6 offset-md-3 mt-lg-4 px-0">
                      <h4 class="text-dark-tp4 border-b-1 brc-grey-l1 pb-1 text-130">
                        <i class="fa fa-coffee text-orange-m2 mr-1"></i>
                        Bienvenido
                      </h4>
                    </div>

                    <div class="d-lg-none text-secondary-m1 my-4 text-center">
                      <a href="#/"><img class="pull-left" src="{{rest_server+'img/'+datos_app.logo}}" width="45" height="45" /></a>
                      <h1 class="text-170">
                        <span class="text-blue-d1">{{datos_app.nombre}} <span class="text-80 text-dark-tp3">App</span></span>
                      </h1>

                      Bienvenido
                    </div>

                    <form class="form-row mt-4" name="loginform" #myForm="ngForm" novalidate>
                      <div class="  col-md-6 offset-md-3">
                        <div class="d-flex align-items-center input-floating-label text-blue-m1 brc-blue-m2">
                          <input (keypress)="enfocarElemento($event,'clave')" type="text" class="form-control form-control-lg pr-4 shadow-none"  name="nombre_usuario" autocomplete="off" [(ngModel)]="usuario.nombre_usuario" />
                          <i class="fa fa-user text-grey-m2 ml-n4"></i>
                          <label class="floating-label text-grey-l1 text-100 ml-n3" for="id-login-username" >Usuario</label>
                        </div>
                      </div>

                      <div class="  col-md-6 offset-md-3 mt-2 mt-md-1">
                        <div class="d-flex align-items-center input-floating-label text-blue-m1 brc-blue-m2">
                          <input id="clave" (keypress)="enfocarElemento($event,null)" type="password" class="form-control form-control-lg pr-4 shadow-none"  name="clave" autocomplete="off" [(ngModel)]="usuario.clave"  />
                          <i class="fa fa-key text-grey-m2 ml-n4"></i>
                          <label class="floating-label text-grey-l1 text-100 ml-n3" for="id-login-password">Clave</label>
                        </div>
                      </div>
                      <div class="col-md-6 offset-md-3 text-right text-md-right mt-n2 mb-2">
                        <a href="#" class="text-primary-m2 text-95" data-toggle="tab" data-target="#id-tab-forgot">
                          ¿Olvidaste tu clave?
                        </a>
                      </div>

                      <div class="  col-md-6 offset-md-3">
                        

                        <button (click)="iniciarSesion()" type="button" class="btn btn-info btn-block btn-md btn-bold mt-2 mb-4">
                          Ingresar
                        </button>
                        <!--<button (click)="iniciarSesionConGoogle()" type="button" class="btn btn-danger btn-block btn-md btn-bold mt-2 mb-4">
                          Ingresar con Google
                        </button>-->
                      </div>
                    </form>


                    <div class="form-row">
                      <div class="col-12 col-md-6 offset-md-3 d-flex flex-column align-items-center justify-content-center">

                        <hr class="brc-default-m4 mt-0 mb-2 w-100" *ngIf="datos_app.crear_cuenta" />

                        <div class="p-0 px-md-2 text-dark-tp3 my-3" *ngIf="datos_app.crear_cuenta">
                          ¿Aún no eres miembro? <br>
                          <a id="crearcuenta" class="text-success-m2 text-600 mx-1" data-toggle="tab" data-target="#id-tab-signup" href="#">
                            Crear cuenta
                          </a>
                        </div>

                        <hr class="brc-default-m4 w-100 mb-2" />
                        <div class="mt-n4 bgc-white-tp2 px-3 py-1 text-default-d1 text-90">Síguenos en:</div>

                        <div class="my-2">
                          <a href="{{datos_app.canal_whatsapp}}" target="_blank" type="button" class="btn btn-bgc-white btn-lighter-green btn-h-success btn-a-success border-2 radius-round btn-lg px-25 mx-1">
                            <i class="fab fa-whatsapp text-110"></i>
                          </a>
                          <a href="{{datos_app.canal_web}}" target="_blank" type="button" class="btn btn-bgc-white btn-lighter-blue btn-h-info btn-a-info border-2 radius-round btn-lg px-25 mx-1">
                            <i class="fas fa-globe text-110"></i>
                          </a>

                          <a href="{{datos_app.canal_facebook}}" target="_blank" type="button" class="btn btn-bgc-white btn-lighter-primary btn-h-primary btn-a-primary border-2 radius-round btn-lg mx-1">
                            <i class="fab fa-facebook-f text-110"></i>
                          </a>

                          <a href="{{datos_app.canal_youtube}}" target="_blank" type="button" class="btn btn-bgc-white btn-lighter-red btn-h-red btn-a-red border-2 radius-round btn-lg px-25 mx-1">
                            <i class="fab fa-youtube text-110"></i>
                          </a>
                        </div>

                      </div>
                    </div>
                  </div>


                  <div class="tab-pane mh-100 px-3 px-lg-0 pb-3" id="id-tab-signup" data-swipe-prev="#id-tab-login">
                    <div class="position-tl ml-3 mt-3 mt-lg-0">
                      <a href="#" class="btn btn-light-default bg-transparent" data-toggle="tab" data-target="#id-tab-login"><i class="fa fa-arrow-left"></i></a>
                    </div>

                    <div class="d-none d-lg-block col-md-6 offset-md-3 mt-lg-4 px-0">
                      <h4 class="text-dark-tp4 border-b-1 brc-grey-l1 pb-1 text-130">
                        <i class="fa fa-user text-purple-m1 mr-1"></i>
                        Crear una cuenta
                      </h4>
                    </div>

                    <div class="d-lg-none text-secondary-m1 my-4 text-center">
                      <i class="fa fa-leaf text-success-m2 text-200 mb-4"></i>
                      <h1 class="text-170">
                        <span class="text-blue-d1">{{datos_app.nombre}} <span class="text-80 text-dark-tp4">App</span></span>
                      </h1>

                      Crear una cuenta
                    </div>

                    <form class="form-row mt-4">
                      <div class="  col-md-6 offset-md-3">
                        <div class="d-flex align-items-center input-floating-label text-success-m1 brc-success-m2">
                          <input name="nombre_completo" type="text" class="form-control form-control-lg pr-4 shadow-none" id="id-name" autocomplete="off" [(ngModel)]="registro.nombre_completo"/>
                          <i class="fas fa-signature text-grey-m2 ml-n4"></i>
                          <label class="floating-label text-grey-l1 text-100 ml-n3" for="id-name">Nombre Completo</label>
                        </div>
                      </div>

                      <div class="  col-md-6 offset-md-3">
                        <div class="d-flex align-items-center input-floating-label text-success-m1 brc-success-m2">
                          <input name="telefono_movil" type="text" class="form-control form-control-lg pr-4 shadow-none" id="id-phone" autocomplete="off" [(ngModel)]="registro.telefono_movil"/>
                          <i class="fas fa-phone text-grey-m2 ml-n4"></i>
                          <label class="floating-label text-grey-l1 text-100 ml-n3" for="id-phone">Teléfono Móvil</label>
                        </div>
                      </div>

                      <div class="  col-md-6 offset-md-3">
                        <div class="d-flex align-items-center input-floating-label text-success-m1 brc-success-m2">
                          <input (blur)="validarUsuarioFormularioRegistro(registro)" name="correo_electronico" type="text" class="form-control form-control-lg pr-4 shadow-none" id="id-signup-email" autocomplete="off" [(ngModel)]="registro.correo_electronico"/>
                          <i class="fa fa-user text-grey-m2 ml-n4"></i>
                          <label class="floating-label text-grey-l1 text-100 ml-n3" for="id-signup-email">Correo Electrónico</label>
                        </div>
                      </div>

                      <div class="  col-md-6 offset-md-3 mt-1">
                        <div class="d-flex align-items-center input-floating-label text-success-m1 brc-success-m2">
                          <input name="clave" type="password" class="form-control form-control-lg pr-4 shadow-none" id="id-pass-1" autocomplete="new-password" [(ngModel)]="registro.clave" />
                          <i class="fas fa-key text-grey-m2 ml-n4"></i>
                          <label class="floating-label text-grey-l1 text-100 ml-n3" for="id-pass-1">Clave</label>
                        </div>
                      </div>

                      <div class="  col-md-6 offset-md-3 mt-1">
                        <div class="d-flex align-items-center input-floating-label text-success-m1 brc-success-m2">
                          <input (blur)="verificarClaveRepeticionFormularioRegistro(registro)" name="repeticion_clave" type="password" class="form-control form-control-lg pr-4 shadow-none" id="id-pass-2" autocomplete="off" [(ngModel)]="registro.repeticion_clave" />
                          <i class="fas fa-key text-grey-m2 ml-n4"></i>
                          <label class="floating-label text-grey-l1 text-100 ml-n3" for="id-pass-2">Repite la Clave</label>
                        </div>
                      </div>

                      <div class="  col-md-6 offset-md-3">
                        <div class="d-flex align-items-center input-floating-label text-success-m1 brc-success-m2">
                          <input name="nombre_empresa" type="text" class="form-control form-control-lg pr-4 shadow-none" id="id-nombre_empresa" autocomplete="off" [(ngModel)]="registro.nombre_empresa"/>
                          <i class="fas fa-signature text-grey-m2 ml-n4"></i>
                          <label class="floating-label text-grey-l1 text-100 ml-n3" for="id-nombre_empresa">Nombre Empresa</label>
                        </div>
                      </div>

                      <div class="  col-md-6 offset-md-3 mt-1">
                        <div class="d-flex align-items-center text-success-m1 brc-success-m2">
                          <label class="floating-label text-grey-l1 text-100 ml-n3" for="id-rubro">Rubro</label>
                          <select id="id-rubro" class="form-control" name="rubro" [(ngModel)]="registro.rubro_empresa">
                            <option [ngValue]="rubro" *ngFor="let rubro of rubros_empresa" >{{rubro.nombre}}</option>
                          </select>
                        </div>
                      </div>

                      <div class="  col-md-6 offset-md-3 mt-1">
                        <div class="d-flex align-items-center text-success-m1 brc-success-m2">
                          <label class="text-grey-l1 text-100 ml-n3" for="id-cudad">Ciudad</label>
                          <select id="id-ciudad" class="form-control" name="departamento" [(ngModel)]="registro.departamento">
                            <option [ngValue]="departamento" *ngFor="let departamento of departamentos_registro" >{{departamento.nombre}}</option>
                          </select>
                        </div>
                      </div>

                      <div class="  col-md-6 offset-md-3 mt-1">
                        <div class="d-flex align-items-center input-floating-label text-success-m1 brc-success-m2">
                          <re-captcha (resolved)="resolved($event)" siteKey="6LehsCATAAAAAHh09UCOL1Q0WQLqYfu0aN1AIzp3"></re-captcha>
                        </div>
                      </div>

                      

                      <div class="d-none   col-md-6 offset-md-3 my-2">
                        <label class="text-secondary-m3 text-110 mb-25">
                          Choose membership type
                        </label>
                        <div class="row d-flex mx-1 mx-lg-0 btn-group btn-group-toggle" data-toggle="buttons">

                          <div class="col-12 offset-sm-2 col-sm-3 px-1">
                            <label class="shadow-sm d-style border-1 w-100 my-1 py-3 bgc-white-tp2 btn-brc-tp btn btn-light-secondary btn-h-light-primary btn-a-light-primary radius-3">
                              <input type="radio" name="payments" id="payments1" autocomplete="off" class="invisible pos-abs">

                              <span class="d-flex flex-column align-items-center">
                                    <div class="font-bolder flex-grow-1">
                                        Free
                                    </div>
                                </span>

                            </label>
                          </div>

                          <div class="col-12 col-sm-6 px-1">
                            <label class="shadow-sm d-style border-2 w-100 my-1 py-3 bgc-white-tp2 btn-brc-tp btn btn-light-secondary btn-h-light-success btn-a-light-success radius-3">
                              <input type="radio" name="payments" id="payments2" autocomplete="off" class="invisible pos-abs">
                              <span class="d-flex flex-column align-items-center">

                                    <span class="position-tr mr-2">
                                        <span class="v-active pos-abs">
                                            <i class="fa fa-crown text-orange text-110"></i>
                                        </span>
                              <span class="v-n-active">
                                            <i class="fa fa-crown text-secondary-l3 text-110"></i>
                                        </span>
                              </span>

                              <div class="font-bolder flex-grow-1">
                                Premium
                              </div>
                              </span>
                            </label>
                          </div>

                        </div>

                      </div>

                      <div class="  col-md-6 offset-md-3 mt-2">
                        <!--<label class="d-inline-block mt-3 mb-0 text-secondary-d2">
                          <input type="checkbox" class="mr-1" id="id-agree" />
                          <span class="text-secondary-d2">I have read and agree to <a href="#" class="text-blue">terms</a></span>
                        </label>-->

                        <button (click)="registrarme(registro)" type="button" class="btn btn-success btn-block btn-md btn-bold mt-2 mb-3">
                          Registrarme
                        </button>
                      </div>
                    </form>


                    <div class="form-row w-100">
                      <div class="col-12 col-md-6 offset-md-3 d-flex flex-column align-items-center justify-content-center">

                        <hr class="brc-default-m4 mt-0 mb-2 w-100" />

                        <div class="p-0 px-md-2 text-dark-tp4 my-3">
                          ¿Ya tienes una cuenta?
                          <a class="text-blue-m2 text-600 mx-1" data-toggle="tab" data-target="#id-tab-login" href="#">
                            Ingresa aqui
                          </a>
                        </div>

                        <!--<hr class="brc-default-m4 w-100 mb-2" />
                        <div class="mt-n4 bgc-white-tp2 px-3 py-1 text-default-d1 text-90">Or Register Using</div>

                        <div class="mt-2 mb-3">
                          <button type="button" class="btn btn-primary border-2 radius-round btn-lg mx-1">
                            <i class="fab fa-facebook-f text-110"></i>
                          </button>

                          <button type="button" class="btn btn-blue border-2 radius-round btn-lg px-25 mx-1">
                            <i class="fab fa-twitter text-110"></i>
                          </button>

                          <button type="button" class="btn btn-danger border-2 radius-round btn-lg px-25 mx-1">
                            <i class="fab fa-google text-110"></i>
                          </button>
                        </div>-->

                      </div>
                    </div>
                  </div>


                  <div class="tab-pane mh-100 px-3 px-lg-0 pb-3" id="id-tab-forgot" data-swipe-prev="#id-tab-login">
                    <div class="position-tl ml-3 mt-2">
                      <a href="#" class="btn btn-light-default bg-transparent" data-toggle="tab" data-target="#id-tab-login"><i class="fa fa-arrow-left"></i></a>
                    </div>

                    <div class="col-md-6 offset-md-3 mt-5 px-0">
                      <h4 class="pt-4 pt-md-0 text-dark-tp4 border-b-1 brc-grey-l1 pb-1 text-130">
                        <i class="fa fa-key text-brown-m2 mr-1"></i>
                        Recuperar Clave
                      </h4>
                    </div>

                    <form class="form-row mt-4">
                      <div class="  col-md-6 offset-md-3">
                        <label class="text-secondary-m1 mb-3">
                          Ingresa tu correo electrónico y te enviaremos las instrucciones para recuperar tu clave.
                        </label>
                        <div class="d-flex align-items-center">
                          <input [(ngModel)]="recuperacion.correo_electronico" name="email" type="email" class="form-control form-control-lg pr-4 shadow-none" id="id-recover-email" placeholder="Correo electrónico" autocomplete="off" />
                          <i class="fa fa-envelope text-grey-m2 ml-n4"></i>
                        </div>
                      </div>


                      <div class="  col-md-6 offset-md-3 mt-1">
                        <button [disabled]="!recuperacion.correo_electronico"  (click)="enviarNuevaClave()" type="button" class="btn btn-warning btn-block btn-md btn-bold mt-2 mb-4">
                          Recuperar
                        </button>
                      </div>
                    </form>


                    <div class="form-row w-100">
                      <div class="col-12 col-md-6 offset-md-3 d-flex flex-column align-items-center justify-content-center">

                        <hr class="brc-default-m4 mt-0 mb-2 w-100" />

                        <div class="p-0 px-md-2 text-dark-tp4 my-3">
                          <a class="text-blue-m2 text-600" data-toggle="tab" data-target="#id-tab-login" href="#">
                            Volver al inicio
                          </a>
                        </div>


                      </div>
                    </div>
                  </div>
                  
                  <a id="recuperarclave" class="text-success-m2 text-600 mx-1" data-toggle="tab" data-target="#id-tab-recover-password" href="#">
                  </a>
                  <div class="tab-pane active show mh-100 px-3 px-lg-0 pb-3" id="id-tab-recover-password">
                    <div class="d-none d-lg-block col-md-6 offset-md-3 mt-lg-4 px-0">
                      <h4 class="text-dark-tp4 border-b-1 brc-grey-l1 pb-1 text-130">
                        <i class="fa fa-coffee text-orange-m2 mr-1"></i>
                        Recuperación de Clave
                      </h4>
                    </div>

                    <div class="d-lg-none text-secondary-m1 my-4 text-center">
                      <a href="#/"><img class="pull-left" src="{{rest_server+'img/'+datos_app.logo+datos_app.logo}}" width="45" height="45" /></a>
                      <h1 class="text-170">
                        <span class="text-blue-d1">{{datos_app.nombre}} <span class="text-80 text-dark-tp3">App</span></span>
                      </h1>

                      Bienvenido
                    </div>

                    <form class="form-row mt-4" name="loginform" #myForm="ngForm" novalidate>
                      <div class="  col-md-6 offset-md-3">
                        <div class="d-flex align-items-center input-floating-label text-blue-m1 brc-blue-m2">
                          <input id="clave_r" type="password" class="form-control form-control-lg pr-4 shadow-none"  name="clave_1" autocomplete="off" [(ngModel)]="recuperacion.clave"  />
                          <i class="fa fa-user text-grey-m2 ml-n4"></i>
                          <label class="floating-label text-grey-l1 text-100 ml-n3" for="id-login-username" >Nueva Clave</label>
                        </div>
                      </div>

                      <div class="  col-md-6 offset-md-3 mt-2 mt-md-1">
                        <div class="d-flex align-items-center input-floating-label text-blue-m1 brc-blue-m2">
                          <input id="clave_r2" type="password" class="form-control form-control-lg pr-4 shadow-none"  name="clave_2" autocomplete="off" [(ngModel)]="recuperacion.repeticion_clave"  />
                          <i class="fa fa-key text-grey-m2 ml-n4"></i>
                          <label class="floating-label text-grey-l1 text-100 ml-n3" for="id-login-password">Repita nueva clave</label>
                        </div>
                      </div>

                      <div class="  col-md-6 offset-md-3">
                        

                        <button (click)="recuperarClave()" type="button" class="btn btn-info btn-block btn-md btn-bold mt-2 mb-4">
                          Recuperar Clave
                        </button>
                      </div>
                    </form>
                  </div>

                </div><!-- .tab-content -->
              </div>

            </div>

          </div>
        </div>

        <div class="d-lg-none my-3 text-white-tp1 text-center">
          <img class="pull-left" src="{{rest_server+'img/'+datos_app.logo}}" width="30" height="30" /> SnapCoding S.R.L. &copy; 2013 - 2023
        </div>
      </div>
    </div><!-- /main -->
<div class="body-container"></div>
</div><!-- /.main-container -->