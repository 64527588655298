import { Component, OnInit } from '@angular/core';
import { UsuarioService } from '../../base/services/usuario/usuario.service';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { PersistenciaService } from '../../base/services/persistencia/persistencia.service';
import { GlobalVariable } from '../../../app/global';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Util } from 'src/app/utils/utils';
import { GeneralService } from '../../base/services/general/general.service';

declare const aplicarScriptPaginaLogin:any;
declare const $:any;
declare const gapi: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  usuario:any={};
  rest_server=GlobalVariable.SERVER_URL;
  recuperacion:any={};
  registro:any={
    correo_electronico:null,
    clave:null
  };
  rubros_empresa:any[];
  departamentos_registro:any[];
  datos_app:any={
    nombre:"SnapQuick",
    logo:'icon-empresa-default.png',
    canal_youtube:'https://www.youtube.com/channel/UCnfAlzr1NXqfsOp6_7osgJw',
    crear_cuenta:false
  }

  constructor(private _Activatedroute:ActivatedRoute,
    public usuarioService:UsuarioService,
    private persistenciaService:PersistenciaService,
    private generalService:GeneralService,
    private router:Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.verificarSesion(false);
    this.obtenerRubrosEmpresa();
    this.obtenerDepartamentos();
    aplicarScriptPaginaLogin();
    this.generalService.obtenerDatosApp().subscribe((datos:any)=>{
      this.datos_app.nombre=datos.app_name;
      this.datos_app.logo=datos.logo;
      this.datos_app.canal_youtube=datos.canal_youtube;
      this.datos_app.canal_facebook=datos.canal_facebook;
      this.datos_app.canal_whatsapp=datos.canal_whatsapp;
      this.datos_app.canal_web=datos.canal_web;
      this.datos_app.crear_cuenta=datos.crear_cuenta;
      document.title = this.datos_app.nombre.toUpperCase();
      let favicon = document.querySelector('link[rel="icon"]');
      favicon.setAttribute('type', 'image/png');
      favicon.setAttribute('href',GlobalVariable.SERVER_URL+"img/"+ this.datos_app.logo);
      if(localStorage.getItem("app_name")==null){
        localStorage.setItem("app_name",datos.app_name.toString());
      }
      if(localStorage.getItem("app_logo")==null){
        localStorage.setItem("app_logo",datos.logo.toString());
      }
      if(localStorage.getItem("themenav")==null){
        localStorage.setItem("themenav",datos.themenav.toString());
      }
      if(localStorage.getItem("themesidebar")==null){
        localStorage.setItem("themesidebar",datos.themesidebar.toString());
      }
      if(localStorage.getItem("zoom")==null){
        localStorage.setItem("zoom",datos.zoom.toString());
      }
      if(localStorage.getItem("canal_youtube")==null){
        localStorage.setItem("canal_youtube",datos.canal_youtube.toString());
      }
      if(localStorage.getItem("canal_whatsapp")==null){
        localStorage.setItem("canal_whatsapp",datos.canal_whatsapp.toString());
      }
      if(localStorage.getItem("canal_facebook")==null){
        localStorage.setItem("canal_facebook",datos.canal_facebook.toString());
      }
      if(localStorage.getItem("canal_web")==null){
        localStorage.setItem("canal_web",datos.canal_web.toString());
      }
    });
  }

  ngAfterViewInit():void{
    this.recuperacion.action = this._Activatedroute.snapshot.queryParamMap.get("action");
    this.recuperacion.token = this._Activatedroute.snapshot.queryParamMap.get("token");console.log(this.recuperacion.action);console.log(this.recuperacion.token);
    if(this.recuperacion.action=="change-password" && this.recuperacion.token){
      $("#recuperarclave").click();
    }else if(this.recuperacion.action=="register"){
      $("#crearcuenta").click();
    }
  }

  obtenerRubrosEmpresa () {
      this.blockUI.start();
      this.generalService.obtenerClases("RUBEMP").subscribe((entidad:any)=>{
        this.rubros_empresa = entidad?entidad.clases:[];
				this.blockUI.stop();
      });
		}

		obtenerDepartamentos () {
      this.blockUI.start();
      this.generalService.obtenerClases("DEP").subscribe((entidad:any)=>{
        this.departamentos_registro = entidad?entidad.clases:[];
				this.blockUI.stop();
      });
		}

  enfocarElemento(keyEvent,id_elemento){
    if (keyEvent.which === 13){
      if(id_elemento){
        Util.enfocarElemento(id_elemento);
      }else{
        this.iniciarSesion();
      }
    }
  }

  validarFormularioSesion(){
    let res=true;
    if(!this.usuario.nombre_usuario){
      res=res && false;
      this.toastr.error("Debe especificar el nombre de usuario!");
    }
    if(!this.usuario.clave){
      res=res && false;
      this.toastr.error("Debe especificar la clave!");
    }
    return res;
  }

  iniciarSesion(){
    if(this.validarFormularioSesion()){
      this.blockUI.start('Ingresando... espere por favor!');
      let usuario_respuesta=this.usuarioService.iniciarSesionUsuario(this.usuario,this.blockUI);
      if(usuario_respuesta){
        this.verificarSesion(true);
      }
    }
  }

  iniciarSesionConGoogle(){
    this.generalService.cargarGapiScript(GlobalVariable.GAPI_SCRIPT,true,false).subscribe(() => {
      let me=this;
	  //if(gapi.auth2.getAuthInstance().isSignedIn.get()){
		
	  //}else{
      this.blockUI.start('Esperando a Google...!');
      gapi.auth2.getAuthInstance().signIn().then(function(res){
        let usuario_respuesta=me.usuarioService.iniciarSesionUsuario({token:"61713658dde94caff634bb05e4d2e99d",nombre_usuario:gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail()},me.blockUI);
        if(usuario_respuesta){
          me.verificarSesion(true);
        }
      },function(err){
        me.blockUI.stop();
        me.toastr.error("Ocurrio un problema: "+err.error+'!','Ups!' );
      });
	  //}
	   
    });
  }

  verificarSesion(login){
    this.persistenciaService.getUsuario().subscribe(usuario => {
      if(usuario && usuario.persona){
        if(usuario.aplicacion_partida){
          this.router.navigateByUrl('/'+usuario.aplicacion_partida.url, {skipLocationChange: false}).then(()=>{
            this.router.navigate(['/'+usuario.aplicacion_partida.url]);
            if(login){
              window.location.reload()
            }
          });
        }else{
          this.router.navigateByUrl('/inicio', {skipLocationChange: false}).then(()=>{
            this.router.navigate(['/inicio']);
            if(login){
              window.location.reload()
            }
          });
        }
      }
    });
  }

  async enviarNuevaClave(){
    this.blockUI.start();
    let existencia_usuario:any=await this.usuarioService.verificarUsuarioExsistente(0,this.recuperacion.correo_electronico).toPromise();
    if(existencia_usuario.usuario){
      let res:any=await this.usuarioService.enviarEnlaceRecuperacionClave(this.recuperacion).toPromise();
      if(res.tiene_error){
        this.toastr.error(res.mensaje);
      }else{
        this.toastr.success(res.mensaje);
        this.recuperacion.correo_electronico=null;
      }
    }else{
      this.toastr.error("No se pudo encontrar una cuenta asociada con el correo electrónico ingresado!");
    }
    this.blockUI.stop();
  }

  verificarClaveRepeticionFormularioRegistro(usuario){
    var res=true;
    if(usuario.clave!=usuario.repeticion_clave){
      res=false;
      this.toastr.error("Las claves ingresadas no coinciden! Vuelva a intentarlo.");
    }
    return res;
  }

  recuperarClave(){
    this.blockUI.start();
    if(this.verificarClaveRepeticionFormularioRegistro(this.recuperacion)){
      this.persistenciaService.setUsuario({id:this.recuperacion.token.split("-")[0]});
      this.persistenciaService.set("token",this.recuperacion.token);
      //localStorage.setItem("ngStorage-token", this.recuperacion.token);
      //localStorage.setItem("ngStorage-usuario", JSON.stringify(JSON.stringify({id:this.recuperacion.token.split("-")[0]})));
      this.usuarioService.recuperarClave({id:this.recuperacion.token.split("-")[0],clave:this.recuperacion.clave}).subscribe((res:any)=>{
        if(res.tiene_error){
          this.toastr.error(res.mensaje);
        }else{
          this.toastr.success(res.mensaje);
          this.persistenciaService.removerUsuario();
          //window.localStorage.removeItem("ngStorage-usuario");//clear();
          //window.localStorage.removeItem("ngStorage-token");
          let me=this;
          setTimeout(function(){
            me.router.navigateByUrl('/', {skipLocationChange: false}).then(()=>{
              me.router.navigate(['/']);
              window.location.reload()
            });
          },2000)
        }
        this.blockUI.stop();
      });
    }else{
      this.blockUI.stop();
    }
  }

  async validarUsuarioFormularioRegistro(usuario) {
    var res=true;
    let existencia_usuario:any=await this.usuarioService.verificarUsuarioExsistente(0,usuario.correo_electronico).toPromise();
    if(existencia_usuario.usuario){
      res=false;
      this.toastr.error("Ya existe una cuenta con el correo electronico ingresado, intente nuevamente!");
    }	
    return res;
  }

  async registrarme(registro){
    this.blockUI.start();
    if(await this.validarFormularioRegistro(registro)){console.log(registro);
      var datos_rubro=await this.buscarModulosRubro(registro.rubro_empresa);
      if(datos_rubro[0]){
        var modulos=datos_rubro[1];
        var aplicaciones_usuario=this.buscarAplicacionesRubro(modulos);
        var empresa={
          razon_social:registro.nombre_empresa,
          nombre:registro.nombre_empresa,
          nit:0,
          direccion:registro.departamento.nombre,
          telefono1:registro.telefono_movil,
          departamento:registro.departamento,
          en_linea:true,
          modulos:modulos,
          rubro:registro.rubro_empresa,
          plan_snapquick:datos_rubro[0].plan,
          persona:{
            nombre_completo:registro.nombre_completo,
            correo_electronico:registro.correo_electronico,
            usuario:{
              nombre_usuario:registro.correo_electronico,
              clave:registro.clave,
              aplicaciones_usuario:aplicaciones_usuario,
              enviar_correo:true,
            }
          }
        };
        this.usuarioService.crearCuentaEmpresa(empresa).subscribe((res:any)=>{
          this.blockUI.stop();
          if(res.tiene_error){
            this.toastr.error(res.mensaje);
          }else{
            this.toastr.success(res.mensaje);
            this.usuario={nombre_usuario:registro.correo_electronico,clave:registro.clave};
            this.iniciarSesion();
          }
        });
      }else{
        this.blockUI.stop();
        this.toastr.info("Estimado solicitante, aun estamos trabajando en el registro de cuenta gratuito para el rubro que eligio!");
      }
    }else{
      this.blockUI.stop();
    }
  }

  async validarFormularioRegistro(registro){
    var res=true;
    if(!registro.nombre_completo){
      res=false;
      this.toastr.error("Debe ingresar su nombre completo.");
    }
    if(!registro.telefono_movil){
      res=false;
      this.toastr.error("Debe ingresar su número de teléfono móvil.");
    }

    if(!registro.correo_electronico){
      res=false;
      this.toastr.error("Debe ingresar su dirección de correo electrónico.");
    }else if(!await this.validarUsuarioFormularioRegistro(registro)){
      res=false;
    }

    if(!registro.clave || !registro.repeticion_clave){
      res=false;
      this.toastr.error("Debe ingresar la clave y repetición de la clave.");
    }else if(!this.verificarClaveRepeticionFormularioRegistro(registro)){
      res=false;
    }

    if(!registro.nombre_empresa){
      res=false;
      this.toastr.error("Debe ingresar el nombre de la empresa.");
    }
    if(!registro.rubro_empresa){
      res=false;
      this.toastr.error("Debe ingresar el rubro de la empresa.");
    }
    if(!registro.departamento){
      res=false;
      this.toastr.error("Debe ingresar la ciudad.");
    }
    var captchResponse = $('#g-recaptcha-response').val();
    if(captchResponse.length == 0 ){
      this.toastr.error("Utiliza el Captcha del sitio!");
      res=false;
    }
    return res;
  }

  async buscarModulosRubro(rubro){
    this.blockUI.start();
    let rubro_datos:any = await this.usuarioService.obtenerPlanesRubro(rubro.id).toPromise();
    this.blockUI.stop();
    var modulos_respuesta=[];
    var plan_gratuito=rubro_datos.planes.filter(e => e.plan.titulo.includes("GRATUITO"));
    if(plan_gratuito.length>0){
      plan_gratuito=plan_gratuito[0];
      for(var i=0;i<plan_gratuito.plan.modulos.length;i++){
        modulos_respuesta.push({id_modulo:plan_gratuito.plan.modulos[i].id_modulo,
          modulo:plan_gratuito.plan.modulos[i].modulo,
          habilitado:true});
      }
      return [plan_gratuito,modulos_respuesta];
    }else{
      return [null,null];
    }
  }

  buscarAplicacionesRubro(modulos){
    var aplicaciones_usuario=[];
    for(var i=0;i<modulos.length;i++){
      if(modulos[i].habilitado){
        for(var j=0;j<modulos[i].modulo.aplicaciones.length;j++){
          var encontrados=aplicaciones_usuario.filter(e =>  e.id_aplicacion == modulos[i].modulo.aplicaciones[j].aplicacion.id);
          if(encontrados.length==0){
            aplicaciones_usuario.push({
              id_aplicacion:modulos[i].modulo.aplicaciones[j].aplicacion.id,
              aplicacion:modulos[i].modulo.aplicaciones[j].aplicacion,
              puede_crear:true,
              puede_ver:true,
              puede_modificar:true,
              puede_eliminar:true
            });		
          }
        }	
      }
    }
    return aplicaciones_usuario;
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

}
