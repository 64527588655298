<div class="page-content container container-plus">
    <div class="page-header border-0 mb-3">
      <h1 class="page-title text-primary-d2 text-150">
        Calificaciones
        <small class="page-info text-secondary-d4">
          <i class="fa fa-angle-double-right text-80"></i>
          Notas
        </small>
      </h1>
    </div>

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Registros de</span>
      Docente
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar" *ngIf="!es_rol_profesor">
        <div class="row">
          <div class="col-12 col-md-9">
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="row">
                  <div class="col-2">
                      <label>Gestión Escolar </label>
                      <select (change)="obtenerProfesores()" [compareWith]="compararObjectos" class="form-control" required name="gestion" [(ngModel)]="filter.gestion">
                          <option [ngValue]="gestion" *ngFor="let gestion of filter.gestiones">{{gestion.nombre}}</option>
                      </select>
                  </div>
                  <div class="col-8">
                    <label>Profesor </label>
                    <select [compareWith]="compararObjectos" class="form-control" required name="empleado" [(ngModel)]="filter.empleado">
                      <option [ngValue]="empleado" *ngFor="let empleado of filter.empleados">{{empleado.persona.nombre_completo}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
		      </div>
          <div class="col-12 col-md-3">
            <div class="row">
              <div class="col-4">
                <div class="columns columns-right btn-group float-right">
                  <button (click)="obtenerRegistrosProfesor()" class="btn btn-xs btn-app btn-primary my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                    <i class="d-block h-4 fa fa fa-search text-150"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
		    </div>
      </div>
      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header">
          <table></table>
        </div>
        <div class="fixed-table-body table-responsive">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="filter.empleado && filter.registros_profesor.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
                <tr>
                    <th class="detail" rowspan="1">
                        <div class="th-inner ">Nº</div>
                    </th>
                    <th class="detail" rowspan="1">
                      <div class="th-inner ">Curso</div>
                    </th>
                    <th class="detail" rowspan="1">
                      <div class="th-inner ">Materia</div>
                    </th>
                    <th>Trimestre</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let registro_profesor of filter.registros_profesor; let i=index;">
                    <td>{{i+1}}</td>
                    <td>{{registro_profesor.curso.nombre}}</td>
                    <td>{{registro_profesor.materia.nombre}}</td>
                    <td *ngIf="registro_profesor.nivel_escolar.nombre_corto=='INICIAL'">
                      <ng-container *ngFor="let intervalo_escolar of filter.tipo_intervalo.clases;">
                        <button (click)="abrirCalificacionesInicial(registro_profesor,intervalo_escolar)" type="button" [ngClass]="{'btn-success':intervalo_escolar.nombre_corto=='1','btn-light-default':intervalo_escolar.nombre_corto=='2','btn-warning':intervalo_escolar.nombre_corto=='3'}" class="btn px-4 btn-h-default btn-a-default mb-1">
                          {{intervalo_escolar.nombre}} <i class="fas fa-file-alt text-110 fa-stack-1x d-n-active pos-rel"></i>
                        </button>
                        &nbsp;
                      </ng-container>
                    </td>
                    <td *ngIf="registro_profesor.nivel_escolar.nombre_corto!='INICIAL'">
                      <ng-container *ngFor="let intervalo_escolar of filter.tipo_intervalo.clases;">
                        <button (click)="abrirCalificaciones(registro_profesor,intervalo_escolar)" type="button" [ngClass]="{'btn-success':intervalo_escolar.nombre_corto=='1','btn-light-default':intervalo_escolar.nombre_corto=='2','btn-warning':intervalo_escolar.nombre_corto=='3'}" class="btn px-4 btn-h-default btn-a-default mb-1">
                          {{intervalo_escolar.nombre}} <i class="fas fa-file-alt text-110 fa-stack-1x d-n-active pos-rel"></i>
                        </button>
                        &nbsp;
                      </ng-container>
                    </td>
                </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
</div>