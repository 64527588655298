import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { Util } from 'src/app/utils/utils';

@Component({
  selector: 'app-configuracion-aplicacion-ventas',
  templateUrl: './configuracion-aplicacion-ventas.component.html',
  styleUrls: ['./configuracion-aplicacion-ventas.component.css']
})
export class ConfiguracionAplicacionVentasComponent extends BaseComponent implements OnInit {

  @Input() configuracion_pagina:any;
  @Input() usuario:any;
  es_usuario_administrador:boolean=false;
  transacciones:any[]=[];
  tipos_pago:any[]=[];
  canales:any[]=[];
  sucursales:any[]=[];
  almacenes:any[]=[];
  tamanos_papel:any[]=[];
  disenios_logo_impresion:any[]=[];
  opciones_canales:any[]=[];
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  constructor(public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    public usuarioService:UsuarioService) {
      super(persistenciaService,modalService,generalService,usuarioService);
     }

  ngOnInit(): void {
    this.obtenerTiposPago();
    this.obtenerTransacciones();
    this.obtenerCanales();
    this.obtenerTamanosPapel();
    this.obtenerDiseniosLogoImpresion();
    this.es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
    this.sucursales=Util.obtenerSucursalesUsuario(this.usuario);
    if(this.configuracion_pagina.object.datos.opciones_permisos.sucursal_defecto==null){
      this.configuracion_pagina.object.datos.opciones_permisos.sucursal_defecto=this.sucursales[0];
      this.almacenes=this.sucursales[0].almacenes;
    }else{
      this.almacenes=this.sucursales.filter(s => s.id==this.configuracion_pagina.object.datos.opciones_permisos.sucursal_defecto.id)[0].almacenes;
    }
    this.verTab("tabla_ventas");
  }

  obtenerTiposPago(){
    this.blockUI.start();
    this.generalService.obtenerClases("TIPA").subscribe((entidad:any) => {
      this.tipos_pago=entidad.clases;
      this.blockUI.stop();
    });
  }

  obtenerTransacciones(){
    this.generalService.obtenerClases("MOVEGR").subscribe((entidad:any) => {
      let movimientosEgresoOriginal=entidad.clases;
      this.transacciones=movimientosEgresoOriginal.filter(e => e.nombre_corto == GlobalVariable.Dictionary.EGRE_PROFORMA);
      if(this.usuario.empresa.usar_facturacion){
        this.transacciones=this.transacciones.concat(movimientosEgresoOriginal.filter(e => e.nombre_corto == GlobalVariable.Dictionary.EGRE_FACTURACION));
      }
      if(this.usuario.empresa.usar_facturacion_computarizada_en_linea){
        this.transacciones=this.transacciones.concat(movimientosEgresoOriginal.filter(e => e.nombre_corto == GlobalVariable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA));
        this.transacciones=this.transacciones.concat(movimientosEgresoOriginal.filter(e => e.nombre_corto == GlobalVariable.Dictionary.EGRE_FACTURACION_MANUAL));
      }
    });
  }

  obtenerTamanosPapel(){
    this.generalService.obtenerClases("TAMPAPFACT").subscribe((entidad:any) => {
      this.tamanos_papel=entidad.clases;
    });
  }

  obtenerDiseniosLogoImpresion(){
    this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPO_DISENIOS_LOGO_IMPRESION).subscribe((entidad:any) => {
      this.disenios_logo_impresion=entidad.clases;
    });
  }

  obtenerCanales(){
    this.generalService.obtenerClases("SNAPCANVEN").subscribe((entidad:any) => {
      this.canales=entidad.clases;
      this.llenarOpcionesCanales();
    });
  }

  llenarOpcionesCanales(){
    this.opciones_canales=[];
		for(var i=0;i<this.canales.length;i++){
			let opcion={
				name:this.canales[i].nombre,
				maker: "",
				ticked:false,
				id:this.canales[i].id
			}
			this.opciones_canales.push(opcion);
		}
  }

  seleccionarCanal(e){
    this.cdr.detectChanges();
  }

  guardarConfiguracionVista(){
		this.configuracion_pagina.updateObject();
		this.toastr.success("Configuracion actualizada satisfactoriamente!");
		this.alTerminar.emit();
	}

  cerrarComponente(){
    this.alTerminar.emit();
  }

  establecerSucursalDefecto(sucursal){
    this.configuracion_pagina.object.datos.opciones_permisos.almacen_defecto=null;
    this.almacenes=sucursal.almacenes;
  }

  establecerAlmacenDefecto(sucursal){

  }

}
