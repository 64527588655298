import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { Util } from 'src/app/utils/utils';
import { PacientesService } from '../../services/pacientes/pacientes.service';
import { CitasMedicasService } from '../../services/citas-medicas/citas-medicas.service';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { ConfiguracionAplicacionCitasMedicasComponent } from 'src/app/base/components/configuracion-aplicacion-citas-medicas/configuracion-aplicacion-citas-medicas.component';
import { VentaComponent } from 'src/app/base/components/venta/venta.component';
import { PagoVentaComponent } from 'src/app/snapquick/components/pago-venta/pago-venta.component';
import { RegistroPacienteComponent } from '../registro-paciente/registro-paciente.component';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { KardexPacienteComponent } from '../kardex-paciente/kardex-paciente.component';
import { RecetasComponent } from '../recetas/recetas.component';

declare const aplicarPluginCalendario:any;
declare const resaltarPestañaMenu:any;
declare const FullCalendar:any;
declare const $:any;
declare const PDFDocument:any;
declare const blobStream:any;

@Component({
  selector: 'app-citas-medicas',
  templateUrl: './citas-medicas.component.html',
  styleUrls: ['./citas-medicas.component.css']
})
export class CitasMedicasComponent extends BaseComponent implements OnInit {

  calendar:any;
  cita_medica:any={};
  historia_paciente:any;
  dato_paciente_lista:boolean=true;
  tipo_consulta:any;
  tipo_tratamiento:any;
  citas_medicas:any[]=[];
  fichas_citas_medicas:any[]=[];
  clases_item_cita:any[]=['bgc-red-d1 text-white text-95','bgc-purple-d2 text-white text-95','bgc-blue-d1 text-white text-95','bgc-orange-d1 text-white text-95','bgc-pink-d1 text-white text-95','bgc-dark-d1 text-white text-95'];
  modalVenta:NgbModalRef;
  modalKardexPaciente:NgbModalRef;
  es_evento:boolean=true;
  searching = false;
  searchFailed = false;  

  modal_registro_cita_medica:NgbModalRef;
	@ViewChild('modal_registro_cita_medica')
  private modal_registro_cita_medica_ref: TemplateRef<any>;

  modal_registro_historia_paciente:NgbModalRef;
	@ViewChild('modal_registro_historia_paciente')
  private modal_registro_historia_paciente_ref: TemplateRef<any>;

  modal_registro_receta:NgbModalRef;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    private pacientesService:PacientesService,
    private citasMedicasService:CitasMedicasService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

  async ngOnInit(): Promise<void> {
    this.init();
		this.buscarAplicacion(this.router.url.substring(1));
		let fecha_actual=new Date();
    this.filter={
      gestion_actual:fecha_actual.getFullYear(),
		  mes_actual:fecha_actual.getMonth(),
      id_empresa:this.usuario.id_empresa,
      ids_sucursales_usuario:Util.obtenerIdsSucursales(this.usuario),
      sucursales:[{nombre:"TODOS",id:0}].concat(Util.obtenerSucursalesUsuario(this.usuario)),
      sucursal: {nombre:"TODOS",id:0},
      usuarios:[{nombre_usuario:"TODOS",id:0}],
      usuario: {nombre_usuario:"TODOS",id:0},
      tipos_cita_medica: [GlobalVariable.Dictionary.SELECCION_TODOS],
      tipo:GlobalVariable.Dictionary.SELECCION_TODOS,
      pacientes:[],
      fecha_historias:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      }
    }
    await this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_CITAS_MEDICAS);
    this.configurarCalendario();
    this.establecerFechas(this.filter.gestion_actual,this.filter.mes_actual);
    this.obtenerPacientes();
    this.obtenerTiposConsulta();
    this.obtenerFichasHistoriasPacientes();
    this.column = "fecha_hora";
    this.getSearch(this.text_search,null);
  }

  ngAfterViewInit() {
		resaltarPestañaMenu(this.router.url.substring(1)+"1");
    aplicarPluginCalendario();
    this.configurarBotonesVista();
  }

  ocultarHorasVista(){
    $(".fc-timegrid-slots table tr:nth-child(-n+14)").css({display:"none"});
  }

  obtenerPacientes(){
    this.pacientesService.obtenerPacientesEmpresa(this.usuario.id_empresa).subscribe((res:any)=>{
      this.filter.pacientes=res;
    });
  }

  configurarCalendario(){
    let me=this;
    //for some random events to be added
	  var date = new Date();
	  var m = date.getMonth();
	  var y = date.getFullYear();
  
	  var day1 = Math.random() * 20 + 2;
	  var day2 = Math.random() * 25 + 1;
  
	// initialize the calendar
    this.calendar = new FullCalendar.Calendar(document.getElementById('calendar'), {
      themeSystem: 'bootstrap',
      headerToolbar: {
        start: 'today,prev,next',
        center: 'title',
        end: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
      },
      businessHours: {
        // days of week. an array of zero-based day of week integers (0=Sunday)
        daysOfWeek: [1,2,3,4,5], // Monday - Friday

        startTime: Util.formatearHoraCadena(this.configuracion_pagina.object.datos.opciones_permisos.hora_trabajo_inicio.hour,this.configuracion_pagina.object.datos.opciones_permisos.hora_trabajo_inicio.minute,0), // a start time (10am in this example)
        endTime: Util.formatearHoraCadena(this.configuracion_pagina.object.datos.opciones_permisos.hora_trabajo_fin.hour,this.configuracion_pagina.object.datos.opciones_permisos.hora_trabajo_fin.minute,0), // an end time (6pm in this example)
      },
      allDayDefault: true,
      initialView: 'timeGridDay',
      locale: 'es',
      events: [],
      selectable: true,
      selectLongPressDelay: 200,
      contentHeight: 'auto',
      defaultTimedEventDuration:Util.formatearHoraCadena(this.configuracion_pagina.object.datos.opciones_permisos.duracion_cita.hour,this.configuracion_pagina.object.datos.opciones_permisos.duracion_cita.minute,0),
      slotDuration:Util.formatearHoraCadena(this.configuracion_pagina.object.datos.opciones_permisos.duracion_cita.hour,this.configuracion_pagina.object.datos.opciones_permisos.duracion_cita.minute,0),
      slotMinTime:Util.formatearHoraCadena(this.configuracion_pagina.object.datos.opciones_permisos.mostrar_horas_desde.hour,this.configuracion_pagina.object.datos.opciones_permisos.mostrar_horas_desde.minute,0),
      slotMaxTime:Util.formatearHoraCadena(this.configuracion_pagina.object.datos.opciones_permisos.mostrar_horas_hasta.hour,this.configuracion_pagina.object.datos.opciones_permisos.mostrar_horas_hasta.minute,0),
      select: function(date){
        me.cita_medica={
          id_empresa:me.usuario.id_empresa,
          paciente:{cliente:{}},
          fecha_hora:date.start,
          //tipo:me.filter.tipos_eventos.filter(t => t.nombre_corto=="RECGRUP")[0],
          fecha_texto:{day:date.start.getDate(),month:date.start.getMonth()+1,year:date.start.getFullYear()},
          hora_texto:{hour:date.start.getHours(),minute:date.start.getMinutes()},
          id_usuario:me.usuario.id
        }
        me.modal_registro_cita_medica=me.modalService.open(me.modal_registro_cita_medica_ref, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
        me.modal_registro_cita_medica.result.then((result) => {
          me.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          me.closeResult = `Dismissed ${me.getDismissReason(reason)}`;
        });
      }/*,
      eventContent: function(arg) { 
        return {
        html: '<div class="fc-event-time">'+arg.event.start.getHours()+':'+arg.event.start.getMinutes() +'</div><div class="fc-event-title">'+arg.event.title +'</div>'
        }
      }*/,
      editable: true,
      droppable: true,
      drop: function(info) {
        /*me.registroevento=me.modalService.open(me.registroeventoRef, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
        me.registroevento.result.then((result) => {
          me.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          me.closeResult = `Dismissed ${me.getDismissReason(reason)}`;
        });*/
      },
      eventClick: function(info) {
        me.es_evento=true;
        me.cita_medica = me.citas_medicas.filter(cm => (cm.id==info.event.id))[0];
        me.cita_medica.fecha_hora=new Date(me.cita_medica.fecha_hora);
        me.cita_medica.fecha_texto={day:me.cita_medica.fecha_hora.getDate(),month:me.cita_medica.fecha_hora.getMonth()+1,year:me.cita_medica.fecha_hora.getFullYear()},
        me.cita_medica.hora_texto={hour:me.cita_medica.fecha_hora.getHours(),minute:me.cita_medica.fecha_hora.getMinutes()},
        me.modal_registro_cita_medica=me.modalService.open(me.modal_registro_cita_medica_ref, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
        me.modal_registro_cita_medica.result.then((result) => {
          me.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          me.closeResult = `Dismissed ${me.getDismissReason(reason)}`;
        });
      },
      datesSet: function (dateInfo) {
        var view = dateInfo.view;
        //console.log(dateInfo);
      }
    
    });
	  this.calendar.render();
  }  

  configurarBotonesVista(){
	  let me=this;
    /*$('.fc-timeGridDay-button').click(function(){
      me.ocultarHorasVista();
      setTimeout(function(){
        me.getItems();
      },1000);
		});

    $('.fc-timeGridWeek-button').click(function(){
      me.ocultarHorasVista();
      setTimeout(function(){
        me.getItems();
      },1000);
		});*/

		/*$('.fc-prev-button').click(function(){
			if(me.filter.mes_actual==0){
				me.filter.mes_actual=11;
				me.filter.gestion_actual--;
			}
			else{
				me.filter.mes_actual--;
			}		   
      me.establecerFechas(me.filter.gestion_actual,me.filter.mes_actual);
		  me.getItems();
		});

		$('.fc-next-button').click(function(){			
			if(me.filter.mes_actual==11){
				me.filter.mes_actual=0;
				me.filter.gestion_actual++;
			}
			else{
				me.filter.mes_actual++;
			}		   
			me.establecerFechas(me.filter.gestion_actual,me.filter.mes_actual);
		  me.getItems();
		});*/
	}

  establecerFechas(gestion_actual,mes_actual){
    let fecha_inicio_mes=new Date(gestion_actual,mes_actual,1);
    let fecha_fin_mes=new Date(gestion_actual,mes_actual+1,0);
    this.filter.fecha_inicio={
      year: fecha_inicio_mes.getFullYear(), 
      month: (fecha_inicio_mes.getMonth()+1),
      day:fecha_inicio_mes.getDate()
    };
    this.filter.fecha_fin={
      year: fecha_fin_mes.getFullYear(), 
      month: (fecha_fin_mes.getMonth()+1),
      day:fecha_fin_mes.getDate()
    }
  }

  cambiarPresentacionPaciente(){
    this.dato_paciente_lista=!this.dato_paciente_lista;
  }

  async obtenerTiposConsulta(){
		this.tipo_consulta=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_CONSULTAS_MEDICAS).toPromise();
	}

  guardarCitaMedica(){
    if(this.validarRegistroCitaMedica()){
      this.blockUI.start();
      this.cita_medica.fecha_hora=Util.convertirObjetoAfecha(this.cita_medica.fecha_texto);
      this.cita_medica.fecha_hora.setHours(this.cita_medica.hora_texto.hour);
      this.cita_medica.fecha_hora.setMinutes(this.cita_medica.hora_texto.minute);
      if(this.cita_medica.id){
        this.citasMedicasService.actualizarCitaMedica(this.cita_medica).subscribe((res:any)=>{
          this.blockUI.stop();
          this.modal_registro_cita_medica.close();
          if(res.tiene_error){
            this.toastr.error(res.mensaje);
          }else{
            this.toastr.success(res.mensaje);
            this.getItems();
          }
        });
      }else{
        this.citasMedicasService.guardarCitaMedica(this.cita_medica).subscribe((res:any)=>{
          this.blockUI.stop();
          this.modal_registro_cita_medica.close();
          if(res.tiene_error){
            this.toastr.error(res.mensaje);
          }else{
            this.toastr.success(res.mensaje);
            this.getItems();
          }
        });
      }
    }
  }

  validarRegistroCitaMedica(){
    let res=true;
    if(!this.cita_medica.paciente || (this.cita_medica.paciente && !this.cita_medica.paciente.id)){
      res=res && false;
      this.toastr.error("Debe especificar el paciente!");
    }
    if(!this.cita_medica.tipo){
      res=res && false;
      this.toastr.error("Debe especificar el tipo de cita médica!");
    }
    if(!this.cita_medica.fecha_texto || !this.cita_medica.fecha_texto.year || !this.cita_medica.fecha_texto.month || !this.cita_medica.fecha_texto.day){
      res=res && false;
      this.toastr.error("Debe especificar la fecha completa para la cita médica!");
    }
    if(!this.cita_medica.hora_texto || (this.cita_medica.hora_texto.hour==null || this.cita_medica.hora_texto.hour==undefined) || (this.cita_medica.hora_texto.minute==null || this.cita_medica.hora_texto.minute==undefined)){
      res=res && false;
      this.toastr.error("Debe especificar la hora completa para la cita médica!");
    }
    return res;
  }

  async getItems(){
    this.blockUI.start();
    let eventos_source:any[] = this.calendar.getEvents();
		eventos_source.forEach(event => {
			event.remove();
		});
    this.citasMedicasService.obtenerListaCitasMedicas(this).subscribe((citas_medicas:any)=>{
      this.blockUI.stop();
      this.citas_medicas=citas_medicas;
      for(var i=0;i<citas_medicas.length;i++){
        let fecha_cita=new Date(citas_medicas[i].fecha_hora);
        let cita_medica_calendario:any={
            id:citas_medicas[i].id,
            title: (citas_medicas[i].paciente.cliente.persona.nombres+" "+(citas_medicas[i].paciente.cliente.persona.apellido_paterno?citas_medicas[i].paciente.cliente.persona.apellido_paterno:"")+" "+(citas_medicas[i].paciente.cliente.persona.apellido_materno?citas_medicas[i].paciente.cliente.persona.apellido_materno:""))
        }
        /*if(pedidos[i].tiempo_despacho){
            let fecha_despacho=pedidos[i].tiempo_despacho;
            pedido_calendario.start=fecha_pedido;
            pedido_calendario.end=fecha_despacho;
        }else{*/
        cita_medica_calendario.start=fecha_cita;
        //cita_medica_calendario.end=new Date(fecha_cita).setMinutes(fecha_cita.getMinutes()+15);
        //}
        cita_medica_calendario.className=citas_medicas[i].consolidado?"bgc-success-d1 text-white text-95":(citas_medicas[i].tipo.nombre.toUpperCase()=="CONSULTA"?'bgc-blue-d1 text-white text-95':'bgc-orange-d1 text-white text-95')/*this.clases_item_cita[Math.floor(Math.random()*this.clases_item_cita.length)]*/;
        this.calendar.addEvent(cita_medica_calendario);
      }
      //$('#'+$scope.idCalendario).fullCalendar('addEventSource',$scope.pedidos_calendario);
    });
	}

  async obtenerFichasHistoriasPacientes(){
    this.blockUI.start();
    this.citasMedicasService.obtenerFichasHistoriasPacientes(this).subscribe((fichas_citas_medicas:any)=>{
      this.blockUI.stop();
      this.fichas_citas_medicas=fichas_citas_medicas;
    });
	}

  abrirModalRegistroHistoriaPaciente(cita_medica){
    this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de asignar ficha a la historia de paciente?";
		this.popupConfirmacion.componentInstance.data = cita_medica;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.guardarHistoriaPaciente(cita_medica);
			}
			this.popupConfirmacion.close();
    });
  }

  guardarHistoriaPaciente(cita_medica){
    this.historia_paciente=cita_medica.historia_paciente?cita_medica.historia_paciente:{
      cita_medica:cita_medica,
      paciente:cita_medica.paciente,
      numero_paginas:1,
      id_sucursal:this.usuario.sucursalesUsuario[0].id_sucursal,
      id_usuario:this.usuario.id
    };
    this.historia_paciente.cita_medica=this.historia_paciente.cita_medica?this.historia_paciente.cita_medica:JSON.parse(JSON.stringify(cita_medica));
    if(this.validarHistoriaPaciente()){
      this.blockUI.start();
      if(this.historia_paciente.id){
        this.citasMedicasService.actualizarHistoriaPaciente(this.historia_paciente).subscribe((res:any)=>{
          this.blockUI.stop();
          this.toastr.success(res.mensaje);
          this.modal_registro_cita_medica.close();
          this.getItems();
          this.obtenerFichasHistoriasPacientes();
        });
      }else{
        this.citasMedicasService.guardarHistoriaPaciente(this.historia_paciente).subscribe((res:any)=>{
          this.blockUI.stop();
          this.toastr.success(res.mensaje);
          this.modal_registro_cita_medica.close();
          this.getItems();
          this.obtenerFichasHistoriasPacientes();
        });
      }
    }
  }

  validarHistoriaPaciente(){
    let res=true;
    if(!this.historia_paciente.numero_paginas){
      res=res && false;
      this.toastr.error("Debe especificar el número de paginas!");
    }
    return res;
  }

  abrirPopupConfirmacionEliminacion(cita_medica){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de anular la cita medica?";
		this.popupConfirmacion.componentInstance.data = cita_medica;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarCitaMedica($e.data);
			}
			this.popupConfirmacion.close();
    });
	}

  eliminarCitaMedica(cita_medica){
		this.blockUI.start();
		this.citasMedicasService.eliminarCitaMedica(cita_medica).subscribe((res:any) => {
      if(this.modal_registro_cita_medica){
        this.modal_registro_cita_medica.close();
      }
			this.toastr.success(res.mensaje);
			this.getItems();
      this.obtenerFichasHistoriasPacientes();
			this.blockUI.stop();
		});
	}

  abrirConfiguracionAplicacion(){
		this.configuracion_aplicacion_modal=this.modalService.open(ConfiguracionAplicacionCitasMedicasComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',scrollable:true});
		this.configuracion_aplicacion_modal.componentInstance.configuracion_pagina=this.configuracion_pagina;
		this.configuracion_aplicacion_modal.componentInstance.usuario=this.usuario;
		this.configuracion_aplicacion_modal.componentInstance.alTerminar.subscribe((res) => {
			this.configuracion_aplicacion_modal.close();
		});
	}

  async generarVentaCitaMedica(cita_medica){
    if(cita_medica.tipo.nombre_corto && parseFloat(cita_medica.tipo.nombre_corto)>0){
      this.blockUI.start();
      let configuracion_aplicacion_ventas:any=await this.usuarioService.obtenerConfiguracionPaginaPorCodigo(this.usuario.id_empresa,GlobalVariable.Dictionary.CODIGO_APP_VENTAS,this.usuario.empresa.usar_permisos_avanzados_usuarios?this.usuario.id:0).toPromise();
		  this.modalVenta = this.modalService.open(VentaComponent, {windowClass:'venta-popup', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
			this.modalVenta.componentInstance.venta.usuario = this.usuario;
			this.modalVenta.componentInstance.usuario = this.usuario;
			this.modalVenta.componentInstance.configuracion_pagina = configuracion_aplicacion_ventas;
			this.modalVenta.componentInstance.venta.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
			this.modalVenta.componentInstance.es_administrador=Util.esUsuarioAdministrador(this.usuario);
			this.modalVenta.componentInstance.venta.cliente=cita_medica.paciente.cliente;
			this.modalVenta.componentInstance.venta.sucursal=Util.obtenerSucursalesUsuario(this.usuario)[0];
			this.modalVenta.componentInstance.nombre_corto_canal_directo=this.global_variable.Dictionary.CANAL_VENTA_DIRECTA;
      this.modalVenta.componentInstance.venta.descontar_almacen=this.usuario.empresa.usar_inventario?true:false;
      this.modalVenta.componentInstance.venta.id_historia_paciente=cita_medica.historia_paciente.id;
      this.modalVenta.componentInstance.venta.pagado=parseFloat(cita_medica.tipo.nombre_corto);
			this.modalVenta.componentInstance.venta.detallesVenta=[{
          descripcion:cita_medica.tipo.nombre,
          precio_unitario:parseFloat(cita_medica.tipo.nombre_corto),
          importe:parseFloat(cita_medica.tipo.nombre_corto),
          total:parseFloat(cita_medica.tipo.nombre_corto),
          es_servicio:true,
          es_combo:false,
          item:GlobalVariable.Dictionary.ITEM_SERVICIO,
          editar_precio:true,
          cantidad:1,descuento:0,tipo_descuento:false
        }];
	
			this.modalVenta.componentInstance.inhabilitar_busqueda_item=false;
			this.modalVenta.componentInstance.inhabilitar_sucursal=true;
			this.modalVenta.componentInstance.inhabilitar_almacen=true;
			this.modalVenta.componentInstance.inhabilitar_descontar_almacen=true;
			this.blockUI.stop();
			this.modalVenta.componentInstance.alTerminar.subscribe((res) => {
				if(res){
					if(res.tiene_error){
						this.toastr.error(res.mensaje);
						this.toastr.error(res.stack.substring(0,500));
					}else{
            this.obtenerFichasHistoriasPacientes();
						this.toastr.success(res.mensaje);
						//this.imprimirVenta(res.venta.id,true);
						this.modalVenta.close();
					}
				}else{
					this.modalVenta.close();
				}
				
			});
    }else{
      this.toastr.warning("Parámetro economico no definido o no permitido!");
    }
  }

  generarPdfHistoriaPaciente(cita_medica){
    var doc = new PDFDocument({margin:10,size:GlobalVariable.Dictionary.PAPEL_CARTA_MEDIDA});
    var stream = doc.pipe(blobStream());
    let fecha_inicio=new Date();
    let fecha_fin=new Date();
    fecha_fin.setDate(fecha_inicio.getDate()+365-1);
    doc.font('Helvetica',10);
    doc.fillColor("blue");
    doc.text("DR. HENRY MORUNO CRUZ MEDICO REUMATOLOGO"/*this.usuario.empresa.nombre*/,231,30,{width:150,align:"center"});
    doc.font('Helvetica-Bold',23);
    doc.fillColor("black");
    doc.text("HISTORIA CLINICA",0,60,{align:"center"});
    doc.font('Helvetica-Bold',12);
    doc.text("NOMBRE:",50,90);
    doc.text("EDAD: ",50,110);
    doc.text("TELEFONO: ",50,130);
    doc.text("FECHA:",450,90);
    doc.text(cita_medica.historia_paciente.numero_paginas,550,30);    
    doc.text("- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ",0,140,{align:'center'});
    doc.font('Helvetica',12);
    let texto_nombre=(cita_medica.paciente.cliente.persona.nombres+" "+(cita_medica.paciente.cliente.persona.apellido_paterno?cita_medica.paciente.cliente.persona.apellido_paterno:"")+" "+(cita_medica.paciente.cliente.persona.apellido_materno?cita_medica.paciente.cliente.persona.apellido_materno:""));
    doc.text(texto_nombre.toUpperCase(),120,90);
    doc.text((Util.calcularEdad(cita_medica.paciente.cliente.persona.fecha_nacimiento))+" Años",120,110);
    let telefono=(cita_medica.paciente.cliente.persona.telefono?cita_medica.paciente.cliente.persona.telefono+"-":"")+(cita_medica.paciente.cliente.persona.telefono_movil?cita_medica.paciente.cliente.persona.telefono_movil:"");
    doc.text(telefono,120,130);
    let fecha_hoy=new Date();
    doc.text((fecha_hoy.getDate()+"-"+(fecha_hoy.getMonth()+1)+"-"+fecha_hoy.getFullYear()),500,90);
    doc.end();

    stream.on('finish', function () {
      var fileURL = stream.toBlobURL('application/pdf');
      var w = window.open(fileURL, '_blank', 'location=no');
      setTimeout(function () {
        w.print();
      }, 500);
    });
  }

  crearPagoVenta(cita_medica){
		this.modalVenta = this.modalService.open(PagoVentaComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.modalVenta.componentInstance.venta = {id:cita_medica.historia_paciente.id_venta};
		this.modalVenta.componentInstance.usuario = this.usuario;
		this.modalVenta.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.toastr.success(res.mensaje);
					this.obtenerFichasHistoriasPacientes();
					this.modalVenta.close();
				}
			}else{
				this.modalVenta.close();
			}
			
		});
  }

  modificarCitaMedica(cita_medica){
    this.es_evento=false;
    this.cita_medica=cita_medica;
    this.cita_medica.fecha_hora=new Date(this.cita_medica.fecha_hora);
    this.cita_medica.fecha_texto={day:this.cita_medica.fecha_hora.getDate(),month:this.cita_medica.fecha_hora.getMonth()+1,year:this.cita_medica.fecha_hora.getFullYear()},
    this.cita_medica.hora_texto={hour:this.cita_medica.fecha_hora.getHours(),minute:this.cita_medica.fecha_hora.getMinutes()},
    this.modal_registro_cita_medica=this.modalService.open(this.modal_registro_cita_medica_ref, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
    this.modal_registro_cita_medica.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  crearNuevoPaciente(){
		this.modalVenta = this.modalService.open(RegistroPacienteComponent, { ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
			this.modalVenta.componentInstance.usuario = this.usuario;
			this.modalVenta.componentInstance.alTerminar.subscribe((res) => {
				if(res){
					if(res.tiene_error){
						
					}else{
            this.obtenerPacientes();
            this.cita_medica.paciente=res.paciente;
						this.modalVenta.close();
					}
				}else{
					this.modalVenta.close();
				}
				
			});
  }

  buscarPaciente = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (term) =>  this.pacientesService.buscarPaciente(this.usuario.id_empresa,term)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)
	
  formatter = (x: any) => (x.id?(x.cliente.identificacion?x.cliente.identificacion:""):"");

  establecerPaciente(event){
    let paciente=(event.id)?event:event.item;
    this.cita_medica.paciente_texto=paciente.cliente.idetificacion;
    this.cita_medica.paciente=paciente;
  }

  reiniciarPaciente(){
    this.cita_medica.paciente={cliente:{}};
    this.cita_medica.paciente_texto=null;
    $("#paciente_texto").val("");
  }

  modificarPaciente(paciente){
		this.modalVenta = this.modalService.open(RegistroPacienteComponent, { ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
			this.modalVenta.componentInstance.usuario = this.usuario;
      this.modalVenta.componentInstance.paciente = paciente;
			this.modalVenta.componentInstance.alTerminar.subscribe((res) => {
				if(res){
					if(res.tiene_error){
						
					}else{
            this.obtenerPacientes();
            this.cita_medica.paciente=res.paciente;
						this.modalVenta.close();
					}
				}else{
					this.modalVenta.close();
				}
				
			});
  }

  verKardexPaciente(paciente){
    this.modalKardexPaciente = this.modalService.open(KardexPacienteComponent, { windowClass:'kardex-paciente', ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.modalKardexPaciente.componentInstance.usuario = this.usuario;
    this.modalKardexPaciente.componentInstance.paciente = paciente;
    this.modalKardexPaciente.componentInstance.alTerminar.subscribe((res) => {
      this.modalKardexPaciente.close();
    });
  }

  async abrirModalRegistroReceta(historia_paciente,paciente){
    this.blockUI.start();
    let recetas:any=await this.pacientesService.obtenerReceta(historia_paciente.id).toPromise();
    historia_paciente.recetas=recetas;
    this.blockUI.stop();
    this.modal_registro_receta=this.modalService.open(RecetasComponent, {windowClass:'receta-paciente',size:"md",ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
    this.modal_registro_receta.componentInstance.receta =  {
      id_historia_paciente:historia_paciente.id,
      id_usuario:this.usuario.id,
      activo:true
    };
    this.modal_registro_receta.componentInstance.historia_paciente = historia_paciente;
    this.modal_registro_receta.componentInstance.paciente = paciente;
    this.modal_registro_receta.componentInstance.alTerminar.subscribe((res) => {
      this.modal_registro_receta.close();
    });
  }

}
