import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { Util } from 'src/app/utils/utils';

@Component({
  selector: 'app-configuracion-aplicacion-inventarios',
  templateUrl: './configuracion-aplicacion-inventarios.component.html',
  styleUrls: ['./configuracion-aplicacion-inventarios.component.css']
})
export class ConfiguracionAplicacionInventariosComponent extends BaseComponent implements OnInit {

  @Input() configuracion_pagina:any;
  @Input() usuario:any;
  tamanos_papel:any[]=[];
  sucursales:any[]=[];
  almacenes:any[]=[];
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  constructor(public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public toastr: ToastrService,
    public usuarioService:UsuarioService) {
      super(persistenciaService,modalService,generalService,usuarioService);
     }

  ngOnInit(): void {
    this.obtenerTamanosPapel();
    this.sucursales=Util.obtenerSucursalesUsuario(this.usuario);
    if(this.configuracion_pagina.object.datos.opciones_permisos.sucursal_defecto==null){
      this.configuracion_pagina.object.datos.opciones_permisos.sucursal_defecto=this.sucursales[0];
      this.almacenes=this.sucursales[0].almacenes;
    }else{
      this.almacenes=this.sucursales.filter(s => s.id==this.configuracion_pagina.object.datos.opciones_permisos.sucursal_defecto.id)[0].almacenes;
    }
  }

  obtenerTamanosPapel(){
    this.generalService.obtenerClases("TAMPAPFACT").subscribe((entidad:any) => {
      this.tamanos_papel=entidad.clases;
    });
  }

  guardarConfiguracionVista(){
		this.configuracion_pagina.updateObject();
		this.toastr.success("Configuracion actualizada satisfactoriamente!");
		this.alTerminar.emit();
	}

  cerrarComponente(){
    this.alTerminar.emit();
  }

  establecerSucursalDefecto(sucursal){
    this.configuracion_pagina.object.datos.opciones_permisos.almacen_defecto=null;
    this.almacenes=sucursal.almacenes;
  }

  establecerAlmacenDefecto(sucursal){

  }

}
